<i18n lang="json">
{
	"ru": {
    "lastSeen": "был на сайте",
    "weeks": "менее недели | более недели | более недели | более недели",
    "days": "менее дня | 1 день | {n} дня | {n} дней",
    "hours": "менее часа | 1 час | {n} часа | {n} часов",
    "minutes": "менее минуты | 1 минуту | {n} минуты | {n} минут",
    "answerMinutes": "менее минуты | 1 минута | {n} минуты | {n} минут",
    "back": "назад",
    "requests": "обрабатывает запросы",
    "showProcessRequestsSometimesLabelAbility": "иногда",
    "showProcessRequestsRarelyLabelAbility": "редко",
    "showProcessRequestsOftenLabelAbility": "часто",
    "showProcessRequestsAlwaysLabelAbility": "всегда",
    "showProcessRequestsNotOftenLabelAbility": "нечасто",
    "nowOnSite": "сейчас на сайте",
    "answerTimeMoreTwelve": "более 12 ч.",
    "rankOwner": "Арендодатель",
    "superhost": "Суперхозяин",
    "rankNewby": "Новичок",
    "answerTime": "среднее время ответа",
    "speakLangs": "общается на языках",
    "participant": "участник с",
    "hintProfi": "Успешно прошёл тестирование на знание правил бронирования Суточно.ру",
    "goldPartnerLabel": "Золотой партнер",
    "silverPartnerLabel": "Серебряный партнер",
    "superhostText": "Один из лучших хозяев на Суточно.ру: имеет много успешных бронирований и хороших отзывов.",
    "profiText": "Это знаток Суточно.ру. Хорошо знает, как оформлять бронирования и принимать гостей.",
    "titleOwner": "Хозяин жилья: {name}",
    "gold-partner": "Золотой партнёр Суточно.ру",
    "silver-partner": "Серебряный партнёр Суточно.ру"
  },
	"en": {
    "lastSeen": "last seen",
    "weeks": "менее недели назад | более недели назад | более недели назад | более недели назад",
    "days": "дней назад | 1 день назад | {n} дня назад | {n} дней назад",
    "hours": "менее часа назад | 1 час назад | {n} часа назад | {n} часов назад",
    "minutes": "менее минуты назад | 1 минуту назад | {n} минуты назад | {n} минут назад",
    "back": "назад",
    "requests": "processes requests",
    "showProcessRequestsAlwaysLabelAbility": "always",
    "showProcessRequestsSometimesLabelAbility": "sometimes",
    "showProcessRequestsRarelyLabelAbility": "rarely",
    "showProcessRequestsOftenLabelAbility": "often",
    "showProcessRequestsNotOftenLabelAbility": "not often",
    "nowOnSite": "online",
    "answerTimeMoreTwelve": "more than 12 hours",
    "rankOwner": "Host",
    "superhost": "Super-host",
    "rankNewby": "Newbie",
    "answerTime": "response time",
    "speakLangs": "communicates in languages",
    "participant": "participant since",
    "hintProfi": "Having successfully passed the test for knowledge of the rules of reservation Sutochno.ru",
    "goldPartnerLabel": "Gold partner",
    "silverPartnerLabel": "Silver partner",
    "superhostText": "One of the best hosts on Sutochno.ru: he has many successful bookings and good reviews.",
    "profiText": "This is an expert on Sutochno.ru. Knows well how to make reservations and receive guests.",
    "titleOwner": "Homeowner: {name}",
    "gold-partner": "Golden partner Sutochno.ru",
    "silver-partner": "Silver partner Sutochno.ru"
  }
}
</i18n>
<template>
  <div class="owner">
    <div class="owner__head">
      <div class="owner__main">
        <h3
          class="owner__title tmp-font--average_bold"
          v-html="t('titleOwner', { name: getObjectData.owner.name })"
        ></h3>
        <p v-if="dateText.nowOnSite" class="owner__when-was tmp-font--small">
          {{ t("online", dateText.ownerDate) }}
        </p>
        <p v-else-if="dateText.isDate" class="owner__when-was tmp-font--small">
          {{ dateText.text }}
        </p>
      </div>
      <div class="owner__avatar">
        <img :src="getObjectData.owner.avatar" class="avatar__img" />
      </div>
    </div>
    <div class="owner__body">
      <div class="owner__permissions">
        <div
          v-if="findPermission('showSuperhostLabelAbility')"
          class="mt-6"
        >
          <div class="superhost tmp-font--super-small">
            <span>{{ t("superhost") }}</span>
          </div>
          <span
              v-if="getPartnerStatus"
              class="hint-popup first"
              :data-hint="t(`${getPartnerStatus}`)"
            >
              <span
                class="icon-app-partner partner-icon"
                :class="getPartnerStatus"
              ></span>
            </span>
          <div class="owner__text-permissions tmp-font--small">
            {{ t("superhostText") }}
          </div>
        </div>

        <div
          class="mt-6"
          v-else-if="findPermission('showProLabelAbility')"
        >
          <div class="profi">
            <span class="icon-app-graduate-cap"></span>
            <span
              v-if="getPartnerStatus"
              class="hint-popup second"
              :data-hint="t(`${getPartnerStatus}`)"
            >
              <span
                class="icon-app-partner partner-icon"
                :class="getPartnerStatus"
              ></span>
            </span>
          </div>
          <div class="owner__text-permissions">
            {{ t("profiText") }}
          </div>
        </div>
        <template v-else>
          <span
            v-if="getPartnerStatus"
            class="hint-popup last"
            :data-hint="t(`${getPartnerStatus}`)"
          >
            <span
              class="icon-app-partner partner-icon"
              :class="getPartnerStatus"
            ></span>
          </span>
        </template>
      </div>
      <p class="owner__text owner__text_bold tmp-font--medium">
        <span>{{ processing }}</span>
      </p>
      <p class="owner__text">
        <span>{{ t("answerTime") }}:</span>
        <span class="owner__text_bold tmp-font--medium">{{
          ` ${answerTime}`
        }}</span>
      </p>
      <p class="owner__text">
        <span>{{ t("speakLangs") }}:&nbsp;</span>
        <span
          v-if="speakLanguages && speakLanguages.length > 0"
          class="owner__language"
        >
          {{ speakLanguages }}
        </span>
      </p>
      <p class="owner__text owner__participant tmp-font--small">
        {{ t("participant") }}
        {{ dateAdd }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatSafari } from "@/utils/format";
import languages from "./languages.json";
import { useI18n } from 'vue-i18n';

export default {
  name: "Owner",
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      languages,
    };
  },
  computed: {
    ...mapGetters("detail", ["getObjectData"]),
    speakLanguages() {
      return this.getObjectData.owner.speak_languages.map((item) => item.title).join(", ");
    },
    getPartnerStatus(){
      if (this.findPermission("showGoldPartnerLabelAbility")) {
        return "gold-partner";
      } else if (this.findPermission("showSilverPartnerLabelAbility")) {
        return "silver-partner";
      } else{
        return null;
      }
    },
    dateAdd() {
      return new Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric",
        month: "long",
      }).format(
        new Date(formatSafari(String(this.getObjectData.owner.date_add)))
      );
    },
    dateText() {
      /**
       *
       * Поиск и вывод текста в карточку арендодателя
       *
       * Примеры:
       *
       *  - был на сайте: 4 часа назад
       *  - был на сайте: более недели назад
       *
       */

      let dateNow = new Date(); /* Сегодняшняя дата */
      let dateOwner = new Date(
        formatSafari(String(this.getObjectData.owner.date_last_login))
      ); /* Дата последнего входа в приложение */

      let dateResult = new Date(
        dateNow - dateOwner
      ); /* Подсчет времени отсутвия в приложении */

      /* Посчет даты в различные единицы времени */
      const milliseconds = dateResult;
      const seconds = milliseconds / 1000;
      const minutes = seconds / 60;
      const hours = minutes / 60;
      const days = hours / 24;
      const weeks = days / 7;

      let ownerDate; /* ownerDate - отсутвие по времени в приложении */
      let isDate; /* isDate - единицы измерения */

      /**
       * Вычисление формата в котором будет отображаться
       *
       * Пример:
       *
       * Если owner был online 24 часа назад
       *
       * в минутах: это больше 5 минут и больше 60 минут
       * в часах: 24 часа не меньше 24 часов
       * результатом будет: 1 день
       */

      if (minutes < 5) {
        /* Если меньше 5 минут назад был в приложении то owner online */
        ownerDate = minutes;
        isDate = "online";
      } else if (minutes < 60) {
        ownerDate = minutes;
        isDate = "minutes";
      } else if (hours < 24) {
        ownerDate = hours;
        isDate = "hours";
      } else if (days < 7) {
        ownerDate = days;
        isDate = "days";
      } else {
        ownerDate = weeks;
        isDate = "weeks";
      }

      /* Возвращение результата в нужном формате */

      ownerDate = Math.round(ownerDate);
      if (isDate == "online") {
        /* Пример вывода: 'сейчас на сайте' */
        return {
          isDate,
          ownerDate,
          text: `${this.t("nowOnSite")}`,
        };
      } else {
        /* Пример вывода: 'был на сайте: более недели назад' */
        return {
          isDate,
          ownerDate,
          text: `${this.t("lastSeen")} ${this.t(
            isDate,
            ownerDate
          )} ${this.t("back")}`,
        };
      }
    },
    answerTime() {
      let dateOwner = this.getObjectData.owner.answer_time;
      let minutes = this.getObjectData.owner.answer_time / 60;
      let hours = minutes / 60;

      if (hours < 12 && hours >= 1) {
        hours = Math.round(hours);
        return `${this.t("hours", hours)}`;
      } else if (minutes < 60 && minutes >= 0) {
        minutes = Math.round(minutes);
        return `${this.t("answerMinutes", minutes)}`;
      } else {
        return `${this.t("answerTimeMoreTwelve")}`;
      }
    },
    processing() {
      const process = [
        "showProcessRequestsSometimesLabelAbility",
        "showProcessRequestsRarelyLabelAbility",
        "showProcessRequestsOftenLabelAbility",
        "showProcessRequestsAlwaysLabelAbility",
        "showProcessRequestsNotOftenLabelAbility",
      ];
      let permission = "";
      this.getObjectData.owner.permissions.forEach((item) => {
        if (process.includes(item)) {
          permission = item;
        }
      });
      return `${this.t(permission)} ${this.t("requests")}`;
    },
  },
  methods:{
    findPermission(permisson) {
      return this.getObjectData.owner?.permissions?.find((p) => p == permisson)
        ? true
        : false;
    },
  }
};
</script>

<style lang="scss" scoped>
.owner {
  &__head {
    display: flex;
  }
  &__main {
    flex-grow: 1;
  }
  &__permissions {
    > div:first-child {
      margin-bottom: 18px;
    }

    .mt-6 {
      margin-top: 6px;
    }

    .superhost {
      display: inline-block;
      vertical-align: top;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 16px;
      border-radius: 2px;
      padding: 2px 6px;
      height: 18px;
      // margin-bottom: 15px;
      margin-right: 10px;
      color: #000;
    }
    .superhost {
      background-color: #f1c260;
      text-transform: uppercase;
    }
    .profi {
      display: flex;
      align-items: center;
      height: 100%;

      .icon-app-graduate-cap {
        margin-right: 10px;
        font-size: 14px;
        color: #2d6cb4;
      }
    }
  }
  &__avatar {
    flex-basis: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;

    .avatar__img {
      width: 100%;
      height: 100%;
    }
  }
  &__title {
    margin-top: 6px;
    margin-bottom: 0;
  }
  &__when-was {
    color: #7f7f7f;
    margin-top: 10px;
  }
  &__text {
    margin-top: 15px;
    color: #444;

    &_bold {
      font-weight: 500;
    }
  }
  &__text-permissions {
    color: #7f7f7f;
    margin-top: 8px;
  }
  &__language {
    // text-transform: lowercase;
  }
  &__participant {
    margin-top: 20px;
    color: #7f7f7f;
  }
  .partner-icon{
    vertical-align: middle;
    display: inline-block;
    transform: translateX(-4px);
  }
  .gold-partner{
    color: #f1c260;
  }
  .silver-partner{
    color: #999999;
  }
  .hint-popup {
    &[data-hint]::after {
      transform: translate(-70%, -100%);
    }
    &.first .partner-icon{
      transform: translateY(-3px);
    }
    &.first[data-hint]::before {
      transform: translate(-42%, -100%);
    }
    &.second[data-hint]::before {
      transform: translate(-21%, -100%);
    }
    &.last[data-hint]::before {
      transform: translate(-11%, -100%);
    }
  }
}
</style>

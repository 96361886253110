<template>
  <div class="button-switch-placeholder">
    <div class="shadow"></div>
    <div class="item item__text"></div>
    <div class="item item__button"></div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: "ButtonSwitchPlaceholder",
  computed: {
    ...mapState("user", ["isAuth"]),
  }
};
</script>
<style lang="scss" scoped>
.button-switch-placeholder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .item {
    background: #d8d8d8;
    &__text {
      border-radius: 10px;
      width: 110px;
      height: 16px;
    }
    &__button {
      border-radius: 3px;
      width: 167.5px;
      height: 42px;
    }
  }
}
.shadow {
  position: absolute;
  left: -10%;
  top: -10%;
  bottom: -10%;
  width: 30px;
  opacity: 0;
  background: #fff;
  animation: loader 1.3s linear infinite;
  filter: blur(20px);
  transform: rotate(25deg);
}
@keyframes loader {
  from {
    left: -10%;
    opacity: 0;
  }
  10% {
    left: 10%;
    opacity: 1;
  }
  90% {
    left: 90%;
    opacity: 1;
  }
  to {
    left: 110%;
    opacity: 0;
  }
}
</style>

<i18n lang="json">
{
	"ru": {
    "adultsText":"без взрослых | {n} взрослый | {n} взрослых | {n} взрослых",
    "alertText": "Жильё вмещает максимум {cnt}",
    "guests":"{n} гостей | {n} гостя | {n} гостей | {n} гостей",
    "ages": "0|c {n} года | {n} лет| {n} лет",
    "placeholderPets": "Укажите какие питомцы",
    "subTitle":"Жильё вмещает максимум {cnt}",
    "title":"Гости",
    "withPets": "размещение с питомцами",
    "warning": {
      "petsError": "Внимание, владелец жилья указал, что не готов принимать гостей с питомцами.",
      "petsWarning":"Внимание, владелец жилья не указал, что готов принимать гостей с питомцами. Запрос уйдёт на согласование.",
      "childrenError": "Внимание, владелец жилья указал, что не готов принимать гостей с детьми.",
      "childrenWarning":"Внимание, владелец жилья не указал, что готов принимать гостей с детьми. Запрос уйдёт на согласование.",
      "childrenAgeWarning": "Внимание, владелец жилья указал, что можно с детьми {age} и старше."
    }
	},
	"en": {
    "adultsText": "adults | {n} adult | {n} adults | {n} adults",
    "alertText": "Housing accommodates maximum of {cnt}",
    "guests": "{n} guests | {n} guests | {n} guests | {n} guests",
    "placeholderPets": "Please specify which pets",
    "subTitle": "Accommodation can accommodate maximum {cnt}",
    "title": "Guests",
    "withPets": "accommodation with pets ",
    "warning": {
      "petsError": "Attention, the landlord has indicated that he is not ready to host guests with pets.",
      "petsWarning": "Attention, the landlord has not indicated that he is ready to host guests with pets. The request will go for approval.",
      "childrenError":" Attention, the landlord has indicated that he is not ready to receive guests with children.",
      "childrenWarning":" Attention, the landlord has not indicated that he is ready to receive guests with children. The request will go for approval.",
      "childrenAgeWarning":"Attention, the landlord indicated that it is possible with children {age} and older."
    }
	}
}
</i18n>
<template>
  <div class="step-guests">
    <GuestOption 
      :guests="getGuestsData"
      :showPetsDescError="petsDescError"
      @changeChildren="changeChildren"
      @changeAdults="changeAdults"
      @changePetsValue="changePetsValue"
      @changePetsDescription="changePetsDescription"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { useI18n } from 'vue-i18n';
import axios from "axios";
import _ from "lodash";
import Switcher from "@sutochno/switcher";

import { formatWithTime, formatSafari } from "@/utils/format";
import Button from '@/components/base/Booking/Content/Form/BookingButton/Button.vue';
import Warning from "@/components/base/BaseWarning";
import GuestOption from "@/components/base/SelectGuestsCopy/GuestOption"

export default {
  name: "ModalStepGuests",
  components: {
    Switcher,
    Warning,
    Button,
    GuestOption
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      childrens: [],
      cancelToken: null,
      isSelectOpen: false,
    };
  },
  computed: {
    ...mapState("search", ["guests"]),
    ...mapState("detailBooking", [
      "changesGuests",
      "isChangeForSave",
      "isChangeForSaveGuests",
    ]),
    ...mapGetters("booking", ["currencyCalc"]),
    ...mapGetters("detail", ["getObjectPrice", "getObjectData", "getInstant"]),
    ...mapGetters("detail", { maxGuestsGetter: "maxGuests" }),
    ...mapGetters("detailBooking", ["petsObject", "btnDisabled", "isBooking"]),
    ...mapGetters("search", ["guestsCount"]),
    getGuestsData(){
      return {
        adults: this.adults,
        children: this.childrens,
        pets: {
          value: this.isPets,
          description: this.pets,
        }
      }
    },
    maxGuests() {
      return this.maxGuestsGetter || 0;
    },
    isAlert() {
      return !this.isSelectOpen && this.guestsCount > this.maxGuests;
    },
    guestsObj() {
      return JSON.parse(JSON.stringify(this.guests));
    },
    adults: {
      get() {
        return this.guestsObj.adults;
      },
      set(val) {
        this.updateAdults(val);
        if (!this.isChangeForSaveGuests && !this.isBooking) this.cacheGuests();
      },
    },
    isPets: {
      get() {
        return this.guests?.pets?.value || "";
      },
      set(val) {
        this.updatePets({
          value: val,
          description: this.guests.pets?.description || "",
        })
        this.setIsPets(val);
        this.setPetsWarning(val && this.rules.pets === 2 ? true : false);
      },
    },
    pets: {
      get() {
        return this.guests?.pets?.description || "";
      },
      set(val) {
        this.updatePets({
          value: this.guests.pets?.value || false,
          description: val,
        })
        this.setPets(val);
      },
    },
    //2 - по запросу
    //1 - разрешено
    //0 - запрещено
    rules() {
      const VALID_VALUES = [0, 1, 2];
      const PROPERTIES =
        this.getObjectData?.properties?.accommodation_rules?.properties;
      const PETS_VALUE = PROPERTIES?.find((p) => p.name === "pets")?.value;
      const IS_CHILDREN_VALUE = PROPERTIES?.find(
        (p) => p.name === "is_children"
      )?.value;
      const CHILDREN_AGE_VALUE = PROPERTIES?.find(
        (p) => p.name === "children_age"
      )?.value;
      return {
        pets: VALID_VALUES.includes(PETS_VALUE) ? PETS_VALUE : 2,
        isChildren: VALID_VALUES.includes(IS_CHILDREN_VALUE)
          ? IS_CHILDREN_VALUE
          : 2,
        childrenAge: CHILDREN_AGE_VALUE,
      }; //если ничего не приходит питомцы по запросу VUECLIENT-659
    },
    isPetsError() {
      return this.isPets && this.rules.pets === 0;
    },
    isGuestsError() {
      return this.guestsCount > this.maxGuests;
    },
    searchChildrens() {
      return this.guestsObj?.childrens || [];
    },
    isChildrenError() {
      return this.rules.isChildren === 0 && this.searchChildrens.length > 0;
    },
    isAgeError() {
      if (
        !this.isChildrenError &&
        this.rules.isChildren !== 2 &&
        this.searchChildrens.length > 0 &&
        this.rules.childrenAge > 0
      ) {
        return this.rules.childrenAge > Math.min(...this.searchChildrens);
      } else return false;
    },
    petsDescError(){
      return this.guests.pets?.value && this.guests.pets?.description === "";
    }
  },
  watch: {
    adults: _.debounce(function() {
      this.getInfoPrice();
    }, 500),
    "guests.childrens"(val, oVal) {
      if (
        String(val) != String(this.changesGuests.childrens) &&
        !this.isChangeForSaveGuests &&
        !this.isBooking
      ) {
        this.cacheGuests();
      }
    },
    childrens: {
      deep: true,
      handler: _.debounce(function (val) {
        this.updateChildrens(val.filter((x) => x !== null));
        this.getInfoPrice();
        this.isError();
      }, 500)
    },
  },
  mounted() {
    this.setChangesGuests(this.guests);
    this.$nextTick(() => {
      if (this.searchChildrens.length) {
        this.childrens = this.searchChildrens;
      }
      this.isError();
    });
  },
  methods: {
    ...mapMutations("detailBooking", [
      "setIsPets",
      "setPetsWarning",
      "setPets",
      "setBtnDisabled",
      "setChangesGuests",
      "setIsChangeForSave",
      "setIsChangeForSaveGuests",
    ]),
    ...mapActions("detail", ["setObjectPrice"]),
    ...mapActions("search", ["updateAdults", "updateChildrens", "updatePets"]),
    cacheGuests() {
      if (!this.isChangeForSaveGuests) {
        this.setIsChangeForSaveGuests(true);
      }
    },
    getInfoPrice() {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }

      this.cancelToken = axios.CancelToken.source();

      this.setObjectPrice({
        guests: this.guestsCount,
        date_begin: formatWithTime(
          new Date(formatSafari(this.getObjectPrice.date_begin))
        ),
        date_end: formatWithTime(
          new Date(formatSafari(this.getObjectPrice.date_end))
        ),
        objects: Number(this.getObjectData.id),
        currency_id: this.currencyCalc.id,
        no_time: 1,
        cancelToken: this.cancelToken,
      });
    },
    changeChildren(val) {
      this.childrens = val;
    },
    changeAdults(val) {
      this.adults = val;
      this.isError()
    },
    changePetsValue(val) {
      this.isPets = val;
      this.isError()
    },
    changePetsDescription(val) {
      this.pets = val;
      this.isError();
    },
    isError() {
      const IS_ERROR =
        this.isPetsError ||
        this.isGuestsError ||
        this.isChildrenError ||
        this.isAgeError || 
        this.petsDescError;
      this.setBtnDisabled(IS_ERROR);
      return IS_ERROR;
    },
  },
};
</script>

<style lang="scss" scoped>
$border: solid 1px #d8d8d8;
$brRadius: 3px;
$height: 42px;

.step-guests {
  margin-top: 20px;
  margin-bottom: 20px;
  &__item {
    margin-top: 15px;
  }
  .switcher {
    display: grid;
    grid-template-columns: 1fr 41px;
    align-items: center;
    padding: 0 15px;
    border-radius: 3px;
    height: $height;
    background-color: #f6f6f6;
    &-text {
      line-height: 1;
    }

    &-pets {
      &-input {
        input {
          padding: 4px 15px;
          height: $height;
        }
      }
    }
  }

  :deep(.warning) {
    border-radius: $brRadius;
    white-space: pre-line;
  }
}
.desktop {
  .booking__body {
    :deep(.icon-app-cross) {
      height: 40px;
    }
  }
}

</style>

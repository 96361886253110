const VIEWED_OBJECTS_STORAGE_NAME = "viewedObjects";

export default function (id) {
  const viewedObjects = JSON.parse(
    localStorage.getItem(VIEWED_OBJECTS_STORAGE_NAME)
  );
  if (!viewedObjects) {
    localStorage.setItem(VIEWED_OBJECTS_STORAGE_NAME, JSON.stringify([id]));
    return;
  }
  const value = [id, ...viewedObjects.filter((viewed) => viewed !== id)].slice(0, 100);
  localStorage.setItem(VIEWED_OBJECTS_STORAGE_NAME, JSON.stringify(value));
}

<i18n lang="json">
{
	"ru": {
    "instantBooking": "Быстрое бронирование",
    "instantText": "Бронируйте этот вариант прямо сейчас: ответа на запрос ждать не нужно!",
    "requestBooking": "Бронирование по запросу",
    "requestText": "Отправьте запрос на бронь, чтобы уточнить доступность и стоимость проживания."
	},
	"en": {
    "instantBooking": "Instant Booking",
    "instantText": "Book this option now: there’s no need to wait for a response!",
    "requestBooking": "Booking upon request",
    "requestText": "Send a reservation request to check availability and cost of living."
	}
}
</i18n>
<template>
  <div class="instant">
    <template v-if="bookingNow && !compareDaysBeforeCheckIn">
      <p class="instant__title tmp-font--average">
        <span class="icon-app-lightning" />
        <span>{{ t('instantBooking') }}</span>
      </p>
      <p class="instant__description">{{ t('instantText') }}</p>
    </template>
    <template v-else>
      <p class="instant__title tmp-font--average">
        <span>{{ t('requestBooking') }}</span>
      </p>
      <p class="instant__description">{{ t('requestText') }}</p>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
  name: "Instant",
  props: {
    instant: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapGetters("detail", ["getInstant", "bookingNow", "daysBeforeCheckIn"]),
    ...mapState("search", ["checkIn"]),
    compareDaysBeforeCheckIn() {
      const currentDate = new Date();
      const checkInDate = new Date(this.checkIn);
      const oneDay = 1000 * 60 * 60 * 24;
      const diffInTime = checkInDate.getTime() - currentDate.getTime();
      const diffInDays = Math.round(diffInTime / oneDay);
      return diffInDays > this.daysBeforeCheckIn
    }
  },
};
</script>

<style lang="scss" scoped>
.instant {
  padding: 0 20px;

  &__title {
    color: #000;

    .icon-app-lightning {
      font-size: 16px;
      margin-right: 10px;
    }
  }
  &__description {
    margin-top: 10px;
    color: #000;
  }
}
</style>


<i18n lang="json">
{
  "ru": {
    "showCalc": "Показать расчёт",
    "hideCalc": "Скрыть расчёт"
  },
  "en": {
    "showCalc": "View calculate",
    "hideCalc": "Hide calculate"
  }
}
</i18n>
  
<template>
  <div>
    <Transition name="list">
      <div v-if="priceList && viewCalc" class="price-list">
        <template v-for="item in priceList" :key="item.type">
          <PriceItem
            v-if="item.cost"
            :price-item="item"
            :currency-item="currencyCalc"
          />
        </template>
      </div>
    </Transition>
    <button
      class="toggle-btn"
      @click.prevent="viewCalc = !viewCalc"
    >
      {{ viewCalc ? t("hideCalc") : t("showCalc") }}
    </button>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useCurrencyCalc } from "@/composables/price";
import PriceItem from "./PriceItem.vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  rawData: {
    type: Object,
    default: () => ({})
  }
});

const currencyCalc = useCurrencyCalc();
const viewCalc = ref(false);

const priceList = computed(() => {
  const result = [];
  result.push({
    type: 'one_day_price',
    base: 0,
    cost: props.rawData.one_day_price,
    nights: props.rawData.nights,
  });

  if (props.rawData.surcharge.dop_persons.cost) {
    result.push(props.rawData.surcharge.dop_persons);
  }

  if (props.rawData.surcharge.cleaning.cost) {
    result.push(props.rawData.surcharge.cleaning);
  }

  if (props.rawData.discounts.sale.cost && props.rawData.is_hot && props.rawData.object_hot?.sale) {
    result.push(props.rawData.discounts.sale);
  }

  if (props.rawData.discounts.interval.cost) {
    result.push(props.rawData.discounts.interval);
  }
  return result;
});
</script>

<style scoped lang="scss">
.price-list {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  &:first-child {
    margin-top: 10px;
  }
}
.toggle-btn {
  cursor: pointer;
  color: #2d6cb4;
  background: transparent;
  border: none;
  text-decoration: none;
  outline: none;
  padding: 0;
  border: 0;
  line-height: 14px;
  font-size: 12px;
  transition: all .3s;
  &:hover {
    color: #f51449;
  }
}
.mobile .toggle-btn:hover{
  color: #2d6cb4;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>
import { computed } from "vue";
import { useStore } from "vuex";

export const useCurrencyCalc = () => {
  const store = useStore();

  return computed(() => {
    const currency = store.getters['detail/getObjectPrice']?.currency;
    if (currency) {
      return {
        id: currency.id,
        title: currency.code,
      }
    }
    return store.state.user.currency;
  });
}

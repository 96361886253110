<i18n lang="json">
{
	"ru": {
    "toSearch": "К поиску",
    "superhost": "суперхозяин",
    "guestRecommend": "гости рекомендуют",
    "basement": "цоколь",
    "description": "{floor} из {floorMax} этажей",
    "note": "Показать ещё",
    "hideNote": "Скрыть",
    "elevator": "есть лифт",
    "bedSingle": "нет односпальных кроватей | 1 односпальная кровать | {n} односпальных кровати | {n} односпальных кроватей",
    "bedDouble": "нет двуспальных кроватей | 1 двуспальная кровать | {n} двуспальных кровати | {n} двуспальных кроватей",
    "double_sofa_bed": "нет двуспальных диван-кроватей | 1 двуспальная диван-кровать | {n} двуспальных диван-кровати | {n} двуспальных диван-кроватей",
    "sleepPlace": "Спальные места",
    "priceNote": "Примечание к стоимости:",
    "single_bed": "нет односпальных кроватей | 1 односпальная кровать | {n} односпальных кровати | {n} односпальных кроватей",
    "double_bed": "нет двуспальных кроватей | 1 двуспальная кровать | {n} двуспальных кровати | {n} двуспальных кроватей",
    "king_bed": "нет кроватей king-size | 1 кровать king-size | {n} кроватей king-size | {n} кроватей king-size",
    "super_king_bed": "нет особо широких двуспальных кроватей | 1 особо широкая двуспальная кровать | {n} особо широких двуспальных кровати | {n} особо широких двуспальных кроватей",
    "yarus_bed": "нет двухъярусных кроватей | 1 двухъярусная кровать | {n} двухъярусных кровати | {n} двухъярусных кроватей",
    "bsofa_bed": "нет диван-кроватей | 1 диван-кровать | {n} диван-кровати | {n} диван-кроватей"
	},
	"en": {
    "toSearch": "To search",
    "superhost": "superhost",
    "guestRecommend": "guests recommend",
    "note": "Show more",
    "hideNote": "Hide",
    "basement": "ground",
    "elevator": "there is an elevator",
    "bedSingle": "no single bed | 1 single bed | {n} single beds | {n} single beds",
    "bedDouble": "no double bed | 1 double bed | {n} double beds | {n} double beds",
    "sleepPlace": "Sleep places",
    "priceNote": "Show price details:",
    "single_bed": "no single bed | 1 single bed | {n} single beds | {n} single beds",
    "double_bed": "no double bed | 1 double bed | {n} double beds | {n} double beds",
    "king_bed": "no king-size beds | 1 king-size bed | {n} king-size beds | {n} king-size beds",
    "super_king_bed": "no extra wide double beds | 1 extra wide double bed | {n} extra wide double beds | {n} extra wide double beds",
    "double_sofa_bed": "no double sofa beds | 1 double sofa bed | {n} double sofa beds | {n} double sofa beds",
    "yarus_bed": "no bunk beds | 1 bunk bed | {n} bunk beds | {n} bunk beds",
    "bsofa_bed": "no sofa beds | 1 sofa bed | {n} sofa beds | {n} sofa beds"
	}
}
</i18n>
<template>
  <div class="info">
    <template v-if="isHotel">
      <p class="info__title tmp-font--big_bold">{{ hotelTypeNaming }}</p>
      <InfoMeal :meal="getObjectData.meal" @clickMeal="scrollMeal" />
      <p class="info__hotel-name" @click="$emit('scrollToHotelCard')">
        <HotelStars
          v-if="isCert"
          :stars-count="hotelStars"
          :is-cert="isCert"
          :show-cert-icon="true"
          :cert-size="14"
          :star-size="9"
          :count-size="16"
        />
        <span class="hotel-name__type">{{ getObjectData.hotel.type }}</span>
        <span>«{{ getObjectData.hotel.title }}»</span>
      </p>
    </template>

    <template v-else>
      <p class="info__title tmp-font--big_bold">{{ title }}</p>
    </template>

    <p class="info__type" v-if="!isHotel">{{ getObjectData.title }}</p>

    <p class="info__location tmp-font--medium">{{ location }}</p>

    <div
      v-if="
        getObjectData.owner.permissions.includes('showSuperhostLabelAbility') ||
        isRecommend ||
        (isHotel && hotelAvgRating) ||
        avgRating ||
        maxRatingExternal
      "
      class="info__options"
    >
      <div
        v-if="
          getObjectData.owner.permissions.includes('showSuperhostLabelAbility')
        "
        class="superhost tmp-font--super-small"
      >
        <span>{{ t("superhost") }}</span>
      </div>
      <div v-if="isRecommend" class="guest-recommend tmp-font--super-small">
        <span>{{ t("guestRecommend") }}</span>
      </div>
      <template v-if="isHotel">
        <div @click="$emit('scrollRating')">
          <Rating
            v-if="hotelAvgRating || maxRatingExternal"
            class="card-prices__rating"
            :data-rating="dataRating"
            :count-reviews="countReviews"
            :view-count="true"
            :view-stars="countReviews > 1"
            :is-small="true"
            :reviews-text="true"
            :is-external-rating="isExternalRating"
          />
        </div>
      </template>
      <template v-else>
        <div @click="$emit('scrollRating')">
          <Rating
            v-if="avgRating || maxRatingExternal"
            class="card-prices__rating"
            :data-rating="dataRating"
            :count-reviews="countReviews"
            :view-count="true"
            :view-stars="countReviews > 1"
            :is-small="true"
            :reviews-text="true"
            :is-external-rating="isExternalRating"
          />
        </div>
      </template>
    </div>
    <InfoMainFeatures :unpicked-features="properties" :elevator="elevator"/>
    <p
      v-if="note?.description"
      class="info__description text-break-spaces"
      :class="{ info__description_cropped: !noteView }"
      @click="noteShow"
    >
      {{ note.description.value }}
    </p>
    <div
      v-if="(note && Object.keys(note).length) || priceNote"
      class="info__note"
    >
      <p v-if="noteView && priceNote" class="tmp-font--medium_bold">{{ t("priceNote") }}</p>
      <p v-if="noteView && priceNote"> {{ priceNote.value }}</p>
      <button
        v-if="note?.description && note.description.value.length > 200"
        class="button button_link tmp-font--link"
        @click="noteShow"
      >
        {{ noteView ? t("hideNote") : t("note") }}
      </button>
    </div>

    <div v-if="beds.length" class="info__sleep-place">
      <p class="sleep-place__title tmp-font--medium_bold">
        {{ t("sleepPlace") }}
      </p>
      <div>
        <span v-for="(bed, i) in beds" :key="i">
          <span>{{ t(bed.name, bed.value) }}</span>
          <span v-if="beds.length > i + 1">,&nbsp;</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import Rating from "@/components/base/BaseRating";
import InfoMeal from "@/components/base/Detail/InfoMeal";
import _ from "lodash";
import InfoMainFeatures from "@/components/mobile/Detail/InfoMainFeatures.vue";
import HotelStars from "@/components/base/Hotel/HotelStars.vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "Info",
  components: {
    InfoMainFeatures,
    HotelStars,
    Rating,
    InfoMeal,
  },
  props: {
    maxRatingExternal: {
      type: Number,
      default: 0,
    },
    countReviewsExternal: {
      type: Number,
      default: 0,
    },
    isExternalRating: {
      type: Boolean,
      default: false,
    },
    countReviewsVal: {
      type: Number,
      default: 0,
    },
    countReviews: {
      type: Number,
      default: 0,
    },
    dataRating: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      noteView: false,
    };
  },
  computed: {
    ...mapGetters("detail", [
      "getObjectData",
      "avgRating",
      "hotelAvgRating",
      "isHotel",
      "reducedBasicProperties",
      "beds",
      "hotelStars",
      "isCert",
      "starCert",
    ]),
    hotelTypeNaming() {
      const { hotel_category, type_title } = this.getObjectData;
      if (type_title) {
        return type_title;
      }
      if (typeof hotel_category.title !== "string") {
        return "";
      }
      return `${hotel_category?.type} ${hotel_category?.title}`;
    },
    location() {
      return `${this.getObjectData.location.location}, ${this.getObjectData.location.address}`;
    },
    properties() {
      return this.getObjectData.properties?.basic_properties?.properties?.reduce(
        (acc, item) => ((acc[item.name] = item.value), acc),
        {}
      );
    },
    title() {
      const findTitle =
        this.getObjectData.properties?.enter_name?.properties?.find(
          (item) => item.name === "name_object"
        )?.value;
      if (findTitle) return findTitle;
      else return this.getObjectData.title;
    },
    description() {
      const date =
        this.getObjectData.properties?.check_in_out_rules?.properties?.reduce(
          (acc, item) => ((acc[item.name] = item.value), acc),
          {}
        );
      let floor;
      if (this.reducedBasicProperties.floor === 0) {
        floor = this.t("basement");
      } else {
        floor = this.reducedBasicProperties.floor;
      }
      if (!floor) {
        return `
        ${this.getObjectData.short_description}.
        ${this.elevator ? this.t("elevator") : ""}`;
      }
      return `
        ${this.getObjectData.short_description}.
        ${this.t("description", {
          floor: floor,
          floorMax: this.reducedBasicProperties.max_floor,
        })},
        ${this.elevator ? this.t("elevator") : ""}`;
    },
    elevator() {
      const elevator =
        this.getObjectData.properties?.availability?.properties?.find(
          (item) => item.name === "lift"
        )?.value;
      if (elevator) {
        return true;
      }
      return false;
    },
    note() {
      let note =
        this.getObjectData.properties?.detail_description?.properties.reduce(
          (acc, item) => {
            if (String(item.value).trim() !== "") acc[item.name] = item;
            return acc;
          },
          {}
        );

      if (
        note &&
        String(note.infrastructure)?.value === "" &&
        String(note.furniture)?.value === "" &&
        String(note.description)?.value === ""
      )
        note = false;
      return note;
    },
    isRecommend() {
      let isRecommend =
        this.getObjectData?.properties?.hidden_properties?.properties?.find(
          (item) => item.name == "is_recommend"
        );
      return isRecommend ? isRecommend.value : false;
    },
    priceNote() {
      if (this.getObjectData?.properties?.additional_payment?.properties) {
        const ratesInfo =
          this.getObjectData.properties.additional_payment.properties.find(
            (p) => p.name === "rates_info"
          );
        if (ratesInfo && ratesInfo?.value) return ratesInfo;
        return null;
      } else {
        return null;
      }
    },
  },
  methods: {
    noteShow() {
      this.noteView = !this.noteView;
      sendEventGtag("object_sa_m", {
        click: "show_description",
      });
    },
    scrollMeal() {
      let blockMeal = document.querySelector("#block-meal");
      if (blockMeal) {
        window.scrollTo({
          top: blockMeal.getBoundingClientRect().top + window.scrollY - 50,
          behavior: "smooth",
        });
        sendEventGtag("object_sa_m", {
          click: "meals",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-break-spaces {
  white-space: break-spaces;
}

.info {
  &__type {
    font-weight: bold;
  }
  &__title {
    color: #000;
    word-wrap: break-word;
  }
  .meal {
    margin-top: 18px;
    margin-bottom: 12px;
  }
  &__hotel-name {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 14px;
    color: #000;
    font-weight: 800;

    .hotel-name {
      &__type {
        margin-right: 3px;
      }
    }
  }
  &__location {
    margin-top: 5px;
    color: #000;
  }
  &__options {
    display: flex;
    flex-wrap: wrap;
    div {
      margin-top: 15px;
    }
  }
  &__facilities {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    .facilities {
      &__main {
        display: flex;
        overflow-x: auto;
        flex-grow: 1;
        justify-content: space-around;
      }
      &__facility {
        margin-right: 20px;
        margin-top: 0;
      }
    }
  }

  &__description {
    &_cropped {
      -webkit-line-clamp: 4;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    margin-top: 10px;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__note {
    margin-top: 10px;

    .note__text {
      margin-top: 20px;
      &.text-break-spaces {
        white-space: break-spaces;
      }
    }
    .note-item {
      margin-top: 5px;
      &--title {
        font-weight: 800;
      }
    }
    .button_link {
      font-size: 12px;
    }
  }

  &__sleep-place {
    margin-top: 20px;
  }

  .superhost,
  .guest-recommend,
  .facilities__size {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 16px;
    border-radius: 2px;
    padding: 0 6px;
    height: 18px;
    // margin-bottom: 15px;
    margin-right: 10px;
    color: #000;
  }

  .superhost {
    background-color: #f1c260;
    text-transform: uppercase;
  }
  .guest-recommend {
    background-color: #d8d8d8;
    text-transform: uppercase;
  }
  .facilities__size {
    border-radius: 0px;
    background-color: #f6f6f6;
    color: #717171;
  }
}
.sleep-place__title {
  margin-bottom: 5px;
}
@media screen and (max-width: 359px) {
  .info {
    &__facilities {
      .facilities {
        &__facility {
          margin-right: 6px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="slider">
    <div @click="modalGalleryShow" class="slider__wrap">
      <SliderLight
        :media="media"
        :dots="true"
        :selected-slide="getSelectedSlide"
        :is-use-lazy-load="false"
        @currentSlide="currentSlide"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SliderLight from "@sutochno/sliderlight";
import sutochnoMetrika from "@/utils/sutochnoMetrika";

export default {
  name: "Slider",
  components: {
    SliderLight,
  },
  data() {
    return {
      slideIndex: 0,
    };
  },
  computed: {
    ...mapState("user", ["isVkApp"]),
    ...mapGetters("detail", ["getObjectData", "getSelectedSlide"]),
    ...mapGetters("search", ["commonParamsForMetrika"]),
    media() {
      return this.getObjectData.media.map((img) =>
        img.image_url.replace("source", "medium")
      );
    },
  },
  created() {
    if (this.isVkApp === false) {
      window.addEventListener("beforeunload", this.removeLocalStorage);
    }
  },
  methods: {
    async currentSlide(val) {
      this.slideIndex = val;
      await this.metrikaCalling('object_img_next');
    },
    removeLocalStorage() {
      localStorage.removeItem("selectedSlide");
    },
    metrikaCalling(screenBlock = 'object_img') {
      sutochnoMetrika.detail('click', screenBlock, {
        ...this.commonParamsForMetrika,
        page: "detail",
      })
    },
    async modalGalleryShow() {
      await this.metrikaCalling()

      const slideIndex = {
        slideIndex: this.slideIndex
      }
      this.$router.push({
        name: "DetailFoto",
        query: {...this.$route.query, ...slideIndex},
        params: {
          id: this.$route.params.id
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.slider {
  height: 326px;
  &__wrap {
    width: 100%;
    height: 100%;
  }
}
</style>

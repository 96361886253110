import { computed } from 'vue'
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default () => { 
  const { t } = useI18n({ useScope: "global" });
  const store = useStore();

  const setTitle = (title) => store.dispatch("detail/setTitle", title);

  const title = computed(() => {
    const objectData = store.getters['detail/getObjectData'];
    const titlePage = store.getters['detail/getTitlePage'];
    const isHotel = store.getters['detail/isHotel'];;

    if ((objectData && objectData.location) || titlePage) {
      if (titlePage) return titlePage;
      let title = `${
        isHotel ? objectData.type_title : objectData.title
      } ${t("meta.detail.perDay")}, ${objectData.location.location}, ${
        objectData.location.address
      }, ${t("meta.detail.ad")} ${objectData.id} - ${t("meta.detail.sutochno")}`;
      setTitle(title);
      return title;
    } else {
      return t("meta.index.title");
    }
  });

  useHead({
    title,
  })
}
<i18n lang="json">
{
	"ru": {
    "toSearch": "К поиску",
    "titleMap": "Расположение",
    "titleComfort": "Основные удобства",
    "titleAboutHotel": "Объект размещения",
    "titleOwner": "Хозяин жилья",
    "titlePrice": "Дополнительно к цене",
    "titleReviews": "нет отзывов | {n} отзыв | {n} отзыва от гостей | {n} отзывов от гостей",
    "titleNoReviews": "Нет отзывов",
    "titleRules": "Правила объекта размещения",
    "titleAvailabileRooms": "Доступные номера на выбранный период",
    "externalRating": "Оценка сформирована по реальным отзывам гостей на зарубежных сервисах, ушедших из России. Мы не публикуем отзывы целиком, т.к. гости не давали на это явного согласия. Возможно, отзывы появятся позже."
	},
	"en": {
    "toSearch": "To search",
    "titleMap": "Location",
    "titleComfort": "Main comfort",
    "titleAboutHotel": "Accommodation object",
    "titleOwner": "Homeowner",
    "titlePrice": "In addition to the price",
    "titleReviews": "not reviews | {n} review  | {n} reviews from guests | {n} reviews from guests",
    "titleNoReviews": "No reviews",
    "titleRules": "Object's posting rules",
    "titleAvailabileRooms": "Available rooms for the selected period"
	}
}
</i18n>
<template>
  <div
    v-if="getObjectData"
    class="detail tmp-font--medium"
    :class="{ 'not-auth': !isAuth, 'new-view': isNewViewBtn }"
    itemscope
  >
    <template v-if="!getObjectShow">
      <Empty />
      <div class="detail__box">
        <div class="box__body">
          <Owner />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="detail__wrap">
        <div class="detail__head">
          <Panel :id="String(id)" />
        </div>

        <div class="detail__content">
          <div class="detail__box detail__box_merge">
            <Slider />
          </div>

          <div class="detail__box detail__box_merge">
            <div class="box__body">
              <Info
                :max-rating-external="maxRatingExternalObj"
                :count-reviews-external="countReviewsExternalObj"
                :is-external-rating="isExternalRating"
                :count-reviews-val="countReviewsVal"
                :count-reviews="countReviews"
                :data-rating="dataRating"
                @scrollRating="scrollRating()"
                @scrollToHotelCard="this.scrollToBlock('hotelCard', 50)"
              />
            </div>
          </div>

          <div v-show="isCheck" class="detail__box block-booking-top">
            <div class="box__body">
              <BookingForm />
            </div>
          </div>

          <div ref="map" class="detail__box">
            <p class="box__title tmp-font--average_bold">
              {{ t("titleMap") }}
            </p>
            <div class>
              <Map />
            </div>
          </div>

          <div v-show="isCheck" class="detail__box block-booking-cen">
            <div class="box__body">
              <BookingForm />
            </div>
          </div>

          <div class="detail__box">
            <p class="box__title tmp-font--average_bold">
              {{ t("titleComfort") }}
            </p>
            <div class="box__body">
              <Comfort />
            </div>
          </div>

          <template v-if="isHotel">
            <div class="detail__box">
              <!-- <p class="box__title tmp-font--average_bold">{{ t('titleAboutHotel') }}</p> -->
              <p
                v-if="getFieldFromHotelTypes?.about"
                class="box__title tmp-font--average_bold"
              >
                {{ getFieldFromHotelTypes.about }}
              </p>
              <template v-if="hotelDataLoad">
                <div class="box__body">
                  <div class="box__body_load">
                    <div class="loading"></div>
                  </div>
                </div>
              </template>
              <template v-else>
                <SliderHotel />
                <div class="box__body" ref="hotelCard">
                  <AboutHotel />
                </div>
              </template>
            </div>
            <div
              v-if="
                getHotelData &&
                getHotelData.objects &&
                getHotelData.objects.length > 1
              "
              class="detail__box"
            >
              <p class="box__title tmp-font--average_bold">
                {{ t("titleAvailabileRooms") }}
              </p>
              <RoomsList />
            </div>

            <div ref="reviews" class="detail__box">
              <div class="box__together-title">
                <template v-if="countReviews">
                  <p class="box__title tmp-font--average_bold">
                    {{ t("titleReviews", hotelReviewsCount) }}
                  </p>
                  <div class="box__together-title-rating">
                    <BaseRating
                      v-if="
                        (hotelAvgRating &&
                          hotelAvgRating.ratings &&
                          hotelAvgRating.ratings.avg_rating) ||
                        maxRatingExternalObj
                      "
                      :data-rating="dataRating"
                      :view-count="countReviews > 1"
                      :view-stars="countReviews > 1"
                      :is-small="true"
                      :scale="true"
                      class="tmp-font--average_big_bold"
                      :is-external-rating="isExternalRating"
                    />
                  </div>
                </template>
                <template v-else>
                  <p class="box__title tmp-font--average_bold">
                    {{ t("titleNoReviews") }}
                  </p>
                </template>
              </div>
              <div v-if="getHotelData && getHotelData.hotel" class="box__body">
                <Reviews
                  :object-data="getObjectData"
                  :hotel-data="getHotelData.hotel"
                  :object-reviews="getObjectReviews"
                  :hotel-reviews="getHotelReviews"
                />
                <p v-if="maxRatingExternalHotel" class="reviews__item-text">
                  {{ t("externalRating") }}
                </p>
              </div>
            </div>
          </template>

          <div v-if="!isHotel" ref="reviews" class="detail__box">
            <div class="box__together-title">
              <template v-if="countReviews">
                <p class="box__title tmp-font--average_bold">
                  {{ t("titleReviews", countReviews) }}
                </p>
                <div class="box__together-title-rating">
                  <BaseRating
                    v-if="
                      (avgRating &&
                        avgRating.ratings &&
                        avgRating.ratings.avg_rating) ||
                      maxRatingExternalObj
                    "
                    :data-rating="dataRating"
                    :view-count="countReviews > 1"
                    :view-stars="countReviews > 1"
                    :is-small="true"
                    :scale="true"
                    class="tmp-font--average_big_bold"
                    :is-external-rating="isExternalRating"
                  />
                </div>
              </template>
              <template v-else>
                <p class="box__title tmp-font--average_bold">
                  {{ t("titleNoReviews") }}
                </p>
              </template>
            </div>
            <div class="box__body">
              <Reviews
                :object-data="getObjectData"
                :object-reviews="getObjectReviews"
                :is-external-rating="isExternalRating"
                :count-reviews="countReviews"
              />
            </div>
          </div>

          <div class="detail__box">
            <!-- <p class="box__title" v-if="isHotel">{{ getHotelData.hotel.type_title }}</p> -->
            <div class="box__body">
              <Owner />
            </div>
          </div>

          <div class="detail__box">
            <Instant />
          </div>

          <div class="detail__box">
            <p class="box__title tmp-font--average_bold">
              {{ t("titleRules") }}
            </p>
            <div class="box__body">
              <Rules />
            </div>
          </div>

          <div class="detail__box detail__box_min" v-if="!hotId && !offerId">
            <div class="box__body">
              <CheckInOut />
            </div>
          </div>

          <FreeCancellation
            v-if="isFreeCancellation"
            :arrival-day="checkInFreeCancellation"
            :free-cancellation-day="dateFreeCancellation"
            :is-occupied="occupied"
          />

          <ViewPhone
            :calc-info="getObjectPrice ? { data: getObjectPrice } : null"
            :object="getObjectData"
            :is-bronevik="isBronevik"
            :is-always="!!whitelabel"
            :whitelabel="whitelabel"
          />
        </div>
        <BookingButton />
      </div>
    </template>
  </div>
  <div v-else class="detail-loader">
    <div class="loading"></div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import setViewedObjectsStorage from "@/utils/setViewedObjectsStorage";
import Empty from "./Empty";
import Panel from "./Panel";
import Slider from "./Slider";
import Info from "./Info";
import Map from "./Map";
import BookingForm from "./BookingForm";
import Comfort from "./Comfort";
import SliderHotel from "./Hotel/Slider";
import AboutHotel from "./Hotel/About";
import RoomsList from "./Hotel/RoomsList";
import Reviews from "./Reviews";
import Owner from "./Owner";
import Instant from "./Instant";
import Rules from "./Rules";
import ViewPhone from "@/components/base/Detail/Aside/ViewPhone";
import CheckInOut from "./CheckInOut";
import BaseRating from "@/components/base/BaseRating";
// import BaseModal from "@/components/base/BaseModal";
import BookingButton from "./BookingButton";
// import Booking from "./Booking";
import { format, formatWithTime, formatSafari } from "@/utils/format";
import _ from "lodash";
import { Detail } from "@/utils/gtm/";
import hotelTypes from "@/assets/json/hotelTypes.json";
import FreeCancellation from "@/components/base/Detail/FreeCancellation";
import { HTTP, Offers } from "@/components/api/";
import { pushDataToYM } from "@/utils/yandexMetrica";
import dataLayerPushGA4 from "@/mixins/dataLayerPushGA4.js";
import { useI18n } from 'vue-i18n';
import sutochnoMetrika from "@/utils/sutochnoMetrika";
import getUserIP from "@/utils/getUserIP";
import { intersectObserver } from "@/utils/intersectObserver";
import useMetaDetail from "@/composables/meta/detail/index";

export default {
  name: "Detail",
  mixins: [dataLayerPushGA4],
  setup() {
    const { t } = useI18n();

    useMetaDetail();

    return {
      t
    }
  },
  components: {
    Empty,
    Panel,
    Slider,
    Info,
    Map,
    BookingForm,
    Comfort,
    SliderHotel,
    AboutHotel,
    RoomsList,
    Reviews,
    Owner,
    Instant,
    Rules,
    CheckInOut,
    ViewPhone,
    BaseRating,
    BookingButton,
    FreeCancellation,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bookingView: false,
      hotelTypes,
      scrolledToReview: false,
      isOffer: false,
      scrollToFooterObserver: null,
      metrikSent: false
    };
  },
  provide: function () {
    return {
      id: this.id,
    };
  },
  watch: {
    time(val) {
      this.objectPrice();
    },
    "currency.id"(val) {
      this.objectPrice();
    },
    getObjectPrice(val) {
      if(val && !this.metrikSent) {
        sutochnoMetrika.detail('view', '', {
          ...this.commonParamsForMetrika,
          page: "detail",
        })
        this.metrikSent = true;
      }
    }
  },
  beforeUpdate() {
    window.removeEventListener("scroll", this.scrollToReview);
  },
  updated() {
    window.addEventListener("scroll", this.scrollToReview);
  },
  async mounted() {
    setViewedObjectsStorage(this.id);
    await getUserIP();
    if (this.$route.query.review) {
      this.$router.push({
        name: "Review",
        params: {
          objectId: this.$route.params.id,
        },
      });
    }
    if (this.$route.query.showMap === 'true') {
      this.scrollMap();
    }
    this.setObjectData(Number(this.id))
      .then((res) => {
        if (this.isBronevik)
          sendEventGtag("test_bronevik", {
            view_object: res.data.data.object.location.location,
          });
        if (res.data.data.object.hotel) {
          this.setHotelAvgRating(res.data.data.object.hotel.id);
          this.setHotelData(res.data.data.object.hotel.id);
        }
        this.$nextTick(() => {
          this.setQuery();
          // https://jira.webpower.ru/browse/VUECLIENT-1811
          // this.dataLayerPush("view_item")
          // this.gaCreateProduct();
        });
      })
      .catch((err) => {
        showError({ statusCode: 404, statusMessage: "Page Not Found" });
      });
    this.objectPrice();
    // this.initLocation();
    setTimeout(() => {
      Detail.gtmObjectPage(
        this.id,
        this.getObjectPrice?.price || this.getObjectPrice?.price_default
      );

      const dataForYM = this.getDataForYM("detail", this.$route.name);
      pushDataToYM(dataForYM);
      this.dataLayerPush("view_item");

      this.initScrollObserver();
    }, 3000);
    if (this.hotId && this.offerId) {
      try {
        await this.setOfferDataForUser();
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    ...mapGetters("detail", [
      "getHotelData",
      "getHotelReviews",
      "getObjectShow",
      "getTitlePage",
      "getObjectData",
      "getObjectReviews",
      "avgRating",
      "hotelAvgRating",
      "getObjectPrice",
      "getInstant",
      "isBronevik",
      "isHotel",
      "getObjectPrice",
      "getDataForYM",
    ]),
    ...mapGetters("search", [
      "maxGuests",
      "getQuery",
      "getQuery",
      "occupied",
      "commonParamsForMetrika",
    ]),
    ...mapState("search", ["checkIn", "checkOut"]),
    ...mapState("user", ["currency", "isAuth", "whitelabel", "userData"]),
    ...mapState("detail", ["hotelDataLoad", "locationObject", "currentOffer"]),
    getFieldFromHotelTypes() {
      if (
        this.getHotelData?.hotel?.type_name &&
        hotelTypes[this.getHotelData.hotel.type_name]
      ) {
        return hotelTypes[this.getHotelData.hotel.type_name];
      }
      return null;
    },
    offerId() {
      return this?.$route?.query?.offer_id > 0
        ? Number(this.$route.query.offer_id)
        : false;
    },
    hotId() {
      return this?.$route?.query?.hot_id > 0
        ? Number(this.$route.query.hot_id)
        : false;
    },
    isCheck() {
      return this.checkIn && this.checkOut;
    },
    isFc() {
      let item = false;
      if (this.getObjectData?.properties?.hidden_properties) {
        item = this.getObjectData.properties.hidden_properties.properties.find(
          (item) => item.name == "fc"
        );
        item = item ? item.value : false;
      }
      return item;
    },
    dateFreeCancellation() {
      if (this.checkIn) {
        let lastDay = new Date(this.checkIn.getTime() - 86400000 * 4);
        return lastDay
          .toLocaleString("ru-RU", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })
          .replace(" г.", "");
      } else {
        return "";
      }
    },
    checkInFreeCancellation() {
      if (this.checkIn) {
        return this.checkIn
          .toLocaleString("ru-RU", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })
          .replace(" г.", "");
      } else {
        return "";
      }
    },
    isFreeCancellation() {
      if (this.isFc) {
        if (this.occupied && this.checkIn) {
          let сurrentTime = new Date(
            new Date().toISOString().slice(0, 10) + "T00:00:00"
          ).getTime();
          let amountDays = Math.round(
            (this.checkIn.getTime() - сurrentTime) / 86400000
          );

          if (amountDays < 5) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    isShowMap() {
      return Boolean(this.$route.query.showMap);
    },
    isShowMetro() {
      return this.$route.query.isShowMetro;
    },
    isShowComments() {
      return Boolean(this.$route.query.showComments);
    },
    objectAdditionalPaymentProperties() {
      return this.getObjectData?.properties?.additional_payment?.properties || [];
    },
    time() {
      const rules =
        this.getObjectData?.properties?.check_in_out_rules?.properties;

      const checkInOut = this.objectAdditionalPaymentProperties.filter(
          (item) => item.name === "check_in" || item.name === "check_out"
        );

      if (rules) {
        return rules.reduce((acc, item) => {
          acc[item.name] = item;
          return acc;
        }, {});
      } else if (checkInOut) {
        return checkInOut.reduce((acc, item) => {
          acc[item.name] = item;
          return acc;
        }, {});
      } else {
        return false;
      }
    },
    price() {
      //Для вывода или скрытия блока Дополнительно к цене
      return this.getObjectData?.extprices.find(
        (item) => item.interval_start == "0"
      );
    },
    payment() {
      //Для вывода или скрытия блока Дополнительно к цене
      return this.objectAdditionalPaymentProperties.reduce(
        (acc, item) => ((acc[item.name] = item.value), acc),
        {}
      );
    },
    linen() {
      const hotelPayedProperties = this.getHotelData?.hotel?.properties?.payed?.properties || [];
      const linen =
        hotelPayedProperties.find(
          (item) => item.name == "linen_cost_1"
        )?.value;
      if (linen) return linen;
      else return false;
    },
    parking() {
      const hotelParkingProperties = this.getHotelData?.hotel?.properties?.parking?.properties || [];

      const parking =
        hotelParkingProperties.find(
          (item) => item.name == "parking_cost_1"
        )?.value;
      if (parking) return parking;
      else return false;
    },
    internet() {
      const hotelInternetProperties = this.getHotelData?.hotel?.properties?.internet?.properties || [];

      const internet =
        hotelInternetProperties.find(
          (item) => item.name == "internet_cost_1"
        )?.value;
      if (internet) return internet;
      else return false;
    },
    maxRatingExternalObj() {
      return this.maxRatingExternal
        ? this.maxRatingExternal
        : this.maxRatingExternalHotel;
    },
    maxRatingExternal() {
      const externalReviews = this.getObjectData?.external_reviews;
      if (externalReviews) {
        const ratingAirbnb = externalReviews[0]["rating"]
          ? externalReviews[0]["rating"]
          : 0;
        const ratingBooking = externalReviews[1]["rating"]
          ? externalReviews[1]["rating"]
          : 0;

        return Math.max(ratingAirbnb, ratingBooking);
      } else {
        return 0;
      }
    },
    maxRatingExternalHotel() {
      const externalReviews = this.getHotelData?.hotel?.external_reviews;
      if (externalReviews) {
        const ratingAirbnb = externalReviews[0]["rating"]
          ? externalReviews[0]["rating"]
          : 0;
        const ratingBooking = externalReviews[1]["rating"]
          ? externalReviews[1]["rating"]
          : 0;

        return Math.max(ratingAirbnb, ratingBooking);
      } else {
        return 0;
      }
    },
    countReviewsExternalObj() {
      return this.countReviewsExternal
        ? this.countReviewsExternal
        : this.countReviewsExternalHotel;
    },
    countReviewsExternal() {
      const externalReviews = this.getObjectData?.external_reviews;
      if (externalReviews) {
        const countAirbnb = externalReviews[0]["count"]
          ? externalReviews[0]["count"]
          : 0;
        const countBooking = externalReviews[1]["count"]
          ? externalReviews[1]["count"]
          : 0;

        return countAirbnb + countBooking;
      } else {
        return 0;
      }
    },
    countReviewsExternalHotel() {
      const externalReviews = this.getHotelData?.hotel?.external_reviews;
      if (externalReviews) {
        const countAirbnb = externalReviews[0]["count"]
          ? externalReviews[0]["count"]
          : 0;
        const countBooking = externalReviews[1]["count"]
          ? externalReviews[1]["count"]
          : 0;

        return countAirbnb + countBooking;
      } else {
        return 0;
      }
    },
    isExternalRating() {
      if (this.countReviewsVal <= 1 && this.maxRatingExternalObj > 0) {
        return true;
      } else {
        return false;
      }
    },
    countReviewsVal() {
      let countReviews = 0;
      if (this.isHotel) {
        countReviews = this.getHotelReviews?.count;
      } else if (this.avgRating?.count) {
        countReviews = this.avgRating.count;
      }
      return countReviews;
    },
    countReviews() {
      return this.countReviewsVal + this.countReviewsExternalObj;
    },
    hotelReviewsCount() {
      return this.hotelAvgRating?.count || 0;
    },
    dataRating() {
      if (this.isExternalRating) {
        return this.maxRatingExternalObj;
      } else {
        if (this.countReviewsVal > 1) {
          return this.isHotel
            ? Number(this.hotelAvgRating?.ratings?.avg_rating)
            : Number(this.avgRating?.ratings?.avg_rating);
        } else {
          return 0;
        }
      }
    },
    isNewViewBtn() {
      return this.userData && !this.userData?.balance_virtual;
    },
  },
  methods: {
    ...mapActions("detail", [
      "setObjectData",
      "setAvgRating",
      "setObjectPrice",
      "setHotelData",
      "setHotelAvgRating",
      "fetchOffersListToUser",
      "setCurrentOffer",
    ]),
    ...mapMutations("detail", ["objectDataMutate", "setLocationObject"]),
    ...mapMutations("search", ["setCheckIn", "setCheckOut"]),
    async setOfferDataForUser() {
      await this.fetchOffersListToUser(this.hotId);
      this.setCurrentOffer(this.offerId);
      if (!this.currentOffer) {
        return;
      }
      const { hot } = this.currentOffer;
      this.setCheckIn(new Date(hot?.dateBegin));
      this.setCheckOut(new Date(hot?.dateEnd));
    },
    initScrollObserver() {
      const { observer, initObserve, targets } = intersectObserver(() => {
        sutochnoMetrika.detail('scroll', '', {
          ...this.commonParamsForMetrika,
          page: "detail",
        });
      }, ".interval", { threshold: 0.1 });
      this.scrollToFooterObserver = observer;
      initObserve(this.scrollToFooterObserver, targets);
    },
    scrollToReview() {
      const review = this.$refs.reviews;
      if (review) {
        const top = window.pageYOffset + review.getBoundingClientRect().top;
        if (window.pageYOffset > top - 50 && !this.scrolledToReview) {
          this.scrolledToReview = true;
          Detail.gaScrollToReview("mobile", this.getObjectData?.id);
        }
      }
    },
    // https://jira.webpower.ru/browse/VUECLIENT-1811
    // gaCreateProduct() {
    //   Detail.gaCreateProduct(
    //     this.id,
    //     String(
    //       this.getObjectData.extprices?.find(
    //         (item) => item.interval_start === 0
    //       )?.value || "?"
    //     ),
    //     "5",
    //     this.checkIn ? format(this.checkIn) : null,
    //     this.checkOut ? format(this.checkOut) : null,
    //     this.getObjectData.title,
    //     this.getObjectData.location.location,
    //     this.getObjectData.type_title.charAt(0).toUpperCase() +
    //       this.getObjectData.type_title.substr(1),
    //     `${this.t(
    //       "rooms",
    //       this.getObjectData?.properties?.basic_properties?.properties?.find(
    //         (item) => item.name == "rooms"
    //       )?.value || 1
    //     )}`,
    //     this.getObjectData.position
    //   );
    // },
    setQuery() {
      const query = {
        hash: this.getQuery.hash,
        query: _.omit(this.getQuery.query, ["lat", "lng", "zoom"]),
      };
      navigateTo(query);
    },
    scrollMap() {
      if (this.$refs.map) {
        this.scrollToBlock('map');
      } else {
        setTimeout(() => this.scrollMap(), 100);
      }
    },
    scrollRating() {
      this.scrollToBlock("reviews");
      sendEventGtag("object_sa_m", {
        click: "go2reviews",
      });
    },
    scrollToBlock(ref, offset = 70) {
      window.scrollTo({
        top: this.$refs[ref].getBoundingClientRect().top - offset,
        behavior: "smooth",
      });
    },
    objectPrice: _.debounce(function () {
      const time = this.time || {};
      const timeIn = time.check_in?.value.match(/\d{2}/);
      const timeOut = time.check_out?.value.match(/\d{2}/);
      
      if (this.maxGuests && timeIn && timeOut) {
        this.setObjectPrice({
          guests: Number(this.maxGuests),
          time_begin: timeIn,
          time_end: timeOut,
          date_begin: this.formatDate(this.checkIn, timeIn),
          date_end: this.formatDate(this.checkOut, timeOut, "next"),
          objects: Number(this.id),
          currency_id: this.currency.id,
        });
      }
    }, 1000),
    formatDate(date, time, type = "") {
      if (!this.checkIn && !this.checkOut) {
        const date = new Date();
        if (type === "next") {
          date.setDate(date.getDate() + 1);
        }
        return format(new Date(date));
      }
      let newDate = new Date(formatSafari(formatWithTime(date)));
      newDate.setHours(time);
      return formatWithTime(newDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  position: relative;

  // display: flex;
  // justify-content: center;

  padding-bottom: 112px;

  &.new-view {
    padding-bottom: 130px;
  }
  &-loader {
    height: calc(100vh - 50px);
    position: relative;
  }

  &__wrap {
    // max-width: 360px;
  }

  &__head {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 199;
  }

  &__box {
    margin-top: 40px;
    &:first-child {
      margin-top: 0;
    }

    .box {
      &__title {
        color: #000;
        margin: 10px 20px;
      }
      &__together-title {
        position: relative;
        &-rating {
          position: absolute;
          right: 20px;
          bottom: 0;
        }
      }
      &__body {
        background-color: #fff;
        padding: 20px;

        &_load {
          position: relative;
          height: 240px;
        }
      }
    }

    &_merge {
      margin-top: 0;
    }
    &_min {
      margin-top: 20px;
    }
  }
  .booking {
    &__modal {
      padding-bottom: 20px;
    }
  }
}

.box__together-title-rating {
  :deep(.rating-list__rating) {
    font-size: 16px !important;
  }
}

:deep() {
  .fade {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.3s;
    }
    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }
}

.block-booking-top {
  display: none;
}

.detail.not-auth {
  .block-booking-top {
    display: block;
  }
  .block-booking-cen {
    display: none;
  }
}
.reviews__item-text {
  color: #7f7f7f;
  margin-top: 20px;
  text-align: justify;
}
</style>

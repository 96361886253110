<i18n lang="json">
{
  "ru": {
    "bookingRequestSent": "Запрос на бронь отправлен",
    "moreBookingHint":"Предоплата — от 15% до 100% от общей стоимости проживания, чаще всего не более 20%. Общую стоимость проживания владелец жилья может повысить при раннем заезде, позднем отъезде, проведении вечеринок и заселении с животными.",
    "bookingRequestSentText_1": {
      "left": "Если жильё доступно на ваши даты, вы получите уведомление с суммой предоплаты ",
      "right": " и общей стоимостью проживания.",
      "bottom": "Для оформления брони вам нужно будет внести предоплату (она поступит владельцу жилья только после вашего успешного заселения)."
    },
    "bookingRequestSentText_2": "Чтобы забронировать быстрее, отправьте больше запросов!",
    "bookingRequestSentText_3": "Сейчас на Суточно.ру высокий спрос на бронирования. Посмотрите другие варианты и отправьте ещё несколько запросов — так вы оформите бронь гораздо быстрее!",
    "seeOptions": "Смотреть варианты"
  },
  "en": {
    "bookingRequestSent": "Booking request has been sent",
    "moreBookingHint": "Prepayment - from 15% to 100% of the total cost of living, most often 20%. The owner can increase the total cost for early check-in, late check-out, parties and high demand.",
    "bookingRequestSentText_1": {
      "left": "If the accommodation is available for your dates, you will receive a notification with the amount of the prepayment ",
      "right": " and the total cost of the stay",
      "bottom": "Для оформления брони вам нужно будет внести предоплату (она поступит владельцу жилья только после вашего успешного заселения)."
    },
    "bookingRequestSentText_2": "To book faster, send more requests!",
    "bookingRequestSentText_3": "Now there is a high demand for bookings on Sutochno.ru. Look at other options and send a few more requests - this way you will make your reservation much faster",
    "seeOptions": "See options"
  }
}
</i18n>
<template>
  <div>
    <Modal
      ref="modalRequestSent"
      :value="true"
      theme="light"
      class="booking__modal"
      @close="closeModalReq"
    >
      <div class="booking-request-sent">
        <div class="booking-request-sent--title">
          {{ t("bookingRequestSent") }}
        </div>
        <div class="booking-request-sent--body">
          <span>{{ t("bookingRequestSentText_1.left") }}</span>
          <span
            class="icon-app-question"
            v-tooltip="{
              triggers: ['click'],
              content: t('moreBookingHint'),
              popperClass: 'v-popper__popper--max-width-300',
            }"
          ></span>
          <span>{{ t("bookingRequestSentText_1.right") }}</span>
          <div>{{ t("bookingRequestSentText_1.bottom") }}</div>
          <div class="block-info">
            <div class="block-info--title">
              {{ t("bookingRequestSentText_2") }}
            </div>
            <div class="block-info--body">
              {{ t("bookingRequestSentText_3") }}
            </div>
          </div>
        </div>
        <button
          class="button button_blue button_size_md width-100"
          :class="{'loading button_disabled': btnLoading}"
          @click="seeOptions"
        >
          {{ t("seeOptions") }}
        </button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@sutochno/modal";
import { mapMutations, mapGetters } from "vuex";
import { isNavigationFailure } from "vue-router";
import { useI18n } from 'vue-i18n';

export default {
  name: "modalRequestSent",
  props: {
    showModalSend: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data () {
    return {
      btnLoading: false
    }
  },
  mounted() {
    if (this.showModalSend) {
      this.$refs.modalRequestSent.show()
    }
  },
  watch: {
    showModalSend(val) {
      if (val) {
        this.$refs.modalRequestSent.show()
      }
    }
  },
  components: {
    Modal
  },
  computed: {
    ...mapGetters("detail", ["getObjectData"]),
  },
  methods: {
    ...mapMutations("detailBooking", ["setModalStep", "setIsModalOpen"]),
    closeModalReq() {
      this.setIsModalOpen(false)
      this.setModalStep("calendar")
      this.$emit("closeModalRequestSent", true)
      this.$router.push({
        name: "Detail",
        params: { id: this.getObjectData.id },
      });
    },
    async seeOptions() {
      this.setIsModalOpen(false);
      this.setModalStep("booking");
      this.btnLoading = true;

      await this.$router.push({
        name: "Search",
        query: {
          ...JSON.parse(localStorage.getItem("query") || "{}")
        },
      })
        .then(async (failure) => {
          if (isNavigationFailure(failure)) {
            console.error('ModalRequestSent/seeOptions - ошибка выполнения');
            console.warn('ModalRequestSent/seeOptions - запускаем повторно');
            await this.seeOptions();
          } else {
            this.btnLoading = false;
            console.log('ModalRequestSent/seeOptions - успешно выполнелся');
          }
        })
        .catch(async err => {
          // TODO: проверить работает ли catch в vue-router
          console.error('ModalRequestSent/seeOptions - ошибка выполнения');
          console.warn('ModalRequestSent/seeOptions - запускаем повторно');
          await this.seeOptions();
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.booking-request-sent {
  padding: 20px;
  font-size: 14px;
  &--title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
  }
  &--body {
    .icon-app-question {
      cursor: pointer;
      font-size: 16px;
      margin-right: 0px;
      margin-top: 1px;
      vertical-align: text-top;
      display: inline-block;
    }
    .block-info {
      font-size: 12px;
      padding: 10px;
      border-radius: 3px;
      background-color: #e4f3ff;
      margin-top: 40px;
      margin-bottom: 20px;
      &--title {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
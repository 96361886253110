<i18n lang="json">
{
	"ru": {
    "guests":"{n} гостей | {n} гостя | {n} гостей | {n} гостей",
    "ages": "0|c {n} года | {n} лет| {n} лет",
    "placeholderPets": "Описание",
    "subTitle":"Жильё вмещает максимум {cnt}",
    "title":"Гости",
    "addPets": "Питомцы",
    "petsRestriction": {
      "0": "нельзя с питомцами",
      "1": "укажите какие",
      "2": "по согласованию"
    },
    "petsExample":"Например: померанский шпиц весом 2 кг",
    "warning": {
      "maxLimit": "Внимание! Жильё вмещает максимум {cnt}. Уменьшите их количество или найдите более вместительный объект.",
      "noPets": "Хозяин не готов принимать гостей с питомцами. Удалите это поле или выберите другое жильё.",
      "requestPets":"Хозяин заселяет гостей с питомцами по согласованию. Ваш запрос уйдёт на рассмотрение.",
      "noChildren": "Хозяин не готов принимать гостей с детьми. Удалите это поле или выберите другое жильё.",
      "requestChildren":"Хозяин заселяет гостей с детьми по согласованию. Ваш запрос уйдёт на рассмотрение.",
      "minAge": "Внимание, владелец жилья указал, что можно с детьми {age} и старше",
      "noPetsDescription": "Укажите с какими питомцами путешествуете",
      "freeBabies": "Дети 1 года и младше размещаются бесплатно, но без дополнительного спального места.",
      "freeBabiesOnly": "Хозяин не принимает гостей с детьми до {age}, но можно заселиться с детьми 1 года и младше",
      "freeBabiesAllChildren": "Можно с детьми любого возраста, дети 1 года и младше размещаются бесплатно, но без дополнительного спального места"
    },
    "noChildren": "Хозяин не готов принимать гостей с детьми",
    "noPets": "Хозяин не готов принимать гостей с питомцами",
    "youngerСhildren": "Хозяин не готов принимать гостей с детьми младше {age}",
    "youngerСhildrenAges": "0| {n} года | {n} лет| {n} лет",
    "youngerСhildrenFreeBabies": "Хозяин не принимает гостей с детьми младше {age}, но заселяет с младенцами 1 года и младше"
	},
	"en": {
    "guests": "{n} guests | {n} guests | {n} guests | {n} guests",
    "ages": "0| from {n} year | {n} years| {n} years",
    "placeholderPets": "Description of animals",
    "subTitle": "Accommodation can accommodate maximum {cnt}",
    "title": "Guests",
    "addPets": "Pets",
    "petsExample":"Example: 2kg Pomeranian",
    "petsRestriction": {
      "0": "no pets",
      "1": "specify which",
      "2": "by agreement"
    },
    "warning": {
      "maxLimit": "Attention! Housing can hold a maximum of {cnt}. Reduce their number or find a more capacious object.",
      "noPets": "The host is not willing to accept guests with pets. Please remove this field or select another property.",
      "requestPets": "The host will check in guests with pets by agreement. Your request will be processed.",
      "noChildren": "The host is not willing to host children. Please remove this field or select another accommodation.",
      "requestChildren": "The host will check in guests with children by agreement. Your request will be processed.",
      "minAge":"Attention, the landlord indicated that it is possible with children {age} and older",
      "noPetsDescription": "Please indicate which pets you are traveling with",
      "freeBabies": "Children 1 year and younger stay free, but do not require an extra bed.",
      "freeBabiesOnly": "The host does not accept children under {age} years old, but children 1 year and younger are welcome",
      "freeBabiesAllChildren": "Children of any age are welcome; children 1 year and younger are accommodated free of charge, but without an extra bed"
    },
    "noChildren": "The host is not willing to host children",
    "noPets": "The host is not willing to accept guests with pets",
    "youngerСhildren": "The owner is not ready to accept guests with children under {age}",
    "youngerСhildrenAges": "0| {n} year | {n} years| {n} years",
    "youngerСhildrenFreeBabies": "The host does not accept guests with children under {age}, but will accommodate infants 1 year and younger"
	}
}
</i18n>
<template>
  <div class="step-guests">
    <div class="step-guests__container">
      <div class="step-guests__header">
        <p class="step-guests__title">
          {{ t("title") }}
        </p>
        <p class="step-guests__suptitle">
          {{
            t("subTitle", {
              cnt: t("guests", maxGuests),
            })
          }}
        </p>
      </div>
    </div>
    <div class="step-guests__body">
      <hr />
      <div class="step-guests__container">
        <GuestsCounter v-model="adults" class="adults" />
      </div>
      <hr />
      <div class="step-guests__container">
        <ChildrenSelect
          :children="children"
          :restriction="getChildRestrictionValue"
          :popoverText="getChildPopoverText"
          :free-babies="freeBabies"
          @addChild="addChild"
          @changeChildAge="changeChildAge"
          @removeChild="removeChild"
        />
      </div>
      <hr />
      <div class="step-guests__container">
        <div class="select-guests__add-pets add-pets">
          <div class="add-pets__text">
            <strong>{{ t("addPets") }}</strong>
            <span
              :class="classesForPetsPopover"
              :data-hint="getPetsPopoverText"
              >{{ getPetsRestrictionText }}</span>
          </div>
          <UISwetcher v-model="petsValue" />
        </div>

        <template v-if="petsValue">
          <UITextarea
            v-model="petsDescription"
            class="step-guests__item"
            :placeholder="t('placeholderPets')"
            :isHasRemover="true"
            :isError="showPetsDescError"
            :maxSymbols="100"
            @removeTextarea="petsValue = false"
          />
          <p class="pets__example">{{ t("petsExample") }}</p>
        </template>
      </div>
      <hr />
      <div class="step-guests__container" ref="warnings" v-observe-visibility="{
        callback: warningViewed,
        intersection: {
          threshold: 0.8
        }
      }">
        <template v-for="item of warnings" :key="item.name">
          <transition name="fade">
            <template v-if="item.name == 'noPetsDescription'">
              <UIWarning
                v-if="showPetsDescError"
                :isError="item.type === 'error'"
              >
                {{ getWarningText(item.name) }}
              </UIWarning>
            </template>
            <UIWarning
              v-else
              :isError="item.type === 'error'"
            >
              {{ getWarningText(item.name) }}
            </UIWarning>
          </transition>
        </template>
      </div>
      <hr v-if="warnings.length">
    </div>
  </div>
</template>

<script>
import GuestsCounter from "@/components/base/SelectGuestsCopy/GuestsCounter";
import ChildrenSelect from "@/components/base/SelectGuestsCopy/ChildrenSelect";
import { mapGetters } from "vuex";
import UIButton from "@/components/UIKit/UIButton";
import UISwetcher from "@/components/UIKit/UISwetcher";
import UITextarea from "@/components/UIKit/UITextarea";
import UIWarning from "@/components/UIKit/UIWarning";
import { useI18n } from 'vue-i18n';

export default {
  name: "GuestOption",
  components: {
    GuestsCounter,
    ChildrenSelect,
    UIButton,
    UISwetcher,
    UITextarea,
    UIWarning,
  },
  emits: [
    "changeChildren",
    "changeAdults",
    "changePetsValue",
    "changePetsDescription",
  ],
  props: {
    guests: {
      type: Object,
    },
    showPetsDescError: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapGetters("detail", ["getObjectData", "maxGuests", "getGuestRules"]),
    classesForPetsPopover(){
      return {
        restriction: this.getGuestRules.pets !== 1,
        "hint-popup": this.getPetsPopoverText,
        "hint-popup__bottom": this.getPetsPopoverText && this.petsValue,
        "hint-popup__top": this.getPetsPopoverText && !this.petsValue,
      }
    },
    adults: {
      get() {
        return this.guests?.adults || 1;
      },
      set(val) {
        this.$emit("changeAdults", val);
      },
    },
    freeBabies() {
      const properties =
        this.getObjectData?.properties?.accommodation_rules?.properties;
      const isFreeBabies = properties?.find(item => item.name === 'free_babies')?.value
      return isFreeBabies === "false" ? false : Boolean(isFreeBabies);
    },
    // children
    children: {
      get() {
        return this.guests?.children || [];
      },
      set(val) {
        this.$emit("changeChildren", val);
      },
    },
    getChildRestrictionValue() {
      switch (this.getGuestRules.isChildren) {
        case 2:
          return -2;
        case 0:
          return 0;
        case 1:
          if (this.getGuestRules.childrenAge > 0) {
            return this.getGuestRules.childrenAge;
          }
          return -1;
        default:
          return -1;
      }
    },
    getChildPopoverText() {
      if (this.getChildRestrictionValue === 2 && this.freeBabies) {
        return "";
      } else if (this.getChildRestrictionValue > 2 && this.freeBabies) {
        return this.t("youngerСhildrenFreeBabies", {
          age: this.t("youngerСhildrenAges", this.getChildRestrictionValue),
        });
      } else if (this.getChildRestrictionValue === -2) {
        return this.t("warning.requestChildren");
      } else if (this.getChildRestrictionValue === 0) {
        return this.t("noChildren");
      } else if (this.getChildRestrictionValue > 0) {
        return this.t("youngerСhildren", {
          age: this.t("youngerСhildrenAges", this.getChildRestrictionValue),
        });
      }
      return "";
    },

    // pets
    petsValue: {
      get() {
        return this.guests?.pets?.value || false;
      },
      set(val) {
        this.$emit("changePetsValue", val);
      },
    },
    petsDescription: {
      get() {
        return this.guests?.pets?.description || "";
      },
      set(val) {
        this.$emit("changePetsDescription", val);
      },
    },
    getPetsRestrictionText() {
      switch (this.getGuestRules.pets) {
        case 0:
          return this.t("petsRestriction.0");
        case 1:
          return this.t("petsRestriction.1");
        case 2:
          return this.t("petsRestriction.2");
        default:
          return "";
      }
    },
    getPetsPopoverText() {
      switch (this.getGuestRules.pets) {
        case 0:
          return this.t("noPets");
        case 2:
          return this.t("warning.requestPets");
        default:
          return "";
      }
    },

    warnings() {
      let result = [];


      //  нельзя с детьми
      if (this.getGuestRules.isChildren === 0 && this.children.length > 0) {
        result.push({
          type: "error",
          name: "noChildren",
        });
      } else {
        result.filter((item) => item.name !== "noChildren");
      }
      // ошибка возраста детей
      const childrenSelectedAge = this.children.filter(age => age !== null);
      const childrenAgeError =
        this.getGuestRules.isChildren === 1 &&
        this.children.length > 0 &&
        this.getGuestRules.childrenAge > Math.min(...childrenSelectedAge);
      const freeBabiesWarning = this.getGuestRules.freeBabies &&
        this.children.length > 0 &&
        childrenSelectedAge.some(age => age < 2);
      if (childrenAgeError && !freeBabiesWarning) {
        result.push({
          type: "error",
          name: "minAge",
        });
      } else {
        result.filter((item) => item.name !== "minAge");
      }
      // Разрешены дети до года, но без доп спального места
      if ((!childrenAgeError || (childrenAgeError && this.getGuestRules.childrenAge !== 2)) && freeBabiesWarning) {
        result.push({
          type: "warning",
          name: "freeBabies",
        });
      } else {
        result.filter((item) => item.name !== "freeBabies");
      }
      // ошибка возвраста детей, но можно детей до года без доп спального места
      if (childrenAgeError && this.getGuestRules.childrenAge > 2 && freeBabiesWarning) {
        result.push({
          type: "warning",
          name: "freeBabiesOnly",
        });
      } else {
        result.filter((item) => item.name !== "freeBabiesOnly");
      }
      // Дети любого возраста, но детей до года без доп спального места
      if (childrenAgeError && this.getGuestRules.childrenAge === 2 && freeBabiesWarning) {
        result.push({
          type: "warning",
          name: "freeBabiesAllChildren",
        });
      } else {
        result.filter((item) => item.name !== "freeBabiesAllChildren");
      }
      // нельзя с животными
      if (this.petsValue && this.getGuestRules.pets === 0) {
        result.push({
          type: "error",
          name: "noPets",
        });
      } else {
        result.filter((item) => item.name !== "noPets");
      }
      // не заполнено описание животных
      if (this.petsValue && this.petsDescription.trim().length === 0) {
        result.push({
          type: "error",
          name: "noPetsDescription",
        });
      } else {
        result.filter((item) => item.name !== "noPetsDescription");
      }
      // превышен общий лимит гостей
      if (
        (!this.freeBabies && (this.adults + this.children.length > this.maxGuests)) ||
        (this.freeBabies && (this.adults + this.children.filter(child => child >= 2).length > this.maxGuests))
      ) {
        result.push({
          type: "warning",
          name: "maxLimit",
        });
      } else {
        result.filter((item) => item.name !== "maxLimit");
      }
      // дети по зпросу
      if (this.children.length > 0 && this.getGuestRules.isChildren === 2) {
        result.push({
          type: "warning",
          name: "requestChildren",
        });
      } else {
        result.filter((item) => item.name !== "requestChildren");
      }
      // животные по запросу
      if (this.petsValue && this.getGuestRules.pets === 2) {
        result.push({
          type: "warning",
          name: "requestPets",
        });
      } else {
        result.filter((item) => item.name !== "requestPets");
      }

      return result;
    },
  },
  methods: {
    warningViewed(isVisible){
      if (!this.warnings.length){
        this.$emit("setViewedWarnings", true);
      } else {
        this.$emit("setViewedWarnings", isVisible);
      }
    },
    addChild(remove = false) {
      if (this.children.length >= 20 && !remove) {
        return false;
      }
      if(remove) {
        let target = [...this.children];
        if (target.length > 1) {
          target.pop();
        } else {
          target = [];
        }
        this.children = target;
        return;
      }
      this.children = [...this.children, null];
    },
    changeChildAge(value, index) {
      this.children[index] = value;
    },
    removeChild(index) {
      this.children = this.children.filter((elem, i) => i !== index);
    },

    getWarningText(val) {
      switch (val) {
        case "maxLimit":
          return this.t("warning.maxLimit", {
            cnt: this.t("guests", this.maxGuests),
          });
        case "noChildren":
          return this.t("warning.noChildren");
        case "requestChildren":
          return this.t("warning.requestChildren");
        case "minAge":
          return this.t("warning.minAge", {
            age: this.t("ages", this.getGuestRules.childrenAge),
          });
        case "noPets":
          return this.t("warning.noPets");
        case "requestPets":
          return this.t("warning.requestPets");
        case "noPetsDescription":
          return this.t("warning.noPetsDescription");
        case "freeBabies":
          return this.t("warning.freeBabies");
        case "freeBabiesOnly":
          return this.t("warning.freeBabiesOnly", {
            age: this.t("ages", this.getGuestRules.childrenAge),
          });
        case "freeBabiesAllChildren":
          return this.t("warning.freeBabiesAllChildren");
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.step-guests :deep() {
  .hint-popup::before {
    left: 0;
  }
  .hint-popup__top::before {
    transform: translate(0, -100%);
  }
  .hint-popup__bottom::before {
    transform: translate(0, 100%) !important;
  }
}
:deep(){
  @media (max-width: 768px) {
    .hint-popup::after{
      display: none !important;
    }
    .hint-popup::before{
      display: none !important;
    }
  }
}
.step-guests {
  color: var(--default-fonts-color);

  &__container {
    padding: 0 20px;
  }
  &__header{
    margin-bottom: 18px;
  }
  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
  &__suptitle {
    margin: 0;
    line-height: 20px;
    color: var(--grey-g5);
  }
  &__body{
    max-height: calc(100vh - 80px - 162px);
    overflow-y: auto;
  }
  &__item {
    margin-top: 20px;
  }
}
.add-pets {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    strong {
      display: block;
      font-weight: 500;
      line-height: 20px;
    }
    span {
      font-size: 12px;
      line-height: 16px;
    }
    .restriction {
      border-bottom: 1px dashed #5a5d63;
    }
  }

  &__button {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    border-radius: 50%;

    span {
      font-size: 18px;
    }
  }
}
.pets__example {
  margin-top: 6px;
  font-size: 12px;
  line-height: 16px;
  color: var(--grey-g5);
}
hr{
  &:first-child{
    margin-top: 0;
  }
  &:last-child{
    margin-bottom: 0;
  }
}
::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  width: 4px;
  background: transparent;
  border-radius: 3px;
  box-shadow: inset 0 0 0 2px #d8d8d8;
  border: 3px solid transparent;

  &:hover {
    box-shadow: inset 0 0 0 2px #999999;
  }
}
</style>

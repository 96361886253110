<i18n lang="json">
{
  "ru": {
      "title": "Изменение состава гостей",
      "cansel": "Отменить",
      "save":"Сохранить",
      "continue": "Продолжить"
  },
  "en": {
      "title": "Changing the composition of guests",
      "cansel": "Cansel",
      "save": "Save",
      "continue": "Continue"
  }
}
</i18n>
<template>
  <UIModalLight v-model="isModal" class="modal-guests">
    <div class="modal-container">
      <GuestOption
        :guests="getGuestsData"
        :showPetsDescError="showPetsDescError"
        ref="guests"
        @changeChildren="changeChildren"
        @changeAdults="changeAdults"
        @changePetsValue="changePetsValue"
        @changePetsDescription="changePetsDescription"
        @setViewedWarnings="isViewedWarnings = $event"
      />
      <div class="btn-wrapp">
        <UIButton v-if="isViewedWarnings" @click="save" buttonTheme="dark">{{ t("save") }}</UIButton>
        <UIButton v-else @click="next" buttonTheme="dark">{{ t("continue") }}</UIButton>
      </div>
    </div>
  </UIModalLight>
</template>
<script>
import UIModalLight from "@/components/UIKit/UIModalLight";
import UIButton from "@/components/UIKit/UIButton";
import GuestOption from "./GuestOption/index.vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import axios from "axios";
import { formatWithTime, formatSafari } from "@/utils/format";
import { useI18n } from 'vue-i18n';

export default {
  name: "ModalGuests",
  components: {
    UIModalLight,
    UIButton,
    GuestOption,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      adults: 1,
      children: [],
      petsValue: false,
      petsDescription: "",
      cancelToken: null,
      showPetsDescError: false,
      isViewedWarnings: false
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState("search", ["guests"]),
    ...mapGetters("detail", [
      "getObjectData",
      "getObjectPrice",
      "getGuestRules",
    ]),
    ...mapGetters("booking", ["currencyCalc"]),
    ...mapGetters("detailBooking", ["petsObject"]),
    ...mapGetters("search", ["guestsCount"]),
    isModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:modelValue", val);
      },
    },
    getGuestsData(){
      return {
        adults: this.adults,
        children: this.children,
        pets: {
          value: this.petsValue,
          description: this.petsDescription,
        }
      }
    }
  },
  created(){
    this.adults = this.guests?.adults || 1;
    this.children = this.guests?.childrens ? [...this.guests.childrens.map(age => parseInt(age))] : [];
    this.petsValue = this.guests?.pets?.value || false;
    this.petsDescription = this.guests?.pets?.description || "";

    if (this.petsValue && this.petsDescription === "") {
      this.showPetsDescError = true;
    }
  },
  methods: {
    ...mapMutations("detailBooking", [
      "setIsPets",
      "setPetsWarning",
      "setPets",
      "setBtnDisabled",
      "setOpenDetailGuestsModal"
    ]),
    ...mapMutations("search", ["setChildrenWithoutAgeError"]),
    ...mapActions("detail", ["setObjectPrice"]),
    ...mapActions("search", ["updateAdults", "updateChildrens", "updatePets"]),
    freeBabies() {
      const properties =
        this.getObjectData?.properties?.accommodation_rules?.properties;
      return !!properties?.find(item => item.name === 'free_babies')?.value;
    },
    getInfoPrice() {
      if (this.cancelToken) {
        this.cancelToken.cancel();
      }

      this.cancelToken = axios.CancelToken.source();
      this.setObjectPrice({
        guests: this.guestsCount,
        date_begin: formatWithTime(
          new Date(formatSafari(this.getObjectPrice.date_begin))
        ),
        date_end: formatWithTime(
          new Date(formatSafari(this.getObjectPrice.date_end))
        ),
        objects: Number(this.getObjectData.id),
        currency_id: this.currencyCalc.id,
        no_time: 1,
        cancelToken: this.cancelToken,
      });
    },
    changeChildren(val) {
      this.children = val;
    },
    changeAdults(val) {
      this.adults = val;
    },
    changePetsValue(val) {
      this.petsValue = val;
    },
    changePetsDescription(val) {
      if (val.length){
        this.showPetsDescError = false;
      }
      this.petsDescription = val;
    },
    save(){
      if (this.petsValue && this.petsDescription === "" || this.children.includes(null)) {
        this.showErrorAction();
        return false;
      }

      this.updateAdults(this.adults);
      this.updateChildrens(this.children.filter((x) => x !== null));

      this.setIsPets(this.petsValue);
      this.setPets(this.petsDescription);
      this.updatePets({
        value: this.petsValue,
        description: this.petsDescription,
      });
      this.setOpenDetailGuestsModal(false)



      this.getInfoPrice()

      this.setPetsWarning(
        this.petsValue && this.getGuestRules.pets === 2 ? true : false
      );

      this.isModal = false;
    },
    next(){
      const elem = this.$refs?.guests?.$refs?.warnings;
      elem && elem.scrollIntoView({ behavior: "smooth", block: "end" });
      if (this.petsValue && this.petsDescription === "" || this.children.includes(null)) {
        this.showErrorAction();
      }
    },
    showErrorAction(){
      if (this.petsValue && this.petsDescription === "") {
        this.showPetsDescError = true;
      }
      if (this.children.includes(null)) {
        this.setChildrenWithoutAgeError(true);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.modal-guests {
  .modal-container {
    padding: 20px 0;
    width: 360px;
    max-height: 100%;
  }
  .btn-wrapp {
    margin-top: 15px;
    padding: 0 20px;
    display: flex;
    grid-gap: 16px;
  }
}
</style>
<style lang="scss">
.modal-guests{
  .hint-popup__bottom::before{
    padding: 10px 12px;
    background-color: #484848 ;
    border-radius: 6px;
    left: 0;
    bottom: -9px;
    font-size: 12px;
    font-weight: 500;
    max-width: 230px;
    transform: translate( 0%, 100%);
  }
  .hint-popup__top::before{
    padding: 10px 12px;
    background-color: #484848 ;
    border-radius: 6px;
    left: 0;
    font-size: 12px;
    font-weight: 500;
    max-width: 230px;
    transform: translate(0%, -100%);
  }
  .hint-popup::after{
    border-color: #484848 transparent transparent transparent;
  }
}
</style>

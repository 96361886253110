const config = {
  root: null,
  rootMargin: "5px",
  threshold: 0.1,
};

//с этим апдейтом гарантируем только 1 обзёрвер на узел
const observedElements = new WeakMap();

export function intersectObserver(
  action = null,
  selector = null,
  { root, rootMargin, threshold } = config
) {
  let entriesMap = [];
  let lastEntry = null;

  const callback = (entries) => {
    entries.forEach(async (entry) => {
      if (
        entry.isIntersecting &&
        selector &&
        action &&
        action instanceof Function
      ) {
        entriesMap = [entry];
        action(entry);
      }
    });
  };

  const observer = new IntersectionObserver(callback, {
    root,
    rootMargin,
    threshold,
  });

  const targets = document.querySelectorAll(selector);

  const initObserve = (observerEntity, nodeList = []) => {
    nodeList.forEach((node) => {
      if (!observedElements.has(node)) {
        observerEntity.observe(node);
        observedElements.set(node, observerEntity);
      }
    });
  };

  return { observer, targets, initObserve, entriesMap };
}

<i18n>
{
  "ru": {
    "per_day": "за сутки",
    "nights": "менее суток | за сутки | за {n} суток | за {n} суток",
    "economy": "Экономия",
    "discount": "Скидка",
    "bonuses": "Применены бонусы",
    "cashback_after_booking": "Кэшбэк после бронирования",
    "detail_calc": "Показать подробный расчёт",
    "total": "Итого",
    "precost": "Предоплата",
    "upon_arrival": "Оплата при заселении",
    "uponCheckIn": "Расчётная цена указана в валюте: {n}. Обратите внимание, что в соответствии с законодательством страны пребывания, вам может потребоваться произвести расчет в национальной валюте по курсу на момент заселения.",
    "centerCourse": "Курс ЦБ РФ на {n}",
    "modal": {
      "title": "Подробный расчёт"
    }
  },
  "en": {
    "per_day": "per day",
    "nights": "less night | in {n} night | in {n} nights | in {n} nights",
    "economy": "Economy",
    "discount": "Discount",
    "bonuses": "Bonuses applied",
    "cashback_after_booking": "Cashback after booking",
    "detail_calc": "Show detailed calculation",
    "total": "Total",
    "precost": "Prepayment",
    "upon_arrival": "Payment upon arrival",
    "uponCheckIn": "The estimated price is shown in currency: {n}. Please pay attention that in accordance with the legislation of the host country, you may be required to make a payment in local currency at the rate at the time of check-in.",
    "centerCourse": "The exchange rate of the Central Bank of the RF {n}",
    "modal": {
      "title": "Detailed calculation"
    }
  }
}
</i18n>

<template>
  <div class="price-object">
    <div
      v-if="isCheck && getPrices.max_price"
      class="price-detail"
      :class="{
        'one-day': getPrices.nights <= 1,
        'not-sale': getPrices.nights > 1 && isNotDiscount
      }"
    >
      <div class="price-detail__price">
        <span class="price-detail__price-total">{{ totalPrice }}</span>
        <span v-if="getPrices.max_price !== getPrices.total_price" class="price-detail__price-base">{{ maxPrice }}</span>
      </div>
      <div class="price-detail__nights">{{ t('nights', getPrices.nights) }}</div>
      <template v-if="getPrices.nights > 1">
        <div class="price-detail__day-price">
          {{ perDayPrice }}
          <template v-if="isNotDiscount">{{ t('per_day') }}</template>
        </div>
        <div v-if="isDiscount" class="price-detail__day-price-title">{{ t('per_day') }}</div>
      </template>
    </div>
    <div class="price-sale" v-if="getSalePrice > 0 && getPrices.discounts.all_cost > 0">
      <span class="price-sale__title">{{ t('economy') }}</span>
      <span class="price-sale__cost">-{{ formatPriceCeil(bonuses + getPrices.discounts.all_cost, currencyCalc.title, locale) }}</span>
    </div>
    <div class="price-sale" v-else-if="getSalePrice <= 0 && getPrices.discounts.all_cost > 0">
      <span class="price-sale__title">{{ t('discount') }}</span>
      <span class="price-sale__cost">-{{ formatPriceCeil(getPrices.discounts.all_cost, currencyCalc.title, locale) }}</span>
    </div>
    <div class="price-sale" v-else-if="getSalePrice > 0">
      <span class="price-sale__title">{{ t('bonuses') }}</span>
      <span class="price-sale__cost price-sale__cost--bonuses">-{{ bonuses }}</span>
    </div>
    <div class="price-cashback">
      <span class="price-cashback__title">{{ t('cashback_after_booking') }}</span>
      <span class="price-cashback__cost price-cashback__cost--bonuses">+{{ accruedBonus }}</span>
    </div>
    <div class="detail-calc" @click.prevent="openCalcPrice">{{ t('detail_calc') }}</div>
  </div>
  <SimpleModal
    v-model="isShowModal"
    :bangInMobileVersion="true"
    class="calc-price"
  >
    <div class="calc-price__wrap">
      <div class="calc-price__title">{{ t('modal.title') }}</div>
      <div v-if="priceList.length" class="calc-price__list">
        <template v-for="item in priceList" :key="item.type">
          <PriceItem
            v-if="item.cost"
            :price-item="item"
            :currency-item="currencyCalc"
          />
        </template>
      </div>
      <hr class="line">
      <div class="price-total">
        <div class="price-total__title">{{ t('total') }}</div>
        <div class="price-total__wrap">
          <span v-if="getPrices.max_price !== getPrices.total_price" class="price-total__price">{{ maxPrice }}</span>
          <span class="price-total__sale">{{ totalPrice }}</span>
        </div>
      </div>
      <div class="calc-price__payment calc-price__payment--precost">
        <span class="calc-price__payment-title">{{ t('precost') }}</span>
        <span class="calc-price__payment-price">{{ precost }}</span>
      </div>
      <div class="calc-price__payment calc-price__payment--upon-arrival">
        <span class="calc-price__payment-title">{{ t('upon_arrival') }}</span>
        <span class="calc-price__payment-price">{{ isYourCurrency ? check_in_cost : currencyPrice }}</span>
        <VDropdown
          v-if="!isYourCurrency"
          class="bonus__popover"
          distance="5"
          placement="top"
          :triggers="['click']"
          popper-class="v-popper__popper--max-width-300 calc-price-item"
        >
          <span class="calc-price__payment-ico-question"></span>
          <template #popper>
            <div class="tooltip-text">
              <p
                class="text-left"
                v-html="t('uponCheckIn', { n: lowerCurrrencyTitle })"
              ></p>
              <div v-close-popper class="tooltip-close"></div>
            </div>
          </template>
        </VDropdown>
      </div>
      <div
        v-if="!isYourCurrency"
        class="calc-price__payment-arrival"
      >
          <span class="calc-price__payment-arrival-text">{{ t("centerCourse", { n: currentDate }) }}</span>
          <span class="calc-price__payment-arrival-price">~{{ check_in_cost }}</span>
      </div>
    </div>
  </SimpleModal>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { usePricesObject, useBonuses, useCurrencyCalc } from "@/composables/price";
import { formatPriceCeil, formatPriceCeilForCurrency, formatPriceFloorForCurrency } from "@/utils/format";

import { SimpleModal } from "@sutochno/modal";
import PriceItem from "./PriceItemMobile.vue";

const store = useStore();
const { t, locale } = useI18n();

const props = defineProps({
  isCheck: {
    required: true,
  }
});

const currencyCalc = useCurrencyCalc();
const getSalePrice = useBonuses();
const getPrices = usePricesObject();

const getObjectPrice = computed(() => store.getters["detail/getObjectPrice"]);
const isDiscount = computed(() => Boolean(getSalePrice.value > 0 || getPrices.value.discounts.all_cost > 0));
const isNotDiscount = computed(() => !isDiscount.value);
const maxPrice = computed(() => formatPriceCeilForCurrency(getPrices.value.max_price, getPrices.value.currency, locale.value));
const totalPrice = computed(() => formatPriceCeilForCurrency(getPrices.value.total_price, getPrices.value.currency, locale.value));
const precost = computed(() => formatPriceCeilForCurrency(getPrices.value.new_pre_cost, getPrices.value.currency, locale.value));
const perDayPrice = computed(() => formatPriceCeilForCurrency(getPrices.value.per_day_price, getPrices.value.currency, locale.value));
const accruedBonus = computed(() => Number(getPrices.value.accrued_bonus.toFixed(2)));
const bonuses = computed(() => Math.floor(getSalePrice.value));

const priceList = computed(() => {
  const result = [];
  result.push({
    type: 'one_day_price',
    base: 0,
    cost: getPrices.value.one_day_price,
    nights: getPrices.value.nights,
  });

  if (getPrices.value.surcharge.list.dop_persons.cost) {
    result.push(getPrices.value.surcharge.list.dop_persons);
  }

  if (getPrices.value.surcharge.list.cleaning.cost) {
    result.push(getPrices.value.surcharge.list.cleaning);
  }

  const { object_hot } = getObjectPrice.value;
  const { is_hot } = store.getters['detail/getObjectData'];
  if (getPrices.value.discounts.list.sale.cost && is_hot && object_hot?.sale) {
    result.push(getPrices.value.discounts.list.sale);
  }

  if (getPrices.value.discounts.list.interval.cost) {
    result.push(getPrices.value.discounts.list.interval);
  }

  if (bonuses.value) {
    result.push({
      type: 'bonuses',
      base: 0,
      cost: bonuses.value,
      nights: getPrices.value.nights,
    });
  }

  return result;
});

const isShowModal = ref(false);
const openCalcPrice = () => isShowModal.value = true;

// расчет валюты
const rate = ref(null);
const getObjectOriginalCurrency = computed(() => store.getters['detail/getObjectOriginalCurrency']);
const check_in_cost = computed(() => formatPriceFloorForCurrency(getPrices.value.check_in_cost, getPrices.value.currency, locale.value));
const isYourCurrency = computed(() => getObjectOriginalCurrency.value?.id === getObjectPrice.value?.currency?.id);
const lowerCurrrencyTitle = computed(() => getObjectOriginalCurrency.value.title[0].toLowerCase() + getObjectOriginalCurrency.value.title.slice(1));
const currentDate = (() => {
  const now = new Date();
  const day = now.toLocaleString("ru-RU", { day: "numeric" });
  const month = now.toLocaleString("ru-RU", { month: "short" }).replace(".", "");
  const year = now.toLocaleString("ru-RU", { year: "numeric" });
  return `${day} ${month} ${year}`;
})();

const loadCurrencyList = ref(true);
const currentCurrencyPrice = ref(null);
const currencyPrice = computed(() => {
  if(!loadCurrencyList.value) {
    return currentCurrencyPrice.value;
  }
  const currency = formatPriceCeil(getPrices.value.check_in_cost / rate.value, getObjectOriginalCurrency.value.code, locale.value)
  currentCurrencyPrice.value = currency;
  return currency;
});

const setRates = () => {
  loadCurrencyList.value = false;
  if (getObjectOriginalCurrency.value?.id !== getObjectPrice.value?.currency?.id) {
    store.dispatch('detail/setRates', getObjectPrice.value?.currency?.id)
      .then((response) => {
        rate.value = response[getObjectOriginalCurrency.value.id];
      })
      .finally(() => {
        loadCurrencyList.value = true;
      });
  }
}

watch(() => getPrices.value.price, setRates);
onMounted(setRates);

</script>

<style lang="scss" scoped>
$color-gray-g1: #f2f2f2;
$color-gray-g2: #f1f3fb;
$color-gray-g5: #5a5d63;
$color-gray-g6: #252525;
$color-light: #1c1c1c;

.price-object {
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    margin: 20px 0 14px;
    background-color: $color-gray-g1;
  }
}
.price-detail {
  display: grid;
  grid-template-columns: 1.2fr .8fr;
  grid-gap: 1px 10px;
  grid-template-areas: 
    "total-price day-price"
    "nights day-price-title";
  margin-bottom: 20px;

  &.one-day {
    grid-template-areas: "total-price nights"
  }

  &.not-sale {
    grid-template-areas: 
      "total-price nights"
      "day-price day-price";
  }

  &__price {
    grid-area: total-price;
    display: flex;
    align-items: center;
    &-total {
      margin-right: 10px;
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      color: $color-light;
    }
    &-base {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $color-gray-g5;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        width: calc(100% + 2px);
        height: 1px;
        margin-left: -1px;
        background-color: $color-gray-g5;
        transform: translateY(calc(-50% + 1px));
        position: absolute;
        top: 50%;
        left: 0;
      }
    }
  }

  &__day-price {
    grid-area: day-price;
  }
  &__nights {
    .not-sale &,
    .one-day & {
      text-align: right;
    }
    grid-area: nights;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-light;
  }
  &__day-price-title {
    grid-area: day-price-title;
  }

  &__day-price,
  &__day-price-title {
    .not-sale & {
      text-align: left;
    }
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: $color-gray-g5;
  }
}

.price-sale,
.price-cashback {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 8px 6px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2px;
  &__cost--bonuses {
    display: flex;
    align-items: center;
    &::after {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: 4px;
      background-image: url(~/assets/img/icon/cashback-currency--red.svg);
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  &__title,
  &__cost {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.2px;
  }
}
.price-cashback__cost--bonuses::after {
  background-image: url(~/assets/img/icon/cashback-currency.svg);
}
.price-sale {
  color: #EE204D;
  background-color: #fff3f2;
}
.price-cashback {
  margin-top: 8px;
  background-color: $color-gray-g2;
}

.detail-calc {
  margin-top: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-align: center;
  color: $color-light;
  cursor: pointer;
}
.price-total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.01em;
  }
  &__wrap {
    display: flex;
    align-items: center;
  }
  &__price {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: $color-gray-g5;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $color-gray-g5;
      z-index: 1;
      transform: rotate(-10deg);
      position: absolute;
      left: 0;
      top: 50%;
    }
  }
  &__sale {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    color: $color-light;
  }
}
.calc-price {
  &__wrap {
    width: 100vw;
    padding: 16px 16px 20px 16px;
  }
  &__title {
    margin-bottom: 14px;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.01em;
  }
  .line {
    border-color: #E6E6E6;
    margin: 20px 0;
  }
  &__payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    background-color: $color-gray-g2;
    &--precost {
      margin-bottom: 10px;
    }
    &-ico-question {
      display: block;
      width: 18px;
      height: 18px;
      margin-left: 4px;
      background-image: url(~/assets/img/icon/help-grey.svg);
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
    &-title {
      flex: 1;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: $color-gray-g5;
    }
    &-price {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: right;
      color: $color-gray-g6;
    }
  }
  &__payment-arrival {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    padding: 0 10px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    &-text {
      font-weight: 400;
      color: $color-gray-g5;
    }
    &-price {
      font-weight: 500;
      text-align: right;
      color: $color-gray-g6;
    }
  }
}
</style>

<i18n lang="json">
{
	"ru": {
    "rooms": "0| {n} номер | {n} номера | {n} номеров",
    "year": "{year} г постройки",
    "comfort": "Удобства и услуги",
    "paidServices": "Стоимость услуг",
    "smoking": "Курение",
    "playground": "Игровая площадка",
    "spa": "Спа",
    "restaurant": "Ресторан",
    "room_service": "Доставка в номер",
    "all_time": "Круглосуточная стойка",
    "gum": "Фитнес/спортзал",
    "tennis": "Теннисный корт",
    "bar": "Бар",
    "sauna": "Сауна",
    "garden": "Сад",
    "terrace": "Терраса",
    "aquapark": "Аквапарк",
    "pool_out": "Бассейн (открытый)",
    "pool_in": "Бассейн (крытый)",
    "jacuzzi": "Джакузи общего пользования",
    "beach": "Собственный пляж",
    "football": "Футбольное поле",
    "golf": "Гольф",
    "lift": "Лифт",
    "payment": "Оплата",
    "transfer": "Трансфер",
    "transferTitle": "Возможно предоставление трансфера",
    "p_free": "бесплатная парковка",
    "i_free": "бесплатный интернет",
    "ip_common": "в местах общего пользования",
    "ip_few_rooms": "в некоторых номерах",
    "ip_all_rooms": "во всех номерах",
    "ip_all_place": "на всей территории",
    "s_all": "курение разрешено везде",
    "s_no": "курение запрещено",
    "s_only": "курение в специально отведенных местах",
    "pm_cash": "к оплате принимаются только наличные",
    "pm_card": "к оплате принимаются кредитные карты",
    "pm_cash_card": "к оплате принимаются наличные и карты",
    "pm_all": "принимается любой способ оплаты",
    "li_free": "бесплатное постельное белье",
    "li_in": "постельное бельё входит в стоимость проживания",
    "cl_free": "бесплатная уборка",
    "cl_in": "уборка входит в стоимость проживания",
    "more": "Подробнее",
    "breakfast": "Завтрак",
    "dinner": "Обед",
    "supper": "Ужин",
    "price_0": "входит в стоимость проживания",
    "price_1": "оплачивается отдельно",
    "nofood": "не предоставляется",
    "sweden": "шведский стол",
    "menu": "по меню",
    "complex": "комплексный",
    "food": "Питание",
    "all_inclusive": "всё включено",
    "btnShow": "показать ещё",
    "btnHide": "скрыть",
    "parking": {
      "parking": {
        "1": "оплачивается отдельно",
        "2": "без оплаты",
        "3": "нет парковки",
        "default": "есть бесплатная парковка"
      },
      "is_p_covered": {
        "0": "открытая",
        "1": "крытая"
      },
      "is_p_private": {
        "0": "общественная",
        "1": "частная"
      },
      "is_p_fenced": {
        "0": "не огорожена",
        "1": "огорожена"
      },
      "is_p_video_surveilla": {
        "0": "нет видеонаблюдения",
        "1": "есть видеонаблюдение"
      },
      "is_p_security": {
        "0": "нет охраны",
        "1": "есть охрана"
      },
      "is_p_on_site": {
        "0": "за территорией объекта {distance}",
        "1": "на территории объекта"
      },
      "distance_to_p": {
        "5": "до 5 мин пешком",
        "10": "до 10 мин пешком",
        "15": "до 15 мин пешком",
        "20": "более 15 мин пешком"
      },
      "parking_cost_1": {
        "1": "стоимость: {cost} в сутки",
        "2": "стоимость: {cost} за период проживания",
        "3": "стоимость: {cost} в час",
        "default": "стоимость: {cost}"
      },
      "is_p_booking": {
        "0": "парковочное место нельзя забронировать заранее",
        "1": "парковочное место можно забронировать заранее"
      }
    }
	},
	"en": {
    "rooms": "0| {n} room | {n} rooms | {n} rooms",
    "year": "{year} year of build",
    "comfort": "Comfort and services",
    "paidServices": "Paid services",
    "smoking": "Smoking",
    "playground": "Playground",
    "spa": "Spa",
    "restaurant": "Restaurant",
    "room_service": "Room delivery",
    "all_time": "24 hour front desk",
    "gum": "Fitness/gym",
    "tennis": "Tennis",
    "bar": "Bar",
    "sauna": "Sauna",
    "garden": "Garden",
    "terrace": "Terrace",
    "aquapark": "Aquapark",
    "pool_out": "Swimming pool (outdoor)",
    "pool_in": "Pool (indoor)",
    "jacuzzi": "Jacuzzi",
    "beach": "Private beach",
    "football": "Football field",
    "golf": "Golf",
    "lift": "Lift",
    "payment": "Payment",
    "transfer": "transfer",
    "transferTitle": "Transfers possible",
    "p_free": "free parking",
    "i_free": "free internet",
    "ip_common": "in common areas",
    "ip_few_rooms": "in some rooms",
    "ip_all_rooms": "in all rooms",
    "ip_all_place": "in all place",
    "s_all": "smoking is allowed everywhere",
    "s_no": "no smoking",
    "s_only": "smoking in designated areas",
    "pm_cash": "only cash accepted",
    "pm_card": "only credit card accepted",
    "pm_cash_card": "cash and cards accepted",
    "pm_all": "any payment method accepted",
    "li_paid": "платно, {price} за комплект",
    "li_in": "bed linen is included in the price",
    "cl_free": "free cleaning",
    "cl_in": "cleaning is included in the price",
    "more": "More",
    "breakfast": "Breakfast",
    "dinner": "Lunch",
    "supper": "Dinner",
    "price_0": "included in the cost of living",
    "price_1": "paid separately",
    "nofood": "not provided",
    "sweden": "buffet",
    "menu": "by menu",
    "complex": "complex",
    "food": "Food",
    "all_inclusive": "all inclusive",
    "btnShow": "show more",
    "btnHide": "hide",
    "parking": {
      "parking": {
        "1": "paid separately",
        "2": "without payment",
        "3": "",
        "default": "there is free parking"
      },
      "is_p_covered": {
        "0": "open",
        "1": "covered"
      },
      "is_p_private": {
        "0": "public",
        "1": "private"
      },
      "is_p_fenced": {
        "0": "not fenced",
        "1": "fenced"
      },
      "is_p_video_surveilla": {
        "0": "no video surveillance",
        "1": "there is video surveillance"
      },
      "is_p_security": {
        "0": "no security",
        "1": "there is security"
      },
      "is_p_on_site": {
        "0": "outside the facility ({distance})",
        "1": "on site"
      },
      "distance_to_p": {
        "5": "up to 5 minutes on foot",
        "10": "up to 10 minutes on foot",
        "15": "up to 15 minutes on foot",
        "20": "more than 15 minutes on foot"
      },
      "parking_cost_1": {
        "1": "cost: {cost} per day",
        "2": "cost: {cost} per stay",
        "3": "cost: {cost} per hour",
        "default": "cost: {cost}"
      },
      "is_p_booking": {
        "0": "parking space cannot be reserved in advance",
        "1": "parking space can be reserved in advance"
      }
    }
	}
}
</i18n>
<template>
  <div class="about">
    <p v-if="title" class="about__title">
      <HotelStars
        v-if="isCert"
        :stars-count="hotelStars"
        :is-cert="isCert"
        :cert-size="14"
        :star-size="9"
        :count-size="16"
      />
      «{{ title }}»
    </p>
    <div v-if="options" class="about__options">
      <div class="options__main">
        <p
          v-if="options.rooms && options.rooms.value"
          class="options__facility"
        >
          {{ t("rooms", options.rooms.value) }}
        </p>
        <p v-if="options.year && options.year.value" class="options__facility">
          {{ t("year", { year: options.year.value }) }}
        </p>
      </div>
    </div>
    <HotelCert
      v-if="isCert"
      :cert-date="starCert?.certDate"
      :cert-number="starCert?.certNum"
    />
    <p
      v-if="description"
      class="about__description"
      :class="{ show: showButton }"
    >
      <span class="about__description-text">{{
        showButton
          ? description
          : `${description.slice(0, 120 * descShowCof)}...`
      }}</span>
      <span class="button button_link" @click="showButton = !showButton">{{
        !showButton ? t("btnShow") : t("btnHide")
      }}</span>
    </p>
    <div
      v-if="isMeal || isAllInclusive"
      id="block-meal"
      class="comfort__list meal"
    >
      <div v-if="isAllInclusive">
        <div class="comfort__item">
          <strong>{{ t("food") }}</strong>
        </div>
        <div class="comfort__item">
          <strong>{{ t("all_inclusive") }}</strong>
        </div>
      </div>
      <template v-else>
        <div v-for="(item, index) in mealTypes" :key="index">
          <div class="comfort__item direction-column">
            <strong>{{ t(item.name) }}</strong>
            <div>{{ t(item.kind) }}</div>
          </div>
          <div class="comfort__item">
            <template v-if="item.kind != 'nofood'">
              <strong v-if="item.price === 0">{{
                t(`price_${item.price}`)
              }}</strong>
              <span v-else-if="item.price === 1">{{
                t(`price_${item.price}`)
              }}</span>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="about__comfort" v-if="transfer">
      <p class="comfort__title">{{ t("transfer") }}</p>
      <div class="comfort__desc">
        <p>{{ t("transferTitle") }}</p>
        <template v-if="isTransferDescSlice">
          <span class="about__description-text">{{ showTransferDesc }}</span>
          <span
            class="button button_link"
            @click="isShowTransferDesc = !isShowTransferDesc"
          >
            {{ t(isShowTransferDesc ? "btnHide" : "btnShow") }}
          </span>
        </template>
        <template v-else-if="transferDesc">{{ transferDesc }}</template>
        <template v-else>По договоренности с хозяином жилья</template>
      </div>
    </div>
    <div class="about__comfort" v-if="paidServices.length">
      <p class="comfort__title">{{ t("paidServices") }}</p>
      <div class="comfort__list">
        <div
          v-for="item in paidServices"
          :key="item.name"
          class="comfort__item"
        >
          {{ item.title.toLowerCase() }} {{ formatNum(item.value) }}
        </div>
      </div>
    </div>
    <div class="about__comfort" v-if="isParking && parking">
      <div class="comfort__title">
        <span>{{ parking.title }}</span>
        <span
          class="b-info-item-label"
          v-if="parking.label"
          :data-label="parking.label"
        ></span>
      </div>
      <div class="comfort__list">
        <div
          v-for="item in parking.properties"
          :key="item.name"
          class="comfort__item"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <!-- Удобства и услуги -->
    <div class="about__comfort">
      <p class="comfort__title">{{ t("comfort") }}</p>
      <div class="comfort__list">
        <div v-for="prop in comfort" :key="prop.name" class="comfort__item">
          <div class="comfort__text">
            <span>
              {{ prop.translate.toLowerCase() || prop.title.toLowerCase() }}
            </span>
          </div>
        </div>
      </div>
      <div class="comfort__button">
        <button class="button button_w-100" @click="comfortView = !comfortView">
          {{ t("more") }}
        </button>
      </div>
    </div>
    <Modal ref="modal" v-model="comfortView" theme="light" :title="t('more')">
      <div class="comfort__modal">
        <ul class="comfort__list">
          <div class="about__comfort">
            <p class="comfort__title">{{ t("comfort") }}</p>
            <div class="comfort__list">
              <div
                v-for="prop in comfort"
                :key="prop.name"
                class="comfort__item"
              >
                <div class="comfort__text">
                  <span>{{ prop.title }}</span>
                </div>
              </div>
              <div
                v-for="prop in propArray"
                :key="prop.name"
                class="comfort__item"
              >
                <div class="comfort__text">
                  <span>{{ prop.translate || prop.title }}</span>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Modal from "@sutochno/modal";
import HotelStars from "@/components/base/Hotel/HotelStars.vue";
import HotelCert from "@/components/base/Hotel/HotelCert.vue";
import { useI18n } from 'vue-i18n';
import { useProcessData } from "@/composables/properties/processData";

export default {
  name: "AboutHotel",
  components: {
    HotelCert,
    HotelStars,
    Modal,
  },

  setup() {
    const { t } = useI18n();
    const { processDataParking } = useProcessData();

    return {
      t,
      processDataParking
    }
  },
  data() {
    return {
      showButton: false,
      isShowTransferDesc: false,
      comfortView: false,
      descShowCof: 1,
      currencyCoefficient: null,
    };
  },
  watch: {
    userCurrencyId() {
      if (
        this.getObjectPrice?.currency?.id &&
        this.getObjectPrice.currency.id != 1
      ) {
        this.setRates(1).then((response) => {
          this.currencyCoefficient = response[this.getObjectPrice.currency.id];
        });
      }
    },
  },
  computed: {
    ...mapGetters("detail", [
      "getHotelData",
      "getObjectPrice",
      "hotelStars",
      "isCert",
      "starCert",
    ]),
    title() {
      const title =
        this.getHotelData?.hotel?.properties?.title?.properties?.find(
          (p) => p.name === "title"
        )?.value;
      return title ? title : false;
    },
    options() {
      const arrOptions = ["stars", "rooms", "year"];
      const options =
        this.getHotelData?.hotel?.properties?.info?.properties.reduce(
          (acc, item) => {
            if (arrOptions.includes(item.name)) {
              acc[item.name] = item;
            }
            return acc;
          },
          {}
        );
      return options;
    },
    description() {
      const description =
        this.getHotelData?.hotel?.properties?.description?.properties?.find(
          (p) => p.name === "desc"
        )?.value;
      return description ? description : false;
    },
    isParking() {
      if (!this.getHotelData?.hotel?.properties) return false;
      const parkingData = this.getHotelData.hotel.properties.parking;
      const parking = parkingData?.properties?.find(
        (prop) => prop.name === "parking"
      );
      return parking?.value && parking.value !== "p_no";
    },
    parking() {
      if (!this.getHotelData?.hotel?.properties) return false;
      const parkingData = JSON.parse(
        JSON.stringify(this.getHotelData.hotel.properties.parking)
      );
      // TODO - костыль, надеюсь временный
      if (
        parkingData.properties.length <= 2 &&
        parkingData.properties.length > 0
      ) {
        const parkingIndex = parkingData.properties.findIndex(
          (prop) => prop.name === "parking"
        );
        // TODO - костыль, надеюсь временный
        const parking_cost_1 = parkingData.properties.find(
          (prop) => prop.name === "parking_cost_1"
        );

        parkingData.properties[parkingIndex].value = "p_free";
        
        if (parking_cost_1) {
          parking_cost_1.value = 0;
        }

        const isZeroParkingPay =
          parking_cost_1 &&
          parking_cost_1.value === 0 &&
          parkingData.properties[parkingIndex].value === "p_paid";

        const isFree =
          parkingIndex !== -1 &&
          (parkingData.properties[parkingIndex].value === "p_free" ||
            isZeroParkingPay);

        // TODO костыль, перекрывает некоторые быги в БД
        if (isZeroParkingPay) {
          parkingData.properties[parkingIndex].value = "p_free";
          parkingData.properties = parkingData.properties.filter(
            (prop) => prop.name === "parking"
          );
        }

        if (
          isFree ||
          (isFree && parking_cost_1 && parking_cost_1.value === 0)
        ) {
          const result = this.processDataParking(
            "parking",
            parkingData,
            this.getObjectPrice?.currency?.code || "RUB",
            true
          );
          result.properties.push({
            name: "free_parking",
            title: this.t("parking.parking.default"),
            value: 2,
          });
          return {
            key: result.key,
            title: parkingData.title,
            label: result.label,
            properties: result.properties.filter(
              (prop) => prop.name !== "p_parking"
            ),
          };
        }
      }
      if (parkingData?.properties?.length) {
        const parking = this.processDataParking(
          "parking",
          parkingData,
          this.getObjectPrice?.currency?.code || "RUB",
          true
        );
        return {
          key: parking.key,
          title: parkingData.title,
          label: parking.label,
          properties: parking.properties.filter(
            (prop) => prop.name !== "p_parking"
          ),
        };
      }
      return false;
    },
    propArray() {
      if (!this.getHotelData?.hotel?.properties) return false;
      const pick = ["internet", "cleaning", "linen", "smoking", "payment"];

      return Object.keys(this.getHotelData.hotel.properties).reduce(
        (acc, key) => ({
          ...acc,
          ...this.getHotelData.hotel.properties[key].properties.reduce(
            (acc, object) => {
              if (pick.includes(object.name) && object.value) {
                if (object.name === "transfer") {
                  if (object.value) {
                    acc[object.name] = {
                      ...object,
                      translate: this.t("transfer"),
                    };
                  }
                } else {
                  const mod = /([^_]*)$/g.exec(object.value)[1];
                  if (
                    (mod != "no" && mod != "paid") ||
                    object.name == "smoking"
                  ) {
                    acc[object.name] = {
                      ...object,
                      translate: this.t(object.value),
                    };
                  }
                }
              }
              return acc;
            },
            {}
          ),
        }),
        {}
      );
    },
    transfer() {
      const services =
        this.getHotelData?.hotel?.properties?.services?.properties;
      if (!services && !Array.isArray(services)) {
        return false;
      }
      const transfer = services.findIndex((v) => v.name === "transfer");
      if (transfer === -1) {
        return false;
      }
      return true;
    },
    isTransferDescSlice() {
      return this.transferDesc.length > 120;
    },
    transferDesc() {
      const result =
        this.getHotelData?.hotel?.properties?.description?.properties?.filter(
          (v) => v.name === "transfer_description"
        );
      return result && result.length ? result[0].value : "";
    },
    showTransferDesc() {
      return this.isShowTransferDesc
        ? this.transferDesc
        : `${this.transferDesc.slice(0, 120)}...`;
    },
    paidServices() {
      const properties = this.getHotelData?.hotel?.properties;
      if (!properties?.payed) return false;
      let payedArr = properties.payed.properties.filter(
        (it) => typeof it.value === "number"
      );
      const services = [];
      const cleaning = {
        title:
          properties?.payed?.properties.find((el) => el.name === "cleaning")
            ?.title ?? "",
        value:
          payedArr.find((el) => el?.name?.includes("cleaning"))?.value ?? 0,
      };
      if (cleaning.value) {
        services.push(cleaning);
      }

      const linen = {
        title:
          properties?.payed?.properties.find((el) => el.name === "linen")
            ?.title ?? "",
        value: payedArr.find((el) => el?.name?.includes("linen"))?.value ?? 0,
      };
      if (linen.value) {
        services.push(linen);
      }

      if (properties?.internet?.properties) {
        const internet = this.checkingServicesForPayment(
          properties.internet.properties,
          "internet"
        );
        if (internet) {
          services.push(internet);
        }
      }
      return services;
    },
    comfort() {
      if (!this.getHotelData?.hotel?.properties) return false;
      const pick = [
        "room_service",
        "all_time",
        "gum",
        "terrace",
        "football",
        "tennis",
        "golf",
        "playground",
        "spa",
        "restaurant",
        "bar",
        "sauna",
        "garden",
        "terrace",
        "aquapark",
        "pool_out",
        "pool_in",
        "jacuzzi",
        "beach",
        "lift",
      ];
      return Object.keys(this.getHotelData.hotel.properties).reduce(
        (acc, key) => [
          ...acc,
          ...this.getHotelData.hotel.properties[key].properties.reduce(
            (acc, object) => {
              if (pick.includes(object.name) && object.value) {
                if (object.value) {
                  acc.push(
                    Object.assign({}, object, {
                      translate: this.t(object.name),
                    })
                  );
                }
              }
              return acc;
            },
            []
          ),
        ],
        []
      );
    },
    isMeal() {
      if (this.getHotelData?.hotel?.meal) {
        return true;
      } else {
        return false;
      }
    },
    isAllInclusive() {
      if (this.getHotelData?.hotel?.meal?.is_all_inclusive) {
        return true;
      } else {
        return false;
      }
    },
    mealTypes() {
      if (this.getHotelData?.hotel?.meal?.meal_types) {
        return this.getHotelData.hotel.meal.meal_types.filter(
          (item) => item.kind && item.kind != "nofood"
        );
      } else {
        return [];
      }
    },
    userCurrencyId() {
      return this.getObjectPrice?.currency?.id;
    },
  },
  methods: {
    ...mapActions("detail", ["setRates"]),
    checkingServicesForPayment(properties, type) {
      const values = properties.find((v) => v.name === type);
      if (values && values?.value?.includes("paid")) {
        const cost = properties.find((v) => v.name === `${type}_cost_1`);
        return {
          title: values.title,
          value: cost.value > 0 ? cost.value : "paid",
        };
      }
      return false;
    },
    formatNum(item) {
      let price = null;
      if (this.getObjectPrice?.currency?.id != 1 && this.currencyCoefficient) {
        price = item / this.currencyCoefficient;
      } else {
        price = item;
      }
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: this.getObjectPrice?.currency?.code || "RUB",
        minimumFractionDigits: 0,
      }).format(price);
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  &__title {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #000;
  }
  &__options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    color: #000;

    .options {
      &__main {
        display: flex;
        align-items: center;
      }
      &__facility {
        font-weight: 800;
        margin-right: 20px;
        line-height: 14px;
        margin-top: 0;
        &:last-child {
          margin-right: 0;
        }

        @media screen and (max-width: 359px) {
          margin-right: 10px;
        }
      }
    }
  }
  &__description {
    position: relative;
    margin-top: 20px;
    font-size: 14px;
    color: #444;
    -webkit-line-clamp: 3;
    // text-overflow: ellipsis;
    // max-height: 51px;
    overflow: hidden;
    // -webkit-box-orient: vertical;
    display: -webkit-box;

    // &.show {
    //   max-height: 100%;
    // }

    &-text {
      margin-right: 6px;
    }

    .description {
      &__button {
        display: inline-block;
        background: transparent;
        padding: 10px 10px 10px 5px;
        margin-top: -10px;
      }
    }
  }
  &__comfort {
    width: 100%;
    margin-top: 20px;
  }
  .comfort {
    &__modal {
      padding: 20px;
    }
    &__title {
      font-size: 14px;
      color: #000;
      font-weight: 800;
    }
    &__desc {
      margin-top: 5px;
    }
    &__text {
      display: inline-block;
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: -10px;
      &.meal {
        margin-top: 10px;
        margin-bottom: 10px;
        > div {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .comfort__item {
            &:nth-child(2) {
              text-transform: lowercase;
              &:first-letter {
                text-transform: lowercase;
              }
            }
          }
        }
      }
    }
    &__item {
      display: inline-flex;
      flex-basis: 50%;
      margin-top: 15px;
      color: #444;
      padding-right: 10px;
      font-size: 14px;
      &:first-letter {
        text-transform: uppercase;
      }
      &.direction-column {
        flex-direction: column;
      }
    }
    &__button {
      margin-top: 15px;
      .button {
        color: #000;
      }
    }
  }
  .button.button_link {
    display: inline-block;
  }
}
.b-info-item-label {
  display: inline-block;
  max-width: 160px;
  margin: 0;
  margin-left: 16px;
  padding: 0px 5px 3px;
  border-radius: 3px;
  background: #f6f6f6;
  &::before {
    content: attr(data-label);
    font-size: 12px;
    font-weight: 400;
  }
}
</style>

<i18n lang="json">
{
  "ru": {
    "choiceDates": "Выберите даты"
  },
  "en": {
    "choiceDates": "Choice dates"
  }
}
</i18n>
<template>
  <div class="availability" :class="view">
    <div class="availability__wrap">
      <div class="availability__block">
        <div
          v-click-outside="clickOutside"
          class="availability__datepicker"
          :class="{ fill: valueDate }"
          @click.self="datePickerToggle()"
        >
          <span @click.self="datePickerToggle()" v-html="valueDate" />
          <DatePickerDesktop
            v-if="view === 'desktop-view'"
            ref="DatePicker"
            v-model="date"
            v-model:dateViews="dateViews"
            :show-header="showHeaderDatePicker"
            :lang="$i18n.locale"
            @visibleCalendar="visibleCalendar = $event"
          />
          <DatePicker
            v-else
            ref="DatePicker"
            v-model="date"
            :show-header="showHeaderDatePicker"
            :lang="$i18n.locale"
          />
        </div>
        <div class="availability__guests">
          <SelectGuests
            :view="'page-search'"
            :is-merge="true"
            @click="$emit('guests')"
            @guestsClose="$emit('guestsClose')"
            v-if="view === 'mobile-view'"
          />
          <SelectGuestsDesktop
            :view="'page-search'"
            :is-merge="true"
            @click="$emit('guests')"
            @guestsClose="$emit('guestsClose')"
            v-else-if="view === 'desktop-view'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import BaseSelect from "@/components/base/BaseSelect";
import _ from "lodash";
import SelectGuests from "@/components/base/SelectGuestsCopy/Mobile";
import SelectGuestsDesktop from "./Guests";
import DatePicker from "@sutochno/datepicker";
import { format } from "@/utils/format.js";
import DatePickerDesktop from "@sutochno/datepickerext";
import { useI18n } from 'vue-i18n';

export default {
  name: "Availability",
  props: {
    view: {
      type: String,
      default: "mobile-view",
    },
    isObjectList: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DatePicker,
    BaseSelect,
    SelectGuests,
    SelectGuestsDesktop,
    DatePickerDesktop,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      showHeaderDatePicker: false,
      date: {
        in: null,
        out: null,
      },
      guests: null,
      dateViews: {
        in: null,
        out: null,
      },
      visibleCalendar: false,
    };
  },
  async created() {
    let countGuests =
      parseInt(this.guestsSearch.adults) + this.guestsSearch.childrens.length;
    if (countGuests > this.maxGuestsObject) {
      this.guests = {
        adults: 1,
        childrens: [],
      };
    } else {
      this.guests = this.guestsSearch;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.date.in = this.checkIn;
      this.date.out = this.checkOut;
      this.dateViews.in = this.checkIn;
      this.dateViews.out = this.checkOut;

      this.getPrices();
    });
  },
  computed: {
    ...mapGetters("detail", [
      "getObjectData",
      "getHotelData",
      "getHotelPrice",
      "getHotelIdObjects",
    ]),
    ...mapState("detail", ["hotelObjectsId"]),
    ...mapState("search", ["checkIn", "checkOut"]),
    ...mapState("search", { guestsSearch: "guests" }),
    ...mapGetters("search", ["maxGuests", "occupied"]),
    ...mapState("user", ["currency"]),
    valueDate() {
      return this.date.in && this.date.out
        ? this.format(this.date)
        : this.t("choiceDates");
    },
    dateCheck() {
      if (this.getObjectData.properties.check_in_out_rules) {
        return this.getObjectData.properties.check_in_out_rules.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      } else {
        return this.getObjectData.properties.additional_payment.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      }
    },
  },
  methods: {
    ...mapActions("detail", ["setHotelPrice"]),
    changeGuests(e) {
      this.guests = e;
    },
    setQuery(value) {
      const query = {
        hash: value.hash,
        query: {
          occupied: value.query.occupied,
          guests_adults: this.guests.adults,
          guests_childrens: this.guests.childrens.join(","),
          id: value.query.id,
          type: value.query.type,
        },
      };
      this.getPrices();
      navigateTo(query);
    },
    getPrices() {
      if (this.date.in || this.date.out) {
        let date_begin = format(this.checkIn ? this.checkIn : this.date.in);
        let date_end = format(this.checkOut ? this.checkOut : this.date.out);

        if (this.view === "desktop-view") {
          date_begin = this.formatDateGetPrices("in")
          date_end = this.formatDateGetPrices("out")
        }

        const currentObjectId = Number(this.$route.params.id);
        const objects = this.isObjectList ? 
          this.hotelObjectsId.filter(item => item !== currentObjectId) : this.hotelObjectsId;

        this.setHotelPrice({
          guests: Number(this.maxGuests),
          date_begin: date_begin,
          date_end: date_end,
          no_time: 1,
          objects: objects,
          // max_rooms: {'577005': 1},
          currency_id: this.currency.id,
        });
      }
    },
    formatDateGetPrices(elem) {
      if (!this.date.in && !this.date.out) return false;
      const format = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, 0);
        const day = date.getDate().toString().padStart(2, 0);
        const hours =
          this.dateCheck[elem] || this.dateCheck[elem] != ""
            ? this.dateCheck[elem].match(/\d{2}/)
            : elem == "in"
            ? "14"
            : "11";
        const minutes = date.getMinutes().toString().padStart(2, 0);
        const seconds = date.getSeconds().toString().padStart(2, 0);
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      };
      return format(this.date[elem]);
    },
    datePickerToggle() {
      this.$refs.DatePicker?.toggle();
    },
    datePickerClose() {
      this.$refs.DatePicker?.close();
    },
    clickOutside() {
      this.$refs.DatePicker?.close();
    },
    format(date) {
      let dIn = date.in
        .toLocaleString(this.$i18n.locale, {
          day: "numeric",
          month: "short",
        })
        .replace(".", "");
      let dOut = date.out
        .toLocaleString(this.$i18n.locale, {
          day: "numeric",
          month: "short",
        })
        .replace(".", "");
      if (
        date.in &&
        date.out &&
        date.in.getFullYear() != date.out.getFullYear()
      ) {
        dIn += " " + date.in.getFullYear().toString().substring(2);
        dOut += " " + date.out.getFullYear().toString().substring(2);
      }
      return dIn + " <span class='arrow'>—</span> " + dOut;
    },
  },
  watch: {
    maxGuests() {
      this.setQuery({ query: this.$route.query });
      this.getPrices();
    },
    date: {
      deep: true,
      handler() {
        if (this.date && this.date.in && this.date.out) {
          this.datePickerClose();
          this.$store.dispatch("search/setCalendarDates", {
            checkIn: this.date.in,
            checkOut: this.date.out,
          });
        } else {
          this.datePickerClose();
          this.$store.dispatch("search/setCalendarDates", {
            checkIn: null,
            checkOut: null,
          });
        }
      },
    },
    visibleCalendar(val) {
      if (!val && this.dateViews.in && !this.dateViews.out) {
        if (this.checkIn && this.checkOut) {
          this.dateViews.in = this.checkIn;
          this.dateViews.out = this.checkOut;
        } else {
          this.dateViews.in = this.date.in;
        }
      }
    },
    occupied() {
      this.getPrices();
    },
    checkIn() {
      this.date.in = this.checkIn;
      this.date.out = this.checkOut;
    },
    checkOut() {
      this.date.in = this.checkIn;
      this.date.out = this.checkOut;
    },
  },
};
</script>

<style lang="scss" scoped>
.availability {
  &__wrap {
    padding: 20px;
    background: #fff;
  }
  &__block {
    display: flex;
  }
  &__datepicker {
    cursor: pointer;
    position: relative;
    padding: 0 15px;
    white-space: nowrap;
    font-size: 14px;
    border-radius: 3px;
    display: flex;
    align-items: center;

    &.fill {
      border: none;
      white-space: nowrap;
      color: #000;
      border: 1px solid #000;
    }
    .sc-datepicker {
      top: 45px;
      left: 0;
      margin-top: 0;
    }
  }
  &__guests {
    margin-left: 10px;
  }
  &.desktop-view {
    .availability__wrap {
      background: transparent;
      padding: 0;
      .availability__guests {
        margin-left: 20px;
        .availability__datepicker {
          cursor: pointer;
        }
        :deep(.select-guests--btn) {
          background: transparent;
          border-color: #000;
          height: 42px;
          min-width: 225px;
          border-radius: 3px;
        }
      }
    }
    .sc-datepickerext-wrapper {
      top: 0;
      margin-top: 50px;
      margin-left: -16px;
    }
  }
}
@media screen and (max-width: 350px) {
  .availability {
    &__datepicker {
      position: static;
      .sc-datepicker {
        top: 65px;
        left: 50%;
        transform: translate(-50%, 0);
        margin-top: 0;
      }
    }
  }
}
</style>

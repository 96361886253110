<i18n lang="json">
{
  "ru": {
    "area": "{n} м²",
    "of": "из",
    "floor": "этаж",
    "maxGuests": "ни одного | 1 гость | {n} гостя | {n} гостей",
    "beds": "нет кроватей | 1 кровать | {n} кровати | {n} кроватей",
    "bedrooms": "нет спален | 1 спальня | {n} спальни | {n} спален",
    "basement": "цокольный этаж",
    "total": "всего",
    "elevator": "есть лифт"
  },
  "en": {
    "area": "m²",
    "maxGuests": "no guests | 1 guest | {n} guests | {n} guests",
    "beds": "no beds | 1 bed | {n} beds | {n} beds",
    "bedrooms": "no bedrooms | 1 bedroom | {n} bedrooms | {n} bedrooms",
    "of": "of",
    "floor": "floor",
    "basement": "ground floor",
    "total": "total",
    "elevator": "there is an elevator"
  }
}
</i18n>

<template>
  <div class="main-features">
    <p v-if="area" class="main-features__feature tmp-font--medium_gray">
      {{ t("area", area) }}
    </p>
    <p v-if="max_guests" class="main-features__feature tmp-font--medium_gray">
      {{ t("maxGuests", max_guests) }}
    </p>
    <p v-if="bedsCount" class="main-features__feature tmp-font--medium_gray">
      {{ t("beds", bedsCount) }}
    </p>
    <p v-if="bedrooms" class="main-features__feature tmp-font--medium_gray">
      {{ t("bedrooms", bedrooms) }}
    </p>
    <p v-if="floors" class="main-features__feature tmp-font--medium_gray">
      {{ floors }}
    </p>
  </div>
</template>

<script setup>
import { toRefs, computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const props = defineProps({
  unpickedFeatures: {
    type: Object,
    required: true,
  },
  elevator: Boolean,
});

const store = useStore();
const { t } = useI18n();

const { area, bedrooms, floor, max_floor, max_guests } = toRefs(
  props.unpickedFeatures
);

const beds = computed(() => store.getters["detail/beds"]);

const bedsCount = computed(() => {
  if (!beds.value.length) {
    return 0;
  }
  return beds.value.reduce(function (result, item) {
    return result + item.value;
  }, 0);
});

const floors = computed(() => {
  let result = ""
  if (floor?.value === 0 && max_floor?.value) {
    result = `${t("basement")} (${t("total")} ${max_floor?.value})`
  }
  if (floor?.value && max_floor?.value) {
    result = `${t("floor")} ${floor.value} ${t("of")} ${max_floor.value}`;
  }
  if (props.elevator) {
    result += result ? ", " + t("elevator") : t("elevator");
  }
  return result ? result + "." : "";
});
</script>

<style lang="scss" scoped>
.main-features {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  overflow-x: auto;
  flex-grow: 1;

  &__feature {
    white-space: nowrap;
    margin-top: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    &:first-child {
      border-radius: 0px;
      padding: 1px;
      background-color: #f6f6f6;
      color: #717171;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>

<i18n lang="json">
{
	"ru": {
    "reviews": "отзывов | {n} отзыв | {n} отзыва | {n} отзывов",
    "allReviews": "Все отзывы",
    "otherReviews": "Посмотреть другие отзывы",
    "titleReviews": "{count} от гостей",
    "titleReviewsOther": "Отзывы с других ресурсов",
    "titleReviewsHotel": "Отзывы на другие категории номеров",
    "noReviews": "В настоящее время пока никто не оставил отзыв на данный объект.",
    "noReviewsButOther": "У этого владельца {count} на другие объекты.",
    "externalRating": "Оценка сформирована по реальным отзывам гостей на зарубежных сервисах, ушедших из России. Мы не публикуем отзывы целиком, т.к. гости не давали на это явного согласия. Возможно, отзывы появятся позже.",
    "otherReviewsForeign": "Остальные отзывы были размещены на зарубежных сервисах, ушедших из России. Мы не публикуем их целиком, т.к. гости не давали явного согласия. Возможно, эти отзывы появятся позже."
	},
	"en": {
    "reviews": "reviews | {n} review | {n} reviews | {n} reviews",
    "allReviews": "All reviews",
    "otherReviews": "Show other reviews",
    "titleReviews": "{count} from guests",
    "titleReviewsOther": "Reviews from other resources",
    "titleReviewsHotel": "Отзывы на другие категории номеров",
    "noReviews": "Currently, no one has left a review on this object.",
    "noReviewsButOther": "This owner {count} has other objects.",
    "externalRating": "The rating is based on real reviews of guests on foreign services who left Russia. We do not publish full reviews, because the guests did not explicitly agree to this. Perhaps reviews will appear later.",
    "otherReviewsForeign": "The rest of the reviews were posted on foreign services that left Russia. We do not publish them in full, because. the guests did not give explicit consent. Perhaps these reviews will appear later."
	}
}
</i18n>
<template>
  <div class="reviews">
    <template v-if="objectReviews && objectReviews.count">
      <Rating :id="Number(objectData.id)" class="reviews__raing-block" />
      <p class="reviews__no-reviews external-rating" v-if="isExternalRating">{{ t("externalRating") }}</p>
      <ul class="reviews__list">
        <li class="reviews__item">
          <template
            v-if="getReviews && getReviews.length > 0 && getReviews[0]"
          >
            <Review :review="getReviews[0]" />
          </template>
          <template v-else>
            <Review
              v-if="getHotelReviewsMore && getHotelReviewsMore.length > 0 && getHotelReviewsMore[0]"
              :review="getHotelReviewsMore[0]"
              :is-hotel="true"
            />
          </template>
        </li>
      </ul>
      <div
        v-if="
          objectReviews && objectReviews.count > 1 || (getHotelData && hotelReviews.count > 1)
        "
        class="reviews__more"
      >
        <button class="button button_w-100" @click="reviewsViewCick">
          {{ t("allReviews") }}
        </button>
      </div>
    </template>

    <template v-else>
      <template v-if="getHotelData && hotelReviews.count > 0">
        <Rating
          :id="Number(objectData.id)"
          class="reviews__raing-block"
          hidden
        />
        <div class="reviews__more margin-0">
          <button class="button button_w-100" @click="reviewsViewCick">
            {{ t("allReviews") }}
          </button>
        </div>
      </template>
      <template v-else>
        <p class="reviews__no-reviews" v-if="isExternalRating">{{ t("externalRating") }}</p>
        <p class="reviews__no-reviews" v-else>{{ t("noReviews") }}</p>
        <template v-if="getReviewsOther && getReviewsOther.length">
          <p class="reviews__other-text">
            {{
              t("noReviewsButOther", {
                count: t("reviews", getReviewsOther.length),
              })
            }}
          </p>
          <div class="reviews__more">
            <button class="button button_w-100" @click="reviewsViewCick">
              {{ t("otherReviews") }}
            </button>
          </div>
        </template>
      </template>
    </template>

    <Modal
      ref="modal"
      v-model="reviewsView"
      theme="light"
      :title="t('allReviews')"
    >
      <div class="reviews__modal" ref="reviews">
        <!-- Отзывы объекта -->
        <div v-if="objectReviews && objectReviews.count && !isHotel" class="reviews__block">
          <div class="reviews__count">
            <p class="reviews__count-title tmp-font--average_bold">
              {{ t("titleReviews", { count: t("reviews", objectReviews.count) }) }}
            </p>
            <div class="reviews__count-rating">
              <BaseRating
                v-if="
                  avgRating && avgRating.ratings && avgRating.ratings.avg_rating
                "
                :data-rating="Number(avgRating.ratings.avg_rating)"
                :view-count="objectReviews.count > 1"
                :view-stars="objectReviews.count > 1"
                :is-small="true"
                :scale="true"
                class="tmp-font--medium_bold"
              />
            </div>
          </div>
          <ul class="reviews__list">
            <CommentsSorter v-if="objectReviews.count > 3" :sort-options="sorterOptions" @sort="doSort"/>
            <li
              v-for="review in getReviews"
              :key="review.id"
              class="reviews__item"
              :class="{ 'select-review': review.id == selectMessage }"
            >
              <Review :review="review" />
            </li>
            <li class="reviews__item-text" v-if="objectReviews && objectReviews.count != countReviews">
              {{ t("otherReviewsForeign") }}
            </li>
          </ul>
        </div>

        <div v-if="hotelReviews && hotelReviews.count && isHotel" class="reviews__block">
          <div class="reviews__count">
            <p class="reviews__count-title tmp-font--average_bold">
              {{ t("titleReviews", { count: t("reviews", hotelAvgRating.count) }) }}
            </p>
            <div class="reviews__count-rating">
              <BaseRating
                  v-if="
                  hotelAvgRating && hotelAvgRating.ratings && hotelAvgRating.ratings.avg_rating
                "
                  :data-rating="Number(hotelAvgRating.ratings.avg_rating)"
                  :view-count="hotelReviews.count > 1"
                  :view-stars="hotelReviews.count > 1"
                  :is-small="true"
                  :scale="true"
                  class="tmp-font--medium_bold"
              />
            </div>
          </div>
          <CommentsSorter :sort-options="sorterOptions" @sort="doSort"/>
          <ul class="reviews__list">
            <li
                v-for="review in getHotelReviewsMore"
                :key="review.id"
                class="reviews__item"
                :class="{ 'select-review': review.id == selectMessage }"
            >
              <Review :review="review" :is-hotel="true" />
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Rating from "./Rating";
import BaseRating from "@/components/base/BaseRating";
import Review from "./Review";
import ReviewOther from "./ReviewOther";
import Modal from "@sutochno/modal";
import { Detail } from "@/utils/gtm/";
import CommentsSorter from '@/components/base/BaseComments/CommentsSorter';
import { useI18n } from 'vue-i18n';
import sutochnoMetrika from "@/utils/sutochnoMetrika";

export default {
  name: "Reviews",
  components: {
    CommentsSorter,
    Rating,
    BaseRating,
    Review,
    ReviewOther,
    Modal,
  },
  props: {
    objectData: {
      type: Object,
      default: null,
    },
    objectReviews: {
      type: Object,
      default: null,
    },
    hotelData: {
      type: Object,
      default: null,
    },
    hotelReviews: {
      type: Object,
      default: null,
    },
    isExternalRating: {
      type: Boolean,
      default: false
    },
    countReviews: {
      type: Number,
      default: 0
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      reviewsView: false,
      selectMessage: null,
      sorterOptions: [
        { title: 'по умолчанию', value: 'default' },
        { title: 'сначала новые', value: 'new' },
        { title: 'сначала с высокой оценкой', value: 'high' },
        { title: 'сначала с низкой оценкой', value: 'low' },
      ],
      commentsSort: '',
    };
  },
  async mounted() {
    if (this.$route.query.message_id) {
      this.selectMessage = this.$route.query.message_id;
    }
    await this.loadReviews();
    if (!this.objectReviews.count) {
      // this.setReviewsOther({ id: this.objectData.id, page: 1 });
    }
  },
  computed: {
    ...mapGetters("detail", [
      "getHotelData",
      "getReviews",
      "getHotelReviewsMore",
      "avgRating",
      "getReviewsOther",
      "getObjectData",
      "hotelAvgRating",
      "getHotelReviews",
      "isHotel",
    ]),
    ...mapGetters("search", ["commonParamsForMetrika"]),
  },
  methods: {
    ...mapActions("detail", ["setReviewsMore", "setReviewsOther"]),
    async doSort(e) {
      this.commentsSort = e
      Detail.gaSortReviews(this.commentsSort, 'mobile')
      await this.loadReviews()
    },
    reviewsViewCick () {
      this.reviewsView = !this.reviewsView;
      // this.$ga.event("object_sa_m", "click", "all_reviews");
      Detail.gaClickMoreReviews("mobile", this.getObjectData?.id);

      sutochnoMetrika.detail('click', 'object_reviews_more', {
        ...this.commonParamsForMetrika,
        page: "detail",
      })
    },
    async loadReviews() {
      await this.setReviewsMore({
        id: this.isHotel ? this.hotelData.id : this.objectData.id,
        limit: this.isHotel ? this.hotelAvgRating?.count : this.objectReviews.count,
        isHotel: this.isHotel ? 1 : 0,
        sort: this.commentsSort === 'default' ? '' : this.commentsSort
      });
      // if (!this.getReviews || !this.getReviews.length) {
      //   await this.setReviewsMore({
      //     id: this.isHotel ? this.hotelData.id : this.objectData.id,
      //     limit: this.isHotel ? this.hotelAvgRating.count : this.objectReviews.count,
      //     isHotel: this.isHotel ? 1 : 0,
      //     sort: this.commentsSort === 'default' ? '' : this.commentsSort
      //   });
      // }
      //
      // if (
      //   (!this.getHotelReviewsMore || !this.getHotelReviewsMore.length) &&
      //   this.hotelData
      // ) {
      //   await this.setReviewsMore({
      //     id: this.isHotel ? this.hotelData.id : this.objectData.id,
      //     limit: this.isHotel ? this.hotelAvgRating.count : this.objectReviews.count,
      //     isHotel: this.isHotel ? 1 : 0,
      //     sort: this.commentsSort === 'default' ? '' : this.commentsSort
      //   });
      // }

      if (
        !this.getHotelData &&
        (!this.getReviewsOther || !this.getReviewsOther.length)
      ) {
        // await this.setReviewsOther({ id: this.objectData.id, page: 1 });
      }

      if (this.selectMessage) {
        this.reviewsView = true;
        setTimeout(() => {
          if (this.$refs.reviews && this.$refs.reviews.querySelector(".select-review")) {

            window.scrollTo(
              0,
              (this.$refs.reviews
                .querySelector(".select-review")
                .getBoundingClientRect().top) - 50
            );
          }
        }, 200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reviews {
  &__block {
    margin-top: 40px;
    &:first-child {
      margin-top: 0;
    }
  }
  &__count {
    position: relative;
    margin-bottom: 30px;
    &_other {
      margin-top: 30px;

      &-min {
        margin-top: 0px;
      }
    }
    &-title {
      font-size: 18px;
      color: #000;
    }
    &-rating {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &__raing-block {
    margin-bottom: 40px;
  }

  &__no-reviews {
    color: #7f7f7f;
    &.external-rating {
      margin-bottom: 28px;
    }
  }
  &__other-text {
    margin-top: 15px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    &:last-child {
      margin-bottom: 85px;
    }
  }

  &__item {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }
  &__more {
    margin-top: 15px;
    .button {
      color: #000;
    }
  }
  &__modal {
    padding: 20px;
  }

  &__item-text {
    color: #7f7f7f;
    margin-top: 20px;
  }

  .margin-0 {
    margin: 0;
  }
}
</style>

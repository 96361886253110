<template>
  <div class="slider" v-if="media">
    <div @click="galleryShow" class="slider__wrap">
      <SliderLight :media="media" :dots="true"/>
    </div>
    <Gallery
      v-if="viewImage"
      :media="getHotelData.hotel.media"
      :video="{}"
      :items-to-show="3"
      :view-image="viewImage"
      :selected-slide="selectedSlide"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import SliderLight from "@sutochno/sliderlight";
import Gallery from "@sutochno/gallery";

export default {
  name: "SliderHotel",
  components: {
    SliderLight,
    Gallery
  },
  data() {
    return {
      selectedSlide: 0,
      viewImage: false
    }
  },
  computed: {
    ...mapGetters("detail", ["getHotelData"]),
    media() {
      const media = this?.getHotelData?.hotel?.media?.map(img => img.image_url.replace('source', 'medium'))
      if(media)
        return this.getHotelData.hotel.media.map(img => img.image_url.replace('source', 'medium'))
      else
        return false
    }
  },
  methods: {
    galleryShow() {
      this.viewImage = !this.viewImage
      sendEventGtag("object_sa_m", {
        click: "open_gallery",
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.slider {
  height: 210px;
  &__wrap {
    width: 100%;
    height: 100%;
  }

  .mobile & {
    :deep(.gallery) {
      .preview-img {
        max-height: 78vmax;
        object-fit: cover;
      }
    }
    :deep(.gallery__toolbar) {
      position: fixed;
      width: 100%;
      z-index: 1;
      .icon-app-cross-min {
        margin-top: 5px;
        margin-right: 5px;
        border-radius: 100%;
        background: rgba(255, 255, 255, 0.25);
        &:before {
          margin-top: -2px;
          margin-left: -5px;
          width: 20px;
          height: 20px;
        }
      }
      .icon-cross {
        display: none;
      }
      .icon-cross {
        display: none;
      }
    }
    :deep(.gallery__description) {
      position: absolute;
      top: 70vmax;
      padding: 2px 40px;
      width: 100%;
      height: 40px;
      overflow: auto;
      font-size: 14px;
    }
    :deep(.gallery__nav) {
      margin: 0 -1px 3px;
    }
  }
}
</style>

<i18n lang="json">
{
  "ru": {
    "title": "Внимание",
    "cancelAndContinue": "Отменить и продолжить"
  },
  "en": {
    "title": "Attention",
    "cancelAndContinue": "Cancel and Continue"
  }
}
</i18n>
<template>
  <BottomSheet v-model="visible" class="modal-warning" :title="t('title')">
    <div class="body" v-html="textModal"></div>
    <div class="footer">
      <button
        class="button btn-white button_size_md width-100"
        :class="{ loading: loading }"
        @click="cancelAndContinue"
      >
        <span>
          {{ t("cancelAndContinue") }}
        </span>
      </button>
    </div>
  </BottomSheet>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import BottomSheet from "@/components/base/BottomSheet";
import dataLayerPushGA4 from "@/mixins/dataLayerPushGA4.js";
import { useI18n } from 'vue-i18n';

export default {
  name: "ModalWarning",
  mixins: [dataLayerPushGA4],
  components:{
    BottomSheet
  },
  props: {
    value:{
      type: Boolean,
      default: false,
    },
    textModal: {
      type: String,
      default: "",
    },
    intersect: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("detail", ["getBookingData"]),
    ...mapGetters("booking", ["bookingPrice"]),
    ...mapState("detailBooking", ["canHold"]),
    visible:{
      get(){
        return this.value
      },
      set(val){
        this.$emit("update:modelValue", val)
      }
    }
  },
  methods: {
    ...mapActions("booking", ["bookingToAction", "bookingAnalytics"]),
    cancelAndContinue() {
      if (this.intersect) {
        this.loading = true;
        this.bookingToAction({
          order_code: this.getBookingData.order_code,
          no_matter: 1,
          cost: this.bookingPrice?.price,
          precost: this.bookingPrice?.precost,
        })
          .then((response) => {
            this.loading = false;
            if (response?.data?.success) {
              this.bookingAnalytics();
              this.visible = false;
              // $emit('cancelAndContinue') - вызывает модалку "Запрос на бронь отправлен"
              this.$emit('cancelAndContinue', true)
              if (response?.data?.data?.to_url) {
                this.$emit('sendGrandtrain', response.data.data.to_url);
              }
            }
            if (response?.data?.errors.length) {
              // Вы уже подали заявку, сформируйте новую!
              // TODO: сделал чтобы закрывалась, но нужно уточнить
              // если нужно будет опрокинуть текст выше то можно сделать через sync
              //this.$emit("textModal",response?.data?.errors[0]);
              this.visible = false;
            }
          })
          .catch((err) => {
            console.warn(err);
          });
      } else {
        this.visible = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-warning {
  .footer {
    background-color: #fff;
    padding: 0 23px 15px;
    margin-top: 20px;
    .loading span {
      opacity: 0;
    }
  }
}
</style>

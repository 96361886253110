<template>
  <div class="review">
    <div class="review__avatar">
      <img :src="avatar" class="avatar__reviewer">
    </div>
    <div class="review__main">
      <div class="review__text">

        <div class="review__request" v-if="messageText">
          <p>{{ messageText }}</p>
        </div>

      </div>
      <div class="review__info">
        <p class="review__date tmp-font--small">{{ date }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatSafari } from "@/utils/format";
import avatarSmall from "assets/img/default-avatar_small.jpg";

export default {
  name: "Response",
  props: {
    review: {
      type: Object,
      default: {}
    }
  },
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters("detail", ["getObjectData", "getObjectReviews"]),
    name() {
      if(this.review?.user?.data?.properties?.first_name?.value)
        return this.review.user.data.properties.first_name.value;
      else
        return 'Пользователь'
    },
    avatar() {
      const media = this.getObjectData?.owner?.avatar;
      if(media) 
        return media
      else 
        return avatarSmall;
    },
    date() {
      return new Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(new Date(formatSafari(this.review.response_at)))
        .replace(" г.", "");
    },
    messageText() {
      const prop = this.review?.response;
      if(prop)
        return prop
      else 
        return false
    }
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.review {
  display: flex;
  margin-top: 20px;

  &__avatar {
    flex-basis: 30px;
    min-width: 30px;
    height: 30px;
    margin-left: 10px;
    .avatar {
      &__reviewer {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  &__main {
    flex-grow: 1;
    margin-left: 20px;
    margin-right: 40px;
  }
  &__info {
    margin-top: 5px;
  }
  &__date {
    color: #7f7f7f;
  }
  &__advantages {
    color: #7f7f7f;
    margin-bottom: 5px;
  }
}
</style>

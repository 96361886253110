<template>
  <p class="warning" :class="{ error: isError }">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: "UIWarning",
  props:{
    isError: {
      type: Boolean,
      defauld: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.warning{
  padding: 10px 12px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--default-fonts-color);
  background: #FFF7CF;
  border-radius: 6px;
}
.warning.error{
  background: #FFE8E8;
}
</style>
<i18n lang="json">
{
  "ru": {
    "otherConditions": "Выбрать другие условия",
    "toAccept": "Продолжить"
  },
  "en": {
    "otherConditions": "Select other conditions",
    "toAccept": "Proceed"
  }
}
</i18n>
<template>
  <div class="rules-booking-footer width-100">
    <span class="agree-other" @click="routerDraft">{{
      t("otherConditions")
    }}</span>
    <button
      class="button button_blue button_size_md"
      :class="{ loading: loading }"
      @click="toAccept"
    >
      <span>{{ t("toAccept") }}</span>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { Booking } from "@/utils/gtm/";
import { Payments } from "@/components/api/";
import { isNavigationFailure } from "vue-router";
import objectTimeLocation from "@/mixins/objectTimeLocation.js";
import dataLayerPushGA4 from "@/mixins/dataLayerPushGA4.js";
import { useI18n } from 'vue-i18n';

export default {
  name: "RulesBookingFooter",
  mixins: [objectTimeLocation, dataLayerPushGA4],
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("detail", ["getBookingData"]),
    ...mapGetters("booking", ["bookingPrice"]),
    ...mapState("detailBooking", ["canHold"]),
  },
  methods: {
    ...mapActions("booking", ["bookingToAction", "bookingAnalytics"]),
    ...mapMutations("detailBooking", ["setModalStep", "setIsModalOpen"]),
    ...mapActions("detailBooking", ["routeBooking"]),
    ...mapMutations("modal", ["setModalType"]),
    routerDraft() {
      sendEventGtag("draft_sa_m", {
        click: "order_rules_others",
      });
      this.setIsModalOpen(false);
      this.setModalStep("booking");
      this.$router
        .push({
          name: "Booking",
          params: { orderCode: this.getBookingData.order_code },
        })
        .then((failure) => {
          if (isNavigationFailure(failure)) {
            this.$router.push({
              name: "Booking",
              params: { orderCode: this.getBookingData.order_code },
            });
          }
        });
    },
    async goToSearch() {
      const { id, title: term } = this.getObjectDataMixin?.location?.city || {};
      const query = JSON.parse(localStorage.getItem("query")) ?? { id, term }

      await this.$router.push({
        name: "Search",
        query,
      });
    },
    toAccept() {
      sendEventGtag("draft_sa_m", {
        click: "order_rules_next",
      });
      this.loading = true;
      if (this.isTodayBooking) {
        this.routerDraft()
        return false
      }
      this.bookingToAction({
        order_code: this.getBookingData.order_code,
        no_matter: 0,
        cost: this.bookingPrice?.price,
        precost: this.bookingPrice?.precost,
      })
        .then((response) => {
          this.loading = false;
          if (response?.data?.data?.to_url) {
            this.bookingAnalytics();
            this.$emit('sendGrandtrain', response.data.data.to_url);
          }
          if (this.canHold) {
            if (response?.data?.data?.to_url) {
              this.$emit('sendGrandtrain', response.data.data.to_url);
            }
            if (response?.data?.data?.intersect) {
              this.setModalStep("booking");
              this.$emit("intersect", true);
            }
            if (response?.data?.errors?.dates) {
              this.$emit("intersect", false);
              this.$emit("errorsDates", response?.data?.errors?.dates);
            }
            if (Array.isArray(response?.data?.errors) && response?.data?.errors.length) {
              this.$emit("intersect", false);
              this.$emit("errorsDates", response?.data?.errors[0]);
            }
          } else {
            if (response?.data?.success) {
              this.$emit("showModal", true);
            }
            if (response?.data?.data?.intersect) {
              this.setModalStep("booking");
              this.$emit("intersect", true);
            }
            if (response?.data?.errors?.dates) {
              this.$emit("intersect", false);
              this.$emit("errorsDates", response?.data?.errors?.dates);
            }
            if (Array.isArray(response?.data?.errors) && response?.data?.errors.length) {
              this.$emit("intersect", false);
              this.$emit("errorsDates", response?.data?.errors[0]);
            }

            this.setModalType("ModalRequest");
            this.goToSearch();
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.rules-booking-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .agree-other {
    cursor: pointer;
    color: #2d6cb4;
    font-size: 12px;
  }
  .loading span {
    opacity: 0;
  }
}
</style>

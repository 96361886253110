<i18n lang="json">
{
	"ru": {
    "advantages": "Достоинства",
    "disadvantages": "Недостатки"
	},
	"en": {
    "advantages": "Advantages",
    "disadvantages": "Disadvantages"
	}
}
</i18n>
<template>
  <div class="review">
    <div class="review__wrap">
      <div class="review__avatar">
        <img :src="avatar" class="avatar__reviewer">
      </div>
      <div class="review__main">
        <div class="review__info">
          <p class="review__name">{{ name }}</p>
          <p class="review__date tmp-font--small">{{ date }}</p>
        </div>
        <div class="review__text" v-if="comment">

          <div class="review__request">
            <p class="review__advantages">{{ t('advantages') }}</p>
            <p>{{ comment }}</p>
          </div>

        </div>
      </div>
      <div class="review__rating">
        <Rating
          class="card-prices__rating"
          :data-rating="Number(review.total_rating)"
          :view-count="true"
          :is-small="true"
        />
      </div>
    </div>
    <div class="review__responses" v-if="review.response">
      <ResponseOther 
        :review="response" 
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Rating from "@/components/base/BaseRating";
import { formatSafari } from "@/utils/format";
import ResponseOther from "./ResponseOther";
import { useI18n } from 'vue-i18n';
import avatarSmall from 'assets/img/default-avatar_small.jpg';

export default {
  name: "ReviewOther",
  props: {
    review: {
      type: Object,
      default: {}
    }
  },
  components: {
    Rating,
    ResponseOther
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
    }
  },
  computed: {
    name() {
      if(this.review?.name)
        return this.review.name;
      else
        return 'Пользователь'
    },
    avatar() {
      // const media = this.review?.avatar;
      // if(media)
      //   return media
      // else
        return avatarSmall;
    },
    date() {
      return new Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(new Date(formatSafari(this.review.write_at)))
        .replace(" г.", "");
    },
    comment() {
      const prop = this.review?.review;
      if(prop && prop.replace(' ', '') !== "")
        return prop
      else 
        return false
    },
    response() {
      return {
        response: this.review.response,
        response_at: this.review.response_at
      }
    }
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.review {

  &__wrap {
    display: flex;
  }

  &__avatar {
    flex-basis: 40px;
    min-width: 40px;
    height: 40px;
    .avatar {
      &__reviewer {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  &__main {
    flex-grow: 1;
    margin-left: 20px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40px;
  }
  &__date {
    color: #7f7f7f;
  }

  &__text {
    margin-top: 15px;
  }
  &__advantages {
    color: #7f7f7f;
    margin-bottom: 5px;
  }
  &__request {
    margin-top: 15px;
    &:first-child {
      margin-top: 0;
    }
  }


  &__rating {
    flex-basis: 40px;
    min-width: 40px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>

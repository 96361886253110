<i18n lang="json">
{
  "ru": {
    "error": "Бронирование временно недоступно",
    "btnRequest": "Хочу забронировать",
    "btnInstant": "Забронировать"
	},
	"en": {
    "error": "Reservations are temporarily unavailable",
    "btnRequest": "Booking",
    "btnInstant": "Booking"
	}
}
</i18n>
<template>
  <div class="booking__block btn-w100">
    <div class="booking__text" >
      {{t("error")}}
    </div>
    <div class="booking__button" >
      <button
        class="button button_green button_size_md button_w-100 button_disabled" 
        :disabled="true"
      >
        
          <template v-if="getInstant">
            <span class="icon-app-lightning"></span>
            <span>{{ t("btnInstant") }}</span>
          </template>
          <template v-else>
            <span>{{ t("btnRequest") }}</span>
          </template>

      </button>
    </div>
    
  </div>


</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "ButtonSwitch",
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapGetters("detail", [
      "getInstant",
    ]),
  }
};
</script>

<style lang="scss" scoped>

.booking {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  margin: 0 !important;

  &.upper {
    z-index: 200;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-app-lightning {
      font-size: 16px;
      padding-right: 10px;
    }
  }

  .booking {
    &__grid {
      display: grid;
      grid-template-areas: "precost btn" "info info";
      grid-template-columns: 1fr 1fr;
      &.btn-w100 {
        grid-template-areas: "btn";
        grid-template-columns: 1fr;
      }

      .booking__text {
        grid-area: precost;
        color: #e10600;
        font-size: 13px;
        line-height: 17px;
      }
      .booking__button {
        grid-area: btn;
        width: 100%;
      }
      .booking__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-area: info;
        margin-top: 10px;
        font-size: 12px;
      }
      .booking__price-info {
        display: flex;
        align-items: center;
        color: #717171;
      }
      .booking__price-del {
        margin-right: 5px;
        &::before {
          content: '';
          display: block;
          width: 100%;
          border-top: 1px solid #ff0000;
          transform: rotate(-10deg) translate(0px, 8px);
        }
      }
      .booking__sale,
      .booking__check-in {
        margin: 0;
      }
      .booking__sale {
        color: #e10600;
        font-weight: 500;
        text-align: right;
      }
    }

    &__text,
    &__button {
      width: 50%;
    }
    &__button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    &__text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      color: #e10600;
      font-size: 13px;
      line-height: 17px;
      &-price {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 12px;
      }
    }
    &__precost {
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 16px;
      &-popover{
        display: inline-flex;
        align-items: center;
        flex-wrap: nowrap;
      }
      span {
        font-size: 12px;
      }
    }
    &__check-in {
      margin-top: 4px;
      color: #717171;
    }
    &__price {
      color: #417505;
      font-weight: bold;
    }
    &__count {
      margin-left: 5px;
    }
  }
  &__block {
    width: 100%;
    display: flex;
    align-items: center;
  }
}
.moderate-text{
  padding: 15px 10px;
}
</style>

<template>
  <transition name="fade">
    <div v-show="modelValue" class="bottom-sheet-wrap">
      <div class="click-outside" @click="hide"></div>
      <transition name="bottom-sheet--fade">
        <div class="bottom-sheet" :class="{ loading: isLoading }">
          <div class="bottom-sheet__head">
            <span class="close-modal" @click="hide"></span>
            <div class="title tmp-font--average">
              <template v-if="!!$slots['head']">
                <slot name="head"></slot>
              </template>
              <template v-else>{{ title }}</template>
            </div>
          </div>
          <div class="bottom-sheet__body">
            <slot></slot>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
export default {
  name: "BottomSheet",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    title:{
      type: String,
      default: "",
    },
    isLoading:{
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hide() {
      this.$emit("update:modelValue", false);
    },
  },
};
</script>
<style lang="scss" scoped>
$topRadius: 22px;

.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: opacity 0.2s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.bottom-sheet--fade-enter-active,
.bottom-sheet--fade-leave-active {
  transform: translateY(0%);
  transition: transform 0.5s ease;
}
.bottom-sheet--fade-enter,
.bottom-sheet--fade-leave-to {
  transform: translateY(150%);
}
.bottom-sheet {
  position: relative;
  width: 100%;
  &-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-overflow-scrolling: touch;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 300;
    display: flex;
    align-items: flex-end;

    .click-outside {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  &__head {
    position: relative;
    border-top-left-radius: $topRadius;
    border-top-right-radius: $topRadius;
    padding: 15px 0;
    background-color: #f6f6f6;
    text-align: center;

    .close-modal {
      display: block;
      cursor: pointer;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 20px;
      left: 23px;
      &:after,
      &:before {
        content: "";
        display: block;
        width: 14px;
        height: 1px;
        background: #232323;
        position: absolute;
        top: 0;
        left: 0;
        margin: 7px 0 0 -1px;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(-45deg);
      }
    }
  }

  &__body {
    min-height: 200px;
    padding: 15px 23px calc(15px + env(safe-area-inset-bottom));
    background-color: #fff;
  }

  &.loading {
    &::before{
      border-top-left-radius: $topRadius;
      border-top-right-radius: $topRadius;
    }
  }
}
</style>

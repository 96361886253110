<i18n lang="json">
{
	"ru": {
    "emptyText": "Данное объявление было удалено, перемещено или временно приостановлено"
	},
	"en": {
    "emptyText": "Данное объявление было удалено, перемещено или временно приостановлено"
	}
}
</i18n>
<template>
  <div class="empty-block">
    <p class="empty-block__text">{{ t("emptyText") }}</p>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<style lang="scss" scoped>
.empty-block {
  width: 100%;

  &__text {
    padding: 100px 20%;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
  }
}
</style>

<template>
  <div ref="blockRooms">
    <div class="rooms" v-if="getHotelData && hotelObjectsId" :class="{ guests: guests }">
      <div class="rooms__availability">
        <Availability ref="availability" @guests="guests = true" @guestsClose="guests = false" />
      </div>
      <div class="rooms__list">
        <Room
          v-for="(room, i) in objectsFilter"
          :room="room"
          :price="getHotelPrice ? getHotelPrice.find(item => item.id == room.id) : null"
          :key="i"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Availability from './Availability';
import Room from './Room';

export default {
  name: "RoomsList",
  components: {
    Availability,
    Room
  },
  data() {
    return {
      guests: false
    }
  },
  mounted() {
    // this.setHotelPrice()
    this.visibleBtnDetail();
  },
  computed: {
    ...mapGetters("detail", ["getObjectData", "getHotelData", "getHotelPrice", "getHotelPrice"]),
    ...mapState("detail", ["hotelObjectsId"]),
    objectsFilter() {
      return this.getHotelData.objects.filter(item => item.id != this.getObjectData.id)
    },
    trmHotelPrice() {
      if(!this.getHotelPrice) return null
      return this.getHotelPrice.reduce((acc, item) => {
        acc[item.id] = item
        return acc
      }, {})
    }
  },
  methods: {
    ...mapActions("detail", ["setHotelPrice"]),
    ...mapMutations("detail", ["setVisibleBtnMobile"]),
    visibleBtnDetail() {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.setVisibleBtnMobile(false);
          } else {
            this.setVisibleBtnMobile(true);
          }
        })
      }, { threshold: 0.2 })

      observer.observe(this.$refs.blockRooms)
    }
  }
};
</script>

<style lang="scss" scoped>
.rooms {
  &.guests {
    position: relative;
    z-index: 200;
  }
  &__availability {
    position: sticky;
    top: 50px;
    z-index: 3;
  }
  &__list {
    margin-top: -20px;
  }
}
</style>

<i18n lang="json">
{
  "ru": {
    "edit": "Изменить",
    "checkInOut": "Заезд / отъезд",
    "guests": "Гости",
    "adults":"{n} взрослых | {n} взрослый | {n} взрослых | {n} взрослых",
    "childrens": "0| {n} ребенок | {n} детей | {n} детей",
    "after": "после",
    "before": "до",
    "nights": "менее суток | За сутки | За {n} суток | За {n} суток",
    "bonuses": "-{bonuses} бонусов",
    "withPets": "питомец"
  },
  "en": {
    "edit": "Edit",
    "checkInOut": "check in / check out",
    "guests": "Guests",
    "adults":"{n} adults | {n} adults | {n} adults | {n} adults",
    "childrens": "0| {n} children | {n} childrens | {n} childrens",
    "after": "after",
    "before": "before",
    "nights": "less night | in {n} night | in {n} nights | in {n} nights",
    "bonuses": "-{bonuses} bonuses",
    "withPets": "pets"
  }
}
</i18n>

<template>
  <div class="booking-form">
    <p class="booking-form__alert" v-if="getQuery && getQuery.query && getQuery.query.offer_id">
      Обратите внимание! Если вы поменяете даты или количество гостей, стоимость проживания может измениться.
    </p>
    <PriceObject :is-check="isCheck" />
    <div class="check-wrapp" v-if="isCheck">
      <div class="check-title-wrapp">
        <div class="check-title">{{ t("checkInOut") }}</div>
        <div class="check-action">
          <button class="button button_link" @click="onModalCheck">{{ t("edit") }}</button>
        </div>
      </div>
      <div class="check-info-wrapp">
        <span>
          <strong>{{ checkInFormatDate }}, </strong>
          <span>{{ checkInFormatWeekday }} {{ t("after") }} {{ checkInTime }}</span>
          <span> — </span>
          <strong>{{ checkOutFormatDate }}, </strong>
          <span>{{ checkOutFormatWeekday }} {{ t("before") }} {{ checkOutTime }}</span>
        </span>
      </div>
    </div>
    <div class="check-wrapp">
      <div class="check-title-wrapp">
        <div class="check-title">{{ t("guests") }}</div>
        <div class="check-action">
          <button class="button button_link" @click="onModalGuests">{{ t("edit") }}</button>
        </div>
      </div>
      <div class="check-info-wrapp">
        <span>
          <strong>{{ t('adults', guests.adults) }}</strong>
          <strong v-if="childrensCount">, {{ t('childrens', childrensCount) }}</strong>
          <strong v-if="guests.pets?.value">, {{ t('withPets') }}</strong>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import { formatDatePrint, formatWeekdayPrint } from "@/utils/format";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { useI18n } from 'vue-i18n';

import Button from '../../base/Booking/Content/Form/BookingButton/Button.vue'
import PriceObject from "@/components/base/PriceCalcDetail/PriceObject.vue";
import sutochnoMetrika from "@/utils/sutochnoMetrika";

export default {
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapGetters("detail", ["getObjectData"]),
    ...mapState("search", ["checkIn", "checkOut", "guests"]),
    ...mapGetters("search", ["getQuery", "commonParamsForMetrika"]),
    checkInFormatDate () {
      return formatDatePrint(this.checkIn);
    },
    checkInFormatWeekday () {
      return formatWeekdayPrint(this.checkIn);
    },
    checkInTime () {
      const checkIn = this.getObjectData.properties.check_in_out_rules?.properties?.filter(item => item.name === 'check_in')[0];
      return checkIn ? checkIn.value : '14:00';
    },
    checkOutFormatDate () {
      return formatDatePrint(this.checkOut);
    },
    checkOutFormatWeekday () {
      return formatWeekdayPrint(this.checkOut);
    },
    checkOutTime () {
      const checkOut = this.getObjectData.properties.check_in_out_rules?.properties?.filter(item => item.name === 'check_out')[0];
      return checkOut ? checkOut.value : '12:00';
    },
    childrensCount () {
      return this.guests.childrens.length;
    },
    isCheck () {
      return this.checkIn && this.checkOut;
    },
  },
  components: {
    Button,
    PriceObject,
  },
  methods: {
    ...mapMutations("detailBooking", ["setModalStep", "setIsModalOpen"]),
    metrikaCalling(screenBlock = 'dates') {
      sutochnoMetrika.detail('click', screenBlock, {
        ...this.commonParamsForMetrika,
        page: "detail",
      })
    },
    async onModalCheck () {
      this.setModalStep('calendar');
      sendEventGtag("draft_sa", {
        click: "btn_object_date_open",
      });
      this.setIsModalOpen(true);

      await this.metrikaCalling('dates');
    },
    async onModalGuests () {
      this.setModalStep('guests');
      sendEventGtag("draft_sa", {
        click: "btn_guests_open_edit",
      });
      this.setIsModalOpen(true);

      await this.metrikaCalling('guests');
    },
  }
}
</script>
<style lang="scss" scoped>
.booking-form {
  &__alert {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #fff6c4;
    padding: 8px 20px;
    margin-bottom: 12px;
    text-align: justify;
  }
}
.check-wrapp {
  margin-bottom: 25px;
}
.check-wrapp:last-child {
  margin-bottom: 0;
}
.check-title-wrapp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.check-title {
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
}
</style>

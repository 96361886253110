<i18n lang="json">
{
	"ru": {
    "checkIn": "Заезд",
    "checkOut": "Отъезд",
    "checkInTime": "с {start}",
    "checkOutTime": "до {time}"
	},
	"en": {
    "checkIn": "Check-in",
    "checkOut": "Check-out",
    "checkInTime": "from {start}",
    "checkOutTime": "to {time}"
	}
}
</i18n>
<template>
  <div class="interval" v-if="time">
    <div class="interval__item">
      <div class="interval__title tmp-font--average_bold">{{ t('checkIn') }}</div>
      <div class="interval__time">{{ t('checkInTime', {start: time.check_in.value}) }}</div>
    </div>
    <div class="interval__item">
      <div class="interval__title tmp-font--average_bold">{{ t('checkOut') }}</div>
      <div class="interval__time">{{ t('checkOutTime', {time: time.check_out.value}) }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "CheckInOut",
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapGetters("detail", ["getObjectData"]),
    time() {
      const rules = this.getObjectData?.properties?.check_in_out_rules?.properties;
      const checkInOut = this.getObjectData?.properties?.additional_payment?.properties
        ?.filter(item => item.name === 'check_in' || item.name === 'check_out');

      if(rules) {
        return rules.reduce((acc, item) => {
          acc[item.name] = item
          return acc
        }, {})
      } else if(checkInOut) {
        return checkInOut.reduce((acc, item) => {
          acc[item.name] = item
          return acc
        }, {})
      } else {
        return false
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.interval {
  display: flex;

  &__item {
    flex-basis: 50%;
    min-width: 50%;
  }

  &__title {
    color: #444;
  }
  &__time {
    margin-top: 10px;
    font-weight: 600;
    color: #000;
  }
}
</style>

<template>
  <PriceList :raw-data="rawData" />
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import PriceList from "./PriceList.vue";
import { useCurrencyCalc } from "@/composables/price/useCurrencyCalc";

const store = useStore();

const props = defineProps({
  prices: {
    type: Object,
    required: true,
  }
});

const currencyCalc = useCurrencyCalc();
const rawData = computed(() => {
  const { object_hot } = store.getters["detail/getObjectPrice"];
  const { is_hot } = store.getters['detail/getObjectData'];
  return {
    surcharge: props.prices.surcharge.list,
    discounts: props.prices.discounts.list,
    one_day_price: props.prices.one_day_price,
    nights: props.prices.nights,
    object_hot,
    is_hot,
    currency: currencyCalc.value,
  }
});
</script>

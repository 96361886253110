<i18n lang="json">
{
	"ru": {
    "title": "Выберите даты заезда и отъезда",
    "text": "Доступность жилья и стоимость зависят от дат проживания."
	},
	"en": {
    "title": "Select arrival and departure dates",
    "text": "Availability and cost depend on dates of stay."
	}
}
</i18n>

<template>
  <div class="banner" :class="{ 'is-min': isMin }">
    <div class="banner__content">
      <div class="banner__datepicker"></div>
      <div class="banner__info">
        <p class="banner__title">{{ t('title') }}</p>
        <p class="banner__text">{{ t('text') }}</p>
      </div>
    </div>
    <div class="banner__buttons">
      <slot />
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  isMin: {
    type: Boolean,
    default: false
  }
});
</script>
<style lang="scss" scoped>
.is-min {
  &.banner {
    flex-direction: column;
    align-items: flex-start;
    height: max-content;
    padding: 20px 18px 30px 20px;
    border-radius: 8px;

    .banner {
      &__datepicker {
        width: 40px;
        height: 40px;
      }
      &__content {
        flex-direction: column;
        align-items: flex-start;
      }
      &__info {
        margin-top: 20px;
        margin-left: 0;
      }
      &__buttons {
        margin-top: 15px;
      }

    }
  }
}
.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 130px;
  background-color: #ececec;
  padding: 20px 60px;

  &__datepicker {
    width: 50px;
    height: 50px;
    background-image: url("~/assets/img/datepicker.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__content {
    display: flex;
  }
  &__info {
    margin-left: 40px;
  }
  &__title {
    color: #000;
    font-size: 17px;
    line-height: 21px;
    font-weight: 500;
  }
  &__text {
    margin-top: 10px;
    font-size: 14px;
    line-height: 17px;
    color: #717171;
  }
}
</style>

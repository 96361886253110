import { computed } from "vue";
import { useStore } from "vuex";
import { useCurrencyCalc } from "./useCurrencyCalc";
import { useBonuses } from "./useBonuses";

// https://confluence.webpower.ru/pages/viewpage.action?pageId=373097285
export const usePricesObject = () => {
  const store = useStore();
  const currencyCalc = useCurrencyCalc();
  const bonuses = useBonuses();

  const getObjectPrice = computed(() => store.getters['detail/getObjectPrice']);

  return computed(() => {
    const priceData = {
      max_price: 0, // Максимальная цена
      accrued_bonus: 0, // Начисляемый бонус
      all_day_price: 0, // Цена за все дни
      check_in_cost: 0, // Оплата при заселении
      one_day_price: 0, // Цена за сутки без учета скидок, доплат и бонусов
      per_day_price: 0, // Цена за сутки с учетом скидок, доплат и бонусов
      currency: 'RUB', // Сведения о валюте
      nights: 0, // Количество ночей проживания
      guests: 0, // Количество гостей
      price: 0, // Общая цена с учетом доплат и скидок
      pre_cost: 0, // Изначальная предоплата
      new_pre_cost: 0, // Предоплата со скидкой
      total_price: 0, // Итоговая цена
      surcharge: {
        all_cost: 0,
        list: {
          // Доплата за гостя
          dop_persons: {
            base: 0,
            cost: 0,
            nights: 0,
            guests: 0,
            type: 'dop_persons',
          },
          // Доплата за уборку
          cleaning: {
            base: 0,
            cost: 0,
            nights: 0,
            guests: 0,
            type: 'cleaning',
          },
        }
      },
      discounts: {
        all_cost: 0,
        list: {
          // скидка за длительное проживание
          interval: {
            base: 0,
            cost: 0,
            nights: 0,
            guests: 0,
            days: 0,
            type: 'interval',
          },
          // горящее предложение
          sale: {
            base: 0,
            cost: 0,
            nights: 0,
            guests: 0,
            type: 'sale',
          },
        }
      },
    }
    if (!getObjectPrice.value?.detail) {
      return priceData;
    }
    const result = getObjectPrice.value.detail.reduce((acc, current) => {
      if (current.type === 'interval' || current.type === 'sale') {
        const cost = current.cost * current.nights;
        acc.discounts.all_cost += cost;
        acc.discounts.list[current.type].cost += cost;
        acc.discounts.list[current.type].base += current.base * current.nights;
        acc.discounts.list[current.type].nights += current.nights;
        acc.discounts.list[current.type].guests = current.guests;
        if (current.type === 'interval') {
          acc.discounts.list[current.type].days = current.days;
        }
      } else if (current.type === 'dop_persons') {
        acc.surcharge.all_cost += current.cost * current.nights;
        acc.surcharge.list.dop_persons.cost += current.cost * current.nights;
        acc.surcharge.list.dop_persons.guests = current.guests;
        acc.surcharge.list.dop_persons.base += current.base * current.nights;
        if (current.cost) {
          acc.surcharge.list.dop_persons.nights += current.nights;
        }
      }
      return acc;
    }, priceData);

    const { dop_persons } = result.surcharge.list;
    if (dop_persons?.cost) {
      dop_persons.cost = dop_persons.cost / dop_persons.nights;
    }

    result.accrued_bonus = getObjectPrice.value.bonus;
    result.guests = getObjectPrice.value.cnt_guests;
    result.nights = getObjectPrice.value.cnt;
    result.price = getObjectPrice.value.price;
    result.pre_cost = getObjectPrice.value.precost;
    result.currency = currencyCalc.value.title || 'RUB';

    const cleaning = getObjectPrice.value.detail_dop.filter(v => v.tp === 'cleaning')[0];
    result.surcharge.list.cleaning.cost = (cleaning ? Number(cleaning.cost) : 0);
    result.surcharge.list.cleaning.guests = result.guests;

    result.surcharge.all_cost += result.surcharge.list.cleaning.cost;
    
    result.max_price = result.price + result.discounts.all_cost;
    result.all_day_price = result.price + result.discounts.all_cost - result.surcharge.all_cost;
    result.one_day_price = result.all_day_price / result.nights;
    result.per_day_price = (result.price - bonuses.value) / result.nights;
    result.new_pre_cost = result.pre_cost - bonuses.value;
    result.check_in_cost = result.price - result.pre_cost;
    result.total_price = result.price - bonuses.value;
    return result;
  });
}

<template>
  <span class="sutochno-icon" :class="[iconClass, { 'no-bg': noBg }]"></span>
</template>

<script setup>
import { computed } from 'vue'
import icons from './icons'

const props = defineProps({
  color: {
    type: String,
    default: 'white',
  },
  size: {
    type: Number,
    default: 12,
  },
  bgColor: {
    type: String,
    default: '#f51449',
  },
  fontSize: {
    type: Number,
    default: 10
  },
  iconType: {
    type: String,
    default: 'main',
  },
  noBg: {
    type: Boolean,
    default: false
  }
})

const iconSize = computed(() => props.size + 'px')
const iconFontSize = computed(() => props.fontSize + 'px')
const iconClass = computed(() => icons[props.iconType])

</script>

<style scoped lang="scss">
.sutochno-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: v-bind(bgColor);
  width: v-bind(iconSize);
  height: v-bind(iconSize);
  color: v-bind(color);
  border-radius: 50%;
  padding-bottom: 1px;
  margin-left: 3px;
}
.sutochno-icon:before {
  font-size: v-bind(iconFontSize);
}
.no-bg {
  background: transparent;
}
</style>
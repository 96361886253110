<i18n lang="json">
{
	"ru":{
		"defaultPlaceholder": "Введите"
	},
	"en":{
		"defaultPlaceholder": "Enter"
	}
}
</i18n>

<template>
  <div class="ui-textarea">
    <textarea
      class="ui-textarea__main"
      ref="textarea"
      rows="1"
      :class="{ 'ui-textarea__main--error': isError }"
      :placeholder="getPlaceholder"
      :value="modelValue"
      :maxlength="maxSymbols"
      @input="inputHandler($event)"
    ></textarea>
    <transition name="fade">
      <div v-if="modelValue?.length" class="ui-textarea__placeholder">
        {{ getPlaceholder }}
      </div>
    </transition>
    <button v-if="isHasRemover" class="ui-textarea__remover" @click="remove">
      <span class="icon-app-close-btn"></span>
    </button>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: "UITextarea",
  emits: ["update:modelValue", "removeTextarea"],
  props: {
    modelValue: {
      required: true,
    },
    placeholder: {
      type: String,
    },
    isHasRemover: {
      type: Boolean,
      defauld: false,
    },
    isError: {
      type: Boolean,
      defauld: false,
    },
    maxSymbols: {
      type: Number,
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    getPlaceholder() {
      if (this.placeholder) {
        return this.placeholder;
      }
      return this.t("defaultPlaceholder");
    },
  },
  mounted() {
    this.$nextTick(() => {
      const elem = this.$refs.textarea;
      elem.style.height = elem.scrollHeight + "px";
    });
  },
  methods: {
    inputHandler(event) {
      this.autoResize(event.target);
      this.$emit("update:modelValue", event.target.value);
    },
    autoResize(elem) {
      elem.style.height = 0;
      elem.style.height = elem.scrollHeight + "px";
    },
    remove() {
      this.$emit("update:modelValue", "");
      this.$emit("removeTextarea");

      const elem = this.$refs.textarea;
      elem.style.height = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-textarea {
  position: relative;
  width: 100%;
  color: var(--default-fonts-color);
  display: flex;
  align-items: center;
  background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.05) 3.92%,
      rgba(0, 0, 0, 0) 34.31%
    ),
    #f1f3fb;
  border-radius: 10px;

  &__main {
    padding: 11px 14px;
    width: 100%;
    min-height: 42px;
    background: #ffffff;
    border: 1px solid #e7e9f3;
    border-radius: 10px;
    resize: none;
    overflow: hidden;

    &--error {
      border: 1px solid #d12d1e;
    }
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: var(--grey-g5);
    }
  }

  &__remover {
    padding: 12px 6px;
    border: none;
    font-size: 0;
    border-radius: 10px;
    background: none;
    span {
      font-size: 18px;
      font-weight: 700;
    }
  }

  &__placeholder {
    padding: 0 4px;
    position: absolute;
    top: -8px;
    left: 11px;
    font-size: 12px;
    color: var(--grey-g5);
    line-height: 16px;
    transition: all ease 0.2s;
    background: #ffffff;
  }
}
</style>

import { computed } from "vue";
import { useStore } from "vuex";
import { useCurrencyCalc } from "./useCurrencyCalc";

export const useBonuses = () => {
  const store = useStore();
  const getCurrencyCalc = useCurrencyCalc();
  return computed(() => {
    // у wl не должно быть скидок
    const currencyId = Number(store.state.user.currency?.id) || 1;
    const whitelabel = store.state.user.whitelabel;
    const isLoadUserData = store.state.user.isLoadUserData;
    const price = store.getters['detail/getObjectPrice']?.price;
    if (
      price &&
      Number(getCurrencyCalc.value.id) === 1 &&
      currencyId === 1 &&
      store.state.user.isAuth &&
      !whitelabel &&
      !isLoadUserData
    ) {
      const balanceVirtual = store.state.user.userData?.balance_virtual;
      if (balanceVirtual) {
        const sale = Math.floor(price * 0.10);
        const bonus = balanceVirtual > 100000 ? 100000 : balanceVirtual;
        return Number(sale <= bonus ? sale : bonus);
      }
    }
    return 0;
  });
}

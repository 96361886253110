<i18n lang="json">
{
	"ru": {
    "landlord": "хозяин жилья"
	},
	"en": {
    "landlord": "landlord"
	}
}
</i18n>
<template>
  <div class="review">
    <div class="review__top">
      <div class="review__avatar">
        <img :src="avatar" class="avatar__reviewer">
      </div>
      <div class="review__info">
        <span class="review__name tmp-font--medium_bold">{{ name }}</span>
        <span class="review__owner">, {{t('landlord')}}</span>
      </div>
    </div>
    <div class="review__request" v-if="messageText">
      <p>{{ messageText }}</p>
    </div>
  </div>
</template>

<script>
import Rating from "@/components/base/BaseRating";
import { formatSafari } from "@/utils/format";
import { useI18n } from 'vue-i18n';
import avatarNew from "assets/img/default-avatar-new.svg";

export default {
  name: "Response",
  props: {
    review: {
      type: Object,
      default: {}
    }
  },
  components: {
    Rating
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    name() {
      if(this.review?.user?.data?.properties?.first_name?.value)
        return this.review.user.data.properties.first_name.value;
      else
        return 'Пользователь'
    },
    avatar() {
      const media = this.review?.user?.data?.media;
      if (media?.length)
        return `//static.sutochno.ru/${media[0].properties.full_path.value}small/${media[0].properties.filename.value}`
      else return avatarNew;
    },
    date() {
      return new Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
        .format(new Date(formatSafari(this.review.date_add)))
        .replace(" г.", "");
    },
    messageText() {
      const prop = this.review?.data?.properties;
      if (prop?.message_text && prop?.message_text.value !== "")
        return prop.message_text.value
      else return false;
    }
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.review {
  margin-top: 15px;
  padding-left: 55px;

  &__top{
    display: flex;
    align-items: center;
  }

  &__avatar {
    flex-basis: 30px;
    min-width: 30px;
    height: 30px;
    .avatar {
      &__reviewer {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  &__main {
    flex-grow: 1;
    margin-left: 20px;
    margin-right: 5px;
  }
  &__info {
    margin-left: 15px;
    .review__owner {
      color: #7f7f7f;
    }
  }
   &__name{
     color: #000;
   }

  &__advantages {
    color: #7f7f7f;
    margin-bottom: 5px;
  }
  &__request{
    margin-top: 15px;
    line-height: 20px;
  }
}
</style>

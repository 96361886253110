<template>
  <CalendarMobile 
    ref="calendar"
    v-model:dateViews="dateViews"
    v-model:daysCount="daysCount"
    :bookingView="bookingView"
    :cntMin="cntMin"
    :employment="employment"
    @send="getInfoPrice"
    @disabled="disabledBtn"
    @choice="$emit('choice', $event)"
    @reset="resetDates"
    @cntCheck="cntPrice"
  />
</template>

<script>
import CalendarMobile from "@/components/base/CalendarMobile.vue";
import { mapActions, mapMutations, mapGetters, mapState } from "vuex";
import axios from "axios";
import _ from "lodash";

export default {
  name: "Dates",
  components: {
    CalendarMobile
  },
  props: {
    bookingView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateViews: {
        in: null,
        out: null,
      },
      cancelToken: null,
      daysCount: 0
    }
  },
  mounted() {
    if(this.checkIn && this.checkOut) {
      this.setChangesDates({
        in: new Date(this.checkIn.setHours(0)),
        out: new Date(this.checkOut.setHours(0))
      })
    } else {
      this.setBtnDisabled(true)
    }
  },
  watch: {
    'checkOut'(val, oVal) {
      if (String(val) !== String(oVal)) {
        if(!this.isChangeForSave) {
          this.setIsChangeForSave(true)
        }
      }
    },
  },
  computed: {
    ...mapState("user", ["currency"]),
    ...mapState("detail", ["objectData", "bookingData"]),
    ...mapState("detailBooking", ["isChangeForSave"]),
    ...mapGetters("search", ["getQuery", "maxGuests"]),
    ...mapGetters("detail", ["getObjectPrice", "getObjectData", "getInstant", "getObjectPriceErrors"]),
    ...mapGetters("detailBooking", ["isBooking"]),
    ...mapState("search", ["checkIn", "checkOut"]),
    employment() {
      return this.objectData?.data?.calendar
        ? this.objectData?.data?.calendar.map((el) => {
            return {
              from: new Date(el.date_begin.replace(" ", "T")),
              to: new Date(el.date_end.replace(" ", "T")),
            };
          })
        : [];
    },
    cntMin() {
      
      // это костыль для новой струкутры ответа апи метода getPricesAndAvailabilities
      const data = this.getObjectPriceErrors;
      if (data?.length) {
        let error = data?.find((item) => {
          return item.includes("Минимальное количество ночей");
        });
        if (error) {
          error = error?.split(" ");
          return Number(error[error.length - 1]);
        }
      }
      return 1;
    },
    parseTime() {
      if(this.getObjectData) {
        const dateIn = Number(
          this.getObjectData.properties.check_in_out_rules
            ? this.getObjectData.properties.check_in_out_rules.properties
                .find((c) => c.name == "check_in")
                .value.toString()
                .match(/\d{2}/)
            : this.getObjectData.properties.additional_payment.properties
                .find((c) => c.name == "check_in")
                .value.toString()
                .match(/\d{2}/)
        );

        const dateOut = Number(
          this.getObjectData.properties.check_in_out_rules
            ? this.getObjectData.properties.check_in_out_rules.properties
                .find((c) => c.name == "check_out")
                .value.toString()
                .match(/\d{2}/)
            : this.getObjectData.properties.additional_payment.properties
                .find((c) => c.name == "check_out")
                .value.toString()
                .match(/\d{2}/)
        );

        return {
          in: dateIn ? dateIn : 14,
          out: dateOut ? dateOut : 12
        }
      } else {
        return {
          in: 14,
          out: 12
        }
      }
    },
  },
  methods: {
    ...mapActions("detail", ["setObjectPrice"]),
    ...mapActions("booking", ["updateParams", "updateOrder"]),
    ...mapMutations("detailBooking", ["setBtnLoading", "setBtnDisabled", "setIsChangeForSave", "setChangesDates"]),
    ...mapMutations("detail", ["cntObjectPriceMutate", "objectPriceMutate"]),
    ...mapMutations("booking", ["setBookingPriceData"]),
    cntPrice() {
      if (this.daysCount >= this.cntMin) {
        this.cntObjectPriceMutate();
      }
    },
    disabledBtn(e) {
      this.setBtnDisabled(e)
    },
    updateBookingDates() {
      const dateIn = new Date(new Date(this.dateViews?.in).setHours(this.parseTime.in))
      const dateOut = new Date(new Date(this.dateViews?.out).setHours(this.parseTime.out))
      this.updateParams({ 
        code: this.bookingData?.order_code, 
        date_begin: dateIn, 
        date_end: dateOut
      });
      this.updateOrder({ name: 'occupied' });
    },
    getInfoPrice() {
      if (this.dateViews?.in && this.maxGuests) {

        this.setBtnLoading(true)

        if (this.cancelToken) {
          this.cancelToken.cancel();
        }

        this.cancelToken = axios.CancelToken.source();

        this.setObjectPrice({
          guests: Number(this.maxGuests),
          date_begin: this.formatDateGetPrices(this.dateViews.in),
          date_end: this.formatDateGetPrices(
            this.dateViews.out ? this.dateViews.out : this.imitationOutDate()
          ),
          objects: Number(this.getObjectData.id),
          currency_id: this.currency.id,
          no_time: 1,
          cancelToken: this.cancelToken,
        })
          .then((response) => {
            const isHotSale = !!response[0]?.data?.object_hot?.sale;
            this.setQuery(isHotSale);
            this.setBtnLoading(false);
          })
          .catch((err) => {
            // если запрос считается отмененным мы всеровно оставляем лоадер потому что будет следующий запрос
            if(axios.isCancel(err)){
              this.setBtnLoading(true)
            } else {
              this.setBtnLoading(false)
            }
          })

        if (this.isBooking && this.dateViews?.in && this.dateViews.out) {
          this.updateBookingDates()
        }
      }
    },
    formatDateGetPrices(elem) {
      const format = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, 0);
        const day = date.getDate().toString().padStart(2, 0);
        return `${year}-${month}-${day}`;
      };
      return format(elem);
    },
    imitationOutDate() {
      return new Date(new Date(this.dateViews.in).valueOf() + 1000 * 3600 * 24);
    },
    reset() {
      this.$refs.calendar.reset()
    },
    resetDates() {
      this.objectPriceMutate(null)
      this.setBookingPriceData(null)
    },
    setQuery(is_hot_sale = false) {
      const query = {
        hash: this.getQuery.hash,
        query: _.omit(this.getQuery.query, ["lat", "lng", "zoom"]),
      };
      if (is_hot_sale) {
        query.query.burning_offer = 1;
      } else {
        delete query.query.burning_offer;
      }

      navigateTo(query);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>

<i18n lang="json">
{
	"ru": {
    "in": "Заезд",
		"btn": "Сохранить",
		"out": "Отъезд",
		"outLower": "отъезд",
		"reset": "Сбросить даты",
    "pressDate": "Введите даты поездки чтобы увидеть цены",
    "titleCalendar": "Выбор дат",
    "titleGuests": "Выбор гостей",
    "titleBooking": "Бронирование",
    "choiceOut": "Выберите дату отъезда",
    "nights": "менее суток | за сутки | за {n} суток | за {n} суток",
    "nightsMin": "менее суток | {n} сутки | {n} суток | {n} суток",
    "titleModalRequest": "Запрос на бронь отправлен",
    "textModalRequest": "Как только хозяин примет решение о бронировании, мы сразу пришлём вам уведомление на телефон.",
    "warningTitle": "Чтобы забронировать быстрее, отправьте больше запросов!",
    "warningText": "Сейчас на Суточно.ру высокий спрос на бронирования. Посмотрите другие варианты и отправьте еще несколько запросов — так вы оформите бронь гораздо быстрее",
    "send": "Отправить",
    "close": "Закрыть",
    "cancel": "Отмена",
    "tgNotice": "Подключить уведомления в Telegram",
    "attention": "Обратите внимание",
    "lookVariants": "Смотреть варианты",
    "titleRulesBooking":"Правила объекта размещения",
    "intersectText": "Вы уже отправляли запрос на бронь этого жилья. Если вы отправите новый, то старый будет отменен.",
    "datesErrorText": "К сожалению, другой гость выбрал это жильё на те же даты и внёс предоплату раньше вас. Ваш запрос аннулирован, но вы можете найти другой вариант проживания в этом же городе."
	},
	"en": {
    "in": "Check in",
    "btn": "Save",
    "out": "Departure",
    "outLower": "departure",
    "reset": "Reset dates",
    "tgNotice": "Enable notifications in Telegram",
    "pressDate": "Enter your travel dates to see prices",
    "titleCalendar": "Select dates",
    "titleGuests": "Guest Selection",
    "titleBooking": "Booking",
    "choiceOut": "Select departure date",
    "nights": "less than a day | for a day | for {n} days | for {n} days",
    "nightsMin": "less than a day | {n} days | {n} days | {n} days",
    "titleModalRequest": "Booking request sent",
    "textModalRequest": "As soon as the host decides to book, we will immediately send you a notification on your phone.",
    "warningTitle": "To book faster, send more requests!",
    "warningText": "Now Sutochno.ru has a high demand for bookings. Check out other options and send a few more inquiries to make your reservation much faster.",
    "lookVariants": "look variants",
    "send": "Send",
    "close": "Close",
    "cancel": "Cancel",
    "attention": "Pay attention",
    "titleRulesBooking": "Object's accommodation Rules",
    "intersectText": "You have already submitted a reservation request for this accommodation. If you submit a new one, the old one will be canceled.",
    "datesErrorText": "Unfortunately, another guest chose this accommodation for the same dates and made an advance payment before you. Your request has been cancelled, but you can find another place to stay in the same city."
	}
}
</i18n>
<template>
  <div>
    <Modal
      ref="modalBooking"
      :modelValue="isModalOpen"
      theme="light"
      :title="modalTitle"
      class="booking__modal"
      :class="modalClasses"
      :imitation-close="true"
      @imitationClose="closeModal()"
    >
      <template v-if="modalStep === 'calendar'" #side-action>
        <div
          v-show="isSelectedDate"
          class="booking-datepicker__reset"
          @click="reset"
        >
          {{ t("reset") }}
        </div>
      </template>
      <template v-if="modalStep === 'calendar'">
        <Dates ref="calendar" @choice="isSelectedDate = $event" />
      </template>
      <ModalStepGuests v-if="modalStep === 'guests'" />
      <ModalStepBooking v-if="modalStep === 'booking'" />
      <RulesBookingBody
        v-if="modalStep === 'rules-booking'"
        :isGrandtrain="isGrandtrain"
        :isCheckGrandtrain="isCheckGrandtrain"
        v-model="isCheckGrandtrain"
      />
      <template #footer>
        <RulesBookingFooter
          v-if="modalStep === 'rules-booking'"
          @showModal="showModal"
          @errorsDates="errorsDates"
          @intersect="intersect"
          @sendGrandtrain="sendGrandtrain"
        />
        <ButtonSwitch
          v-else
          @clickSave="clickBtnSwitch()"
          :is-step="true"
          :precost="precost"
          :price="price"
          :sale="sale"
          :old-price="oldPrice"
        />
      </template>
    </Modal>
    <TeleportTarget id="modal-code" />
    <div id="always-booked" />

    <ModalLight
      v-if="showModalWarning"
      icon-type="pending"
      :title="t('attention')"
      :default-text="textModalWarning"
      :buttons="[
        {
          type: 'primary',
          name: intersectVal ? t('send') : t('lookVariants'),
        },
        {
          type: 'secondary',
          name: t('cancel'),
        },
      ]"
      @onClose="showModalWarning = false"
      @onSecondary="showModalWarning = false"
      @onPrimary="switchAction"
    />

  </div>
</template>

<script>
import Modal, { ModalLight } from "@sutochno/modal";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { isNavigationFailure } from "vue-router";
import _ from "lodash";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { format } from "@/utils/format";
import ButtonSwitch from "./ButtonSwitch";
import ModalStepGuests from "./ModalSteps/Guests";
import Dates from "./ModalSteps/Dates";
import RulesBookingBody from "@/components/mobile/Detail/Booking/ModalSteps/RulesBooking/Body.vue";
import RulesBookingFooter from "@/components/mobile/Detail/Booking/ModalSteps/RulesBooking/Footer.vue";
import ModalWarning from "@/components/mobile/Detail/Booking/ModalWarning.vue";
import ModalRequestSent from "./ModalSteps/RulesBooking/ModalRequestSent";
import ModalStepBooking from "./ModalSteps/Booking";
import Objects from "@/components/api/objects/";
import { pushDataToYM } from "@/utils/yandexMetrica";
import dataLayerPushGA4 from "@/mixins/dataLayerPushGA4.js";
import { useI18n } from 'vue-i18n';
import getTermSuggestionsWithBoundingBox from "@/components/api/search/getTermSuggestionsWithBoundingBox";

export default {
  name: "Booking",
  mixins: [dataLayerPushGA4],
  components: {
    ModalLight,
    Modal,
    Dates,
    ButtonSwitch,
    ModalStepGuests,
    RulesBookingBody,
    RulesBookingFooter,
    ModalWarning,
    ModalRequestSent,
    ModalStepBooking,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      isSelectedDate: false,
      showModalWarning: false,
      textModalWarning: "",
      intersectVal: false,
      showModalSend: false,
      isGrandtrain: false,
      isCheckGrandtrain: true,
      regionId: null,
      openOldBooking: false,
      coords: null,
    };
  },
  props: {
    price: {
      type: [String, Number],
      default: "0 Р",
    },
    precost: {
      type: [String, Number],
      default: "0 Р",
    },
    sale: {
      type: Number,
      default: 0,
    },
    oldPrice: {
      default: 0,
    },
  },
  computed: {
    ...mapGetters("search", ["maxGuests", "getQuery"]),
    ...mapGetters("booking", ["params", "bookingPriceError"]),
    ...mapGetters("detailBooking", [
      "modalStep",
      "isModalOpen",
      "isBooking",
      "isPhoneCorrect",
      "btnDisabled",
      "petsObject",
    ]),
    ...mapState("search", ["checkIn", "checkOut", "guests", "filters"]),
    ...mapState("user", ["isAuth", "currency", "userData"]),
    ...mapState("detailBooking", ["isChangeForSave", "isChangeForSaveGuests"]),
    ...mapGetters("detail", [
      "getObjectData",
      "getObjectPriceErrors",
      "getDataForYM",
    ]),
    isTelegramContactExists() {
      if (
        !this.userData?.user?.contacts &&
        !this.userData?.user?.contacts?.length
      ) {
        return false;
      }
      const { contacts } = this.userData.user;
      return contacts.find((contact) => Number(contact["type_id"]) === 12);
    },
    calendarTitle() {
      return this.isSelectedDate ? "" : this.t("titleCalendar");
    },
    modalTitle() {
      switch (this.modalStep) {
        case "calendar":
          return this.calendarTitle;
        case "rules-booking":
          return this.t("titleRulesBooking");
        case "guests":
          return this.t("titleGuests");
        case "booking":
        default:
          return this.t("titleBooking");
      }
    },
    modalClasses() {
      return {
        "booking__modal-calendar": this.modalStep === "calendar",
        "booking__modal-guests": this.modalStep === "guests",
        "booking__modal-booking": this.modalStep === "booking",
      };
    },
  },
  watch: {
    isAuth(val) {
      if (val) {
        this.initMobiles();
      }
    },
    bookingPriceError(val) {
      if (val.length && !this.btnDisabled) this.setBtnDisabled(true);
    },
    "getObjectData.id"() {
      this.getObjectLocation();
    },
    regionId(val) {
      if (val === 19888) {
        this.isGrandtrain = true;
      }
    },
  },
  created() {
    this.getObjectLocation();
  },
  mounted() {
    if (!this.checkIn && !this.checkOut) {
      sendEventGtag("dates_sa", {
        click: "object_dates_no",
      });
    } else {
      sendEventGtag("dates_sa", {
        click: "object_dates_yes",
      });
    }
    if (this.isAuth) {
      this.initMobiles();
    };
    localStorage.setItem("query", JSON.stringify({
      ...this.getQuery.query,
      term: this.getObjectData.location.location,
      id: this.getObjectData.location.city.id,
      type: 'city',
    }));
  },
  methods: {
    ...mapMutations("detail", { setBookingDataMutations: "setBookingData" }),
    ...mapActions("detail", ["setObjectPrice"]),
    ...mapMutations("modal", ["setModalType"]),
    ...mapMutations("detailBooking", [
      "setModalStep",
      "setIsModalOpen",
      "setBtnDisabled",
      "setBookingIsModalCode",
      "updateBookingError",
      "setIsPets",
      "setPets",
      "setIsChangeForSave",
      "setIsChangeForSaveGuests",
      "setOpenDetailGuestsModal",
      "setBtnLoading"
    ]),
    ...mapActions("booking", ["getMobiles", "updateParams", "updateOrder", "setParams", "bookingToAction"]),
    ...mapActions("detailBooking", ["routeBooking", "updateSelectedPhone"]),
    ...mapActions("search", ["updateAdults", "updateChildrens", "setLocation"]),
    switchAction() {
      if(this.intersectVal) {
        this.cancelAndContinue();
        return
      }
      this.seeOptions();
    },
    openTgWindow() {
      this.$gtag.event("TG_notification_sub", {
        click: "booking_request",
      });
      window.open("https://t.me/sutochnoru_bot", "_blank");
    },
    reset() {
      sendEventGtag("draft_sa_m", {
        click: "btn_date_clear",
      });
      this.$refs.calendar.reset();
      if (this.$route.params.id) {
        this.setObjectPrice({
          guests: Number(this.maxGuests),
          date_begin: this.formatDate(),
          date_end: this.formatDate("next"),
          objects: Number(this.$route.params.id),
          currency_id: this.currency.id,
        });
      }
    },
    formatDate(type = "") {
      const date = new Date();
      if (type === "next") {
        date.setDate(date.getDate() + 1);
      }
      return format(new Date(date));
    },
    closeModal() {
      this.setBtnDisabled(false);

      if (this.modalStep === "calendar") {
        sendEventGtag("draft_sa_m", {
          click: "btn_date_close",
        });
      } else if (this.modalStep === "guests") {
        sendEventGtag("draft_sa_m", {
          click: "btn_guests_close",
        });
      } else if (this.modalStep === "rules-booking") {
        sendEventGtag("draft_sa_m", {
          click: "order_rules_cross",
        });
      }

      if (this.isBooking && this.modalStep !== "booking") {
        if (this.modalStep === "guests" && this.params) {
          const ADULTS = this.params.guests.filter((c) => c.type === 0).length;
          const CHILDRENS = this.params.guests
            .filter((c) => c.type === 3)
            .map((c) => c.age);
          this.updateAdults(ADULTS);
          this.updateChildrens(CHILDRENS);
          this.setIsPets(this.params.is_pets !== 0 ? true : false);
          this.setPets(this.params.pets);
        }
        let error = this.getObjectPriceErrors?.find((item) => {
          return item.includes("Минимальное количество ночей");
        });
        if (
          this.modalStep === "calendar" &&
          (!this.checkIn || !this.checkOut || error?.length)
        ) {
          this.setIsModalOpen(false);
          this.setModalStep("calendar");
        } else {
          this.setModalStep("booking");
        }
      } else {
        this.setIsModalOpen(false);
        switch (this.modalStep) {
          case "calendar":
            this.setModalStep("calendar");
            break;
          case "guests":
            if (!this.isChangeForSave) {
              this.setIsChangeForSave(true);
            }
            this.setModalStep("booking");
            break;
          case "rules-booking":
            this.setModalStep("booking");
            break;
          case "booking":
            this.setBookingDataMutations(null);
            this.dataLayerPush("add_to_cart");
          default:
            break;
        }
      }
    },
    async clickBtnSwitch() {
      if (this.isBooking && this.modalStep !== "booking") {
        if (this.modalStep === "guests" && this.params) {
          const CHILDRENS = this.guests.childrens.map((c) => {
            return { type: 3, age: c };
          });
          let adults = [];
          let adultsCounter = this.guests.adults;
          while (adultsCounter) {
            adults.push({ type: 0 });
            adultsCounter--;
          }
          if (!this.petsObject.isPets) this.setPets("");
          this.updateParams({
            guests: [...adults, ...CHILDRENS],
            is_pets: this.petsObject.isPets ? 1 : 0,
            pets: this.petsObject.pets,
          });

          if (!this.isChangeForSaveGuests) {
            this.setIsChangeForSaveGuests(true);
          }

          await this.updateOrder({
            name: "guests",
          });
          await this.updateOrder({
            name: "is_pets",
          });
          await this.updateOrder({
            name: "pets",
          });
        }
        this.setModalStep("booking");
      } else {
        switch (this.modalStep) {
          case "calendar":
            this.setModalStep("guests");
            sendEventGtag("draft_sa_m", {
              click: "btn_guests_open_next",
            });
            break;
          case "guests":
          case "rules-booking":
            this.setIsModalOpen(false);
            this.setModalStep("booking");
            break;
          case "booking":
            if (
              this.guests?.pets?.value &&
              this.guests?.pets?.description === ""
            ) {
              this.setModalStep("guests");
              this.setOpenDetailGuestsModal(true);
              return false;
            }
            //блок с логикой перенаправления
            if (this.isAuth && this.isPhoneCorrect) {
              this.routeBooking()
                .then((res) => {
                  const dataForYM = this.getDataForYM("add", this.$route.name);
                  pushDataToYM(dataForYM);
                  if (res?.data?.data?.intersect) {
                    this.setModalStep("booking");
                    this.intersectVal = true;
                  }
                  if (res?.data?.errors?.dates) {
                    this.intersectVal = false;
                    this.errorsDates(res?.data?.errors?.dates);
                  }
                  if (
                    res?.data?.errors &&
                    Object.keys(res.data.errors)?.length
                  ) {
                    this.intersectVal = false;
                    this.errorsDates(res?.data?.errors[0]);
                  }
                })
                .catch((err) => {
                  console.warn(err);
                  // VUECLIENT-819
                  // Миша сказал перенаправлять при всех ошибках
                  // в routeBooking есть throw
                  this.$router
                    .push({
                      name: "Booking",
                      params: { orderCode: this.params.code },
                    })
                    .then((failure) => {
                      if (isNavigationFailure(failure)) {
                        this.$router.push({
                          name: "Booking",
                          params: { orderCode: this.params.code },
                        });
                      }
                    });
                  setTimeout(() => {
                    this.setIsModalOpen(false);
                  }, 1000);
                });
            } else if (this.isPhoneCorrect) {
              //если не авторизован показываем мини модалку с кодом
              this.setBookingIsModalCode(true);
            } else {
              this.updateBookingError({ name: "phone", value: true });
            }
          default:
            break;
        }
      }
    },
    showModal() {
      this.showModalSend = true;
    },
    errorsDates(error) {
      this.setModalStep("booking");
      this.showModalWarning = true;
      this.textModalWarning = error;
      this.intersectVal = false;
    },
    async cancelAndContinue() {
      // this.showModalSend = true;
      this.showModalWarning = false;
      this.setBtnLoading(true);
      const response = await this.setParams({ draftCode: this.params.code })
      await this.bookingToAction({ order_code: response.data.code, no_matter: 1 })
      this.setBtnLoading(false);
      await this.seeOptions();
    },
    intersect() {
      this.setModalStep("booking");
      this.showModalWarning = true;
      this.intersectVal = true;
      this.textModalWarning = this.t("intersectText");
    },
    closeModalRequestSent() {
      this.setModalType("ModalRequest");
      this.setModalStep("booking");
      this.setIsModalOpen(false);
      this.$router.push({
        name: "Detail",
        params: { id: this.getObjectData.id },
      });
    },
    async pushToRoute(route = "Search", query = {}) {
      try {
        await this.$router.push({
          name: route,
          query,
          meta: {
            // ignoreRouterHook: true
          }
        });
      } catch (e) {
        console.log(e, "error from pushToRoute");
        await this.$router.push({
          name: route,
          query,
          meta: {
            // ignoreRouterHook: true
          }
        });
      }
    },
    async seeOptions() {
      const queryParams = JSON.parse(localStorage.getItem("query"));
      try {
        this.showModalSend = false;
        this.setBtnLoading(true);
        const { data } = await getTermSuggestionsWithBoundingBox(queryParams?.term)
        this.coords = data?.data?.suggestions?.at(0) || {};
        // this.setModalStep("booking");
        const query = {
          ...queryParams,
          'NE.lat': this.coords?.bbox?.NE?.lat,
          'NE.lng': this.coords?.bbox?.NE?.lng,
          'SW.lat': this.coords?.bbox?.SW?.lat,
          'SW.lng': this.coords?.bbox?.SW?.lng,
        }
        await this.setLocation(query);
        this.setBtnLoading(false);
        this.setModalType("ModalRequest");
        await this.pushToRoute("Search", query);
      } catch (e) {
        console.warn('Ошибка получения координат объекта: ', e);
        this.setBtnLoading(false);
        this.setIsModalOpen(false);
        this.setModalType("ModalRequest");
        const query = {
          ...queryParams,
          'NE.lat': this.coords?.bbox?.NE?.lat,
          'NE.lng': this.coords?.bbox?.NE?.lng,
          'SW.lat': this.coords?.bbox?.SW?.lat,
          'SW.lng': this.coords?.bbox?.SW?.lng,
        }
        await this.pushToRoute("Search", query);
      }
    },
    initMobiles() {
      this.getMobiles().then((response) => {
        if (response.length) {
          this.updateSelectedPhone(Number(response[0].match(/\d/g).join("")));
        }
      });
    },
    open_hidden_tab(new_url) {
      let thisTimeout = setTimeout(function () {
        window.location.href = new_url;
      }, 500);

      const query = _.pickBy(this.getQuery.query);
      const params = Object.entries(query)
        .map((v) => v.join("="))
        .join("&");

      const url = `${window.location.protocol}//${window.location.host}/front/searchapp/search?${params}`;
      const newWindow = window.open(url);
      if (!newWindow) {
        clearTimeout(thisTimeout);
      }
    },
    sendGrandtrain() {
      if (this.regionId === 19888) {
        if (this.isCheckGrandtrain) {
          sendEventGtag("search_sa_m", {
            load: "grandtrain_open",
          });
          setTimeout(() => {
            this.open_hidden_tab("https://grandtrain.ru/?utm_source=sutochno");
          }, 100);
        } else {
          sendEventGtag("search_sa_m", {
            load: "grandtrain_no_open",
          });
        }
      }
    },
    async getObjectLocation() {
      if (this.getObjectData && this.getObjectData.id) {
        this.regionId = await Objects.getObjectLocation(this.getObjectData.id)
          .then(
            (response) =>
              Number(response.data.data?.location?.region?.id) || NaN
          )
          .catch(console.error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$padding: 20px;

.modal-new {
  &__icon {
    display: block;
    width: 66px;
    height: 66px;
    background-size: cover;
    background-repeat: no-repeat;
    &_warning {
      background-image: url("/assets/img/icon_modal_warning.svg");
    }
    &_request {
      background-image: url("/assets/img/icon_modal_request.svg");
    }
  }
}

.booking {
  &-datepicker {
    &__reset {
      position: absolute;
      top: 0px;
      right: 10px;
      // color: white;
      z-index: 201;
      font-size: 14px;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      -webkit-user-select: none;
      user-select: none;
    }
  }

  &__modal {
    :deep() {
      .sc-modal {
        &-footer {
          padding: 15px $padding calc(15px + env(safe-area-inset-bottom));
          box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
:deep() {
  .booking {
    &__header {
      position: sticky;
      top: 0;
      padding: $padding;
      background: #fff;
      z-index: 10;

      &-text {
        margin-top: 10px;
      }
    }
    &__body {
      padding: $padding;
      padding-top: 0;
    }
  }
}

.modal-request {
  &__content {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 410px;
    row-gap: 20px;
  }

  &__heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin: 0;
  }

  &__tg-btn {
    width: 100%;
    text-align: center;
    padding: 14px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #ffffff;
    border: none;
    background-color: #25a0dd;
    border-radius: 14px;
  }

  &__book-more {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 16px;
    padding: 20px;
    background-color: #f1f3fb;
    border-radius: 14px;
    .book-more {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      row-gap: 14px;
      &__text {
        color: #252525;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
        &_bold {
          color: #1c1c1c;
          font-weight: 500;
          font-size: 16px;
        }
      }

      &__btn {
        display: inline-block;
        padding: 12px 22px;
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background-color: transparent;
        color: #1c1c1c;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
}
</style>

<i18n lang="json">
{
  "ru": {
    "deposit": "страховой депозит (возращается при отъезде)",
    "documents_0": "владелец не предоставляет отчетные документы о проживании",
    "documents_1": "владелец предоставляет отчетные документы о проживании",
    "documents_2": "владелец предоставляет отчетные документы о проживании по согласованию",
    "depositHint": "Залог за сохранность имущества. Хозяин может взять его при заселении и вернуть при отъезде, если ничего не испорчено.",
    "is_children": {
      "0": "нельзя с детьми",
      "1": {
        "0": "можно с детьми любого возраста",
        "1": "можно с детьми от 1 года и старше",
        "2": "можно с детьми от {age} лет и старше"
      },
      "2": "можно с детьми по согласованию с хозяином жилья"
    },
    "is_children_free_babies": "нельзя с детьми до {age} лет, но можно с детьми 1 года и младше",
    "free_babies": "дети 1 года и младше размещаются бесплатно, но без дополнительного спального места",
    "rules": {
      "min_nights": "Сдаётся на срок от {n} суток",
      "smoking": {
        "0": "курение запрещено",
        "1": "курение разрешено",
        "3": "курение разрешено в специально отведенных местах"
      },
      "pets": {
        "0": "нельзя с питомцами",
        "1": "можно с питомцами",
        "2": "можно с питомцами по согласованию с хозяином жилья"
      },
      "party": {
        "0": "без вечеринок и мероприятий",
        "1": "можно проводить вечеринки и мероприятия",
        "2": "вечеринки и мероприятия по согласованию с хозяином жилья"
      },
      "isolation": {
        "1": "Cамоизоляция разрешена"
      }
    },
    "note": "Обратите внимание!",
    "code": "{n}",
    "rentPeriod": "сдаётся на срок от {n} суток",
    "calculationMade": "Расчет при заселении с арендодателем будет производиться в",
    "prepaymentPrompt": "Вы внесёте предоплату через Суточно.ру в рублях по курсу на {n}. Оставшуюся сумму нужно отдать хозяину при заселении — возможен расчёт в национальной валюте страны, в которой находится объект (по курсу на момент заселения).",
    "hotelCleaningCostHint": "Плата за уборку не входит в стоимость проживания — вам нужно дополнительно оплатить уборку при заселении."
	},
	"en": {
    "deposit": "insurance deposit (refunded upon eviction)",
    "documents_0": "the owner does not provide accounting documents about the residence",
    "documents_1": "the owner provides accounting documents about the residence",
    "documents_2": "the owner provides accounting documents on residence as agreed",
    "depositHint": "Security deposit. The owner can take it upon check-in and return it upon departure, if nothing is spoiled.",
    "is_children": {
      "0": "not with children",
      "1": {
        "0": "possible with children of any age",
        "1": "possible with children from 1 year old and older",
        "2": "it is possible with children from {age} years old and older"
      },
      "2": "it is possible with children in agreement with the landlord"
    },
    "is_children_free_babies": "not allowed with children under {age} years old, but allowed with children 1 year and younger",
    "free_babies": "children 1 year and younger are accommodated free of charge, but without an extra bed",
    "rules": {
      "smoking": {
        "0": "no smoking",
        "1": "smoking allowed",
        "3": "smoking is allowed in designated areas"
      },
      "pets": {
        "0": "pets are not allowed",
        "1": "pets allowed",
        "2": "it is possible with pets in agreement with the landlord"
      },
      "party": {
        "0": "no parties and events",
        "1": "parties allowed",
        "2": "parties and events as agreed with the host"
      },
      "isolation": {
        "1": "Self-isolation allowed"
      }
    },
    "note": "Note!",
    "code": "{n}",
    "calculationMade": "Settlement with the landlord will be made in",
    "prepaymentPrompt": "You will make a prepayment via Sutochno.ru in rubles at the currency rate of {n}. The remainder is to be paid to the owner at the moment of your check-in. The payment is possible in the local currency of the country in which the object is located (at the rate at the time of check-in).",
    "hotelCleaningCostHint": "The cleaning fee is not included in the room rate - you need to pay an additional cleaning fee upon check-in."
	}
}
</i18n>
<template>
  <div v-if="rules.length > 0 || deposit" class="rules">
    <div v-if="rules.length > 0" class="rules__list">
      <template v-for="rule in rules" :key="rule.name">
        <p class="rules__item" v-if="rule.name === 'min_nights'">
          {{ t(`rules.${rule.name}`, rule.value) }}
        </p>
        <p class="rules__item" v-if="children && rule.name === 'children'">
          {{ children }}
        <template v-if="isFreeBabies">
          <br>
          {{ t('free_babies') }}
        </template>
        </p>
        <p
          class="rules__item"
          v-if="rule.name !== 'min_nights' && rule.name !== 'children'"
        >
          {{ t(`rules.${rule.name}.${Number(rule.value)}`) }}
        </p>
      </template>
    </div>
    <p class="rules__item" v-if="documents">
      {{ t(`documents_${documents.value}`) }}
    </p>
    <div v-if="deposit" class="rules__deposit">
      <div class="deposit">
        <p class="deposit__title">{{ formatNumber(deposit.value) }}</p>
        <p class="deposit__text">{{ t("deposit") }}</p>
        <VDropdown
          theme="tooltip"
          class="bonus__popover"
          distance="10"
          placement="top"
          :triggers="['hover', 'click']"
          popper-class="v-popper__popper--max-width-300"
        >
          <span class="icon-app-question"></span>
          <template #popper>
            <div class="tooltip-text">
              <p class="text-left" v-html="t('depositHint')"></p>
            </div>
          </template>
        </VDropdown>
      </div>
    </div>
    <div class="rules-payment" v-if="priceCurrencyId && originalCurrencyId != priceCurrencyId">
      <div class="rules-payment__text">
        {{ t("note") }}
      </div>
      <p class="rules-payment__description">
        {{ t("calculationMade") }}
        <span class="rules-payment__code">{{
          t("code", { n: originalCurrencyCode })
        }}</span>
        <VDropdown
          class="rules-payment__popover"
          distance="5"
          placement="top"
          :triggers="['click']"
          popper-class="v-popper__popper--max-width-300"
        >
          <span class="ico-question">?</span>
          <template #popper>
            <div class="tooltip-text">
              <p class="text-left" v-html="popoverText"></p>
              <div v-close-popper class="tooltip-close"></div>
            </div>
          </template>
        </VDropdown>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { useI18n } from 'vue-i18n';

export default {
  name: "Rules",
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  computed: {
    ...mapGetters("detail", [
      "getObjectData",
      "getObjectOriginalCurrency",
      "getObjectPrice",
      "getHotelData",
      "getObjectProperties",
      "getGuestRules"
    ]),
    children() {
      const properties =
        this.getObjectData?.properties?.accommodation_rules?.properties;
      if (properties) {
        const is_children = properties.find(
          (item) => item.name === "is_children"
        );
        const children_age = properties.find(
          (item) => item.name === "children_age"
        );
        if (is_children?.value === 1) {
          if (children_age?.value <= 2 && this.isFreeBabies) {
            return this.t('is_children.1.0');
          } else if (children_age?.value > 2 && this.isFreeBabies) {
            return this.t('is_children_free_babies', {
              age: children_age.value,
            });
          } else if (children_age?.value <= 1) {
            return this.t(
              `is_children.${is_children.value}.${children_age.value}`
            );
          } else {
            return this.t(`is_children.1.2`, {
              age: children_age.value,
            });
          }
        } else if (is_children?.value === 0 || is_children?.value === 2) {
          return this.t(`is_children.${is_children.value}`);
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isFreeBabies() {
      // const properties = this.rules?.properties?.accommodation_rules?.properties;
      // const isFreeBabiesValue = properties?.find(item => item.name === 'free_babies')?.value;
      // return isFreeBabiesValue === "false" ? false : Boolean(isFreeBabiesValue);
      return this.getGuestRules?.freeBabies;
    },
    rules() {
      const ACC_RULES = ["smoking", "pets", "party", "isolation", "min_nights"];

      const pickedProps = _.pick(this.getObjectProperties, [
        "pay_attention",
        "accommodation_rules",
        "additional_payment",
      ]);

      let n = 2;

      const result = Object.values(pickedProps).reduce(
        (previousValue, currentValue) => {
          const { properties } = currentValue;
          const target = properties?.filter((prop) => {
            return ACC_RULES.includes(prop.name);
          });
          return [...previousValue, ...target];
        },
        []
      );

      if (this.children) {
        result.push({ name: "children", value: this.children });
      }

      const finalRules = result.map((rule) => {
        if (rule.name === "min_nights") {
          return { ...rule, value: rule.value / 24, pos: 1 };
        }
        if (rule.name === "children") {
          return { ...rule, pos: 2 };
        }
        return { ...rule, pos: ++n };
      });

      return finalRules.sort((a, b) => a.pos - b.pos);
    },
    documents() {
      if (this.getObjectData?.properties?.documents_rules?.properties) {
        let documents =
          this.getObjectData?.properties?.documents_rules?.properties.find(
            (item) => item.name === "documents"
          );
        return documents ? documents : false;
      } else {
        return false;
      }
    },
    deposit() {
      const findProp =
        this.getObjectData?.properties?.additional_payment?.properties?.find(
          (item) => item.name === "deposit"
        );
      if (!findProp || (findProp && !findProp.value)) return false;
      return findProp;
    },
    popoverText() {
      let now = new Date().toLocaleString("ru", {
        month: "long",
        day: "numeric",
      });

      return this.t("prepaymentPrompt", { n: now });
    },
    originalCurrencyId() {
      return this.getObjectOriginalCurrency?.id || null;
    },
    originalCurrencyCode() {
      return this.getObjectOriginalCurrency?.code || null;
    },
    priceCurrencyId() {
      return this.getObjectPrice?.currency?.id || null;
    },
    objectCurrencyCode() {
      return this.getObjectPrice?.currency?.code || "RUB";
    },
  },
  methods: {
    formatNumber(num, currency) {
      return new Intl.NumberFormat("ru-Ru", {
        style: "currency",
        currency: currency ? currency : this.originalCurrencyCode,
        minimumFractionDigits: 0,
      }).format(num);
    },
    checkRule(rule) {
      if (!rule) return false;
      else if (rule.name === "isolation" && Number(rule.value) === 0)
        return false;
      else if (rule.name === "smoking") {
        if (rule.value === 2 || rule.value > 3) return false;
        else return true;
      } else return rule.value < 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.rules {
  & > div {
    &:nth-child(2n) {
      margin-top: 20px;
    }
  }
  &__item {
    color: #444;
    margin-top: 15px;
    text-transform: lowercase;
    &:first-child {
      margin-top: 0;
    }
  }
  &__deposit {
    margin-top: 20px;
    .deposit {
      display: flex;
      align-items: center;
      padding: 15px 10px;
      background-color: #f6f6f6;
      &__title {
        flex-basis: 30%;
        font-weight: 800;
        line-height: 14px;
        color: #000;
      }
      &__text {
        margin-top: 0;
        margin-right: 10px;
        margin-left: 10px;
        flex-basis: 70%;
        line-height: 14px;
        color: #000;
      }
    }
  }
}

.rules-payment {
  margin: 15px 0 10px 0;
  display: flex;
  flex-direction: column;
  position: relative;

  &__text {
    font-weight: 500;
    margin-right: 20px;
    margin-bottom: 5px;
  }

  &__description {
    margin: 0;
    max-width: 80%;
    line-height: 1.4;
  }

  .ico-question {
    cursor: pointer;
    border: 1px solid #000;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin: 0 0 0 2px;
    font-size: 10px;
  }

  &__popover {
    position: relative;
    top: -1px;
    display: inline-block;
    margin-left: 4px;
  }
}
</style>

<i18n lang="json">
{
	"ru": {
    "allComfort": "Все удобства",
    "allComfortHotel": "Все удобства в номере",
    "comfort": "Удобства",
    "bathroom": "Ванная комната",
    "kitchen": {
      "title": "Кухня",
      "1": "отдельная",
      "2": "совмещена с гостиной",
      "3": "есть кухонная зона (без отдельной кухни)"
    },
    "transferTitle":"Трансфер",
    "transferDefaultDesc": "По договоренности с хозяином жилья",
    "decoration": {
      "title": "Отделка",
      "1":"косметический ремонт",
      "2":"евроремонт",
      "3": "дизайнерский ремонт",
      "4": "современный ремонт",
    },
    "parking": {
      "parking": {
        "1": "оплачивается отдельно",
        "2": "без оплаты",
        "3": "нет парковки"
      },
      "is_p_covered": {
        "0": "открытая",
        "1": "крытая"
      },
      "is_p_private": {
        "0": "общественная",
        "1": "частная"
      },
      "is_p_fenced": {
        "0": "не огорожена",
        "1": "огорожена"
      },
      "is_p_video_surveilla": {
        "0": "нет видеонаблюдения",
        "1": "есть видеонаблюдение"
      },
      "is_p_security": {
        "0": "нет охраны",
        "1": "есть охрана"
      },
      "is_p_on_site": {
        "0": "за территорией объекта {distance}",
        "1": "на территории объекта"
      },
      "distance_to_p": {
        "5": "до 5 мин пешком",
        "10": "до 10 мин пешком",
        "15": "до 15 мин пешком",
        "20": "более 15 мин пешком"
      },
      "parking_cost_1": {
        "1": "стоимость: {cost} в сутки",
        "2": "стоимость: {cost} за период проживания",
        "3": "стоимость: {cost} в час",
        "default": "стоимость: {cost}"
      },
      "is_p_booking": {
        "0": "парковочное место нельзя забронировать заранее",
        "1": "парковочное место можно забронировать заранее"
      }
    }
	},
	"en": {
    "allComfort": "All comfort",
    "allComfortHotel": "All comfort in hotel room",
    "comfort": "Comfort",
    "bathroom": "Bathroom",
    "transferTitle":"Transfer",
    "transferDefaultDesc": "By agreement with the landlord",
    "decoration": {
      "title": "Decoration",
      "1":"redecorating",
      "2":"capital renovation",
      "3": "designer renovation",
      "4": "modern renovation",
    },
    "parking": {
      "parking": {
        "1": "paid separately",
        "2": "without payment",
        "3": ""
      },
      "is_p_covered": {
        "0": "open",
        "1": "covered"
      },
      "is_p_private": {
        "0": "public",
        "1": "private"
      },
      "is_p_fenced": {
        "0": "not fenced",
        "1": "fenced"
      },
      "is_p_video_surveilla": {
        "0": "no video surveillance",
        "1": "there is video surveillance"
      },
      "is_p_security": {
        "0": "no security",
        "1": "there is security"
      },
      "is_p_on_site": {
        "0": "outside the facility ({distance})",
        "1": "on site"
      },
      "distance_to_p": {
        "5": "up to 5 minutes on foot",
        "10": "up to 10 minutes on foot",
        "15": "up to 15 minutes on foot",
        "20": "more than 15 minutes on foot"
      },
      "parking_cost_1": {
        "1": "cost: {cost} per day",
        "2": "cost: {cost} per stay",
        "3": "cost: {cost} per hour",
        "default": "cost: {cost}"
      },
      "is_p_booking": {
        "0": "parking space cannot be reserved in advance",
        "1": "parking space can be reserved in advance"
      }
    }
	}
}
</i18n>
<template>
  <div class="comfort">
    <div class="comfort__main">
      <ul v-if="propertyMain && propertyMain.properties.length" class="sub-comfort">
        <li
          v-for="(prop, i) in propertyMain.properties"
          class="sub-comfort__item"
          :key="i"
        >
          <span>{{ prop.title }}</span>
        </li>
      </ul>
      <div v-if="propertyBathСnt || propertyBathroom" class="comfort__bathroom">
        <p class="bathroom__title tmp-font--medium_bold">
          {{ t("bathroom") }}
        </p>
        <div v-if="propertyBathСnt" class="bathroom__cnt">
          <p
            v-for="(prop, index) in propertyBathСnt"
            class="bathroom__line"
            :key="index"
          >
            <span>{{ prop.title }}</span>
            <span>{{ prop.value }}</span>
          </p>
        </div>
        <div v-if="propertyBathroom" class="bathroom__str">
          <span v-if="propertyBathroom">{{ propertyBathroom }}</span>
        </div>
        <div 
          v-if="kitchenType && kitchenType.value > 0"
          class="comfort__bathroom"
        >
          <p class="bathroom__title tmp-font--medium_bold">
            {{ t("kitchen.title") }}
          </p>
          <div class="bathroom__str">
            <span v-if="propertyBathroom">{{ t(`kitchen.${kitchenType.value}`) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="hasProperties"
      class="comfort__button"
    >
      <button v-if="isHotel" class="button button_w-100" @click="comfortShow()">
        {{ t("allComfortHotel") }}
      </button>
      <button v-else class="button button_w-100" @click="comfortShow()">
        {{ t("allComfort") }}
      </button>
    </div>
    <Modal
      v-if="hasProperties"
      ref="modal"
      v-model="comfortView"
      theme="light"
      :title="t('comfort')"
    >
      <div class="comfort__modal">
        <ul class="comfort__list">
          <li v-for="prop of propertiesArray" :key="prop.key" class="comfort__item">
            <div class="comfort__title">
              <span>{{ prop.title }}</span>
              <span
                class="b-info-item-label"
                v-if="prop.key === 'parking' && properties.parking?.label"
                :data-label="properties.parking?.label"
              ></span>
            </div>
            <ul class="sub-comfort" v-if="prop.properties">
              <template v-if="prop.key === 'parking'">
                <li
                  v-for="(subProp, k) in prop.properties"
                  :key="k"
                  class="sub-comfort__item"
                >
                  <span>{{ subProp.title }}</span>
                </li>
              </template>
              <template v-else>
                <li
                  v-for="(subProp, k) in prop.properties"
                  :key="k"
                  class="sub-comfort__item"
                >
                  <span>{{
                    subProp.name === "transfer_description"
                      ? subProp.value
                      : subProp.title
                  }}</span>
                  <span
                    v-if="prop.key == 'bathroom' && typeof subProp.value !== 'boolean'"
                    >&nbsp;{{ subProp.value }}</span
                  >
                </li>
              </template>
            </ul>
          </li>
        </ul>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import Modal from "@sutochno/modal";
import { useI18n } from 'vue-i18n';
import { useProcessData } from "@/composables/properties/processData";

export default {
  name: "Comfort",
  components: {
    Modal,
  },
  setup() {
    const { t } = useI18n();
    const { processDataParking } = useProcessData();

    return {
      t,
      processDataParking
    }
  },
  data() {
    return {
      comfortView: false,
    };
  },
  computed: {
    ...mapGetters("detail", [
      "getObjectData",
      "isHotel",
      "getObjectProperties",
      "transferData",
    ]),

    kitchenType() {
      const typeOfKitchen =
        this.getObjectProperties.basic_properties.properties.find((prop) =>
          prop.name.includes("kitchen_")
        );
      if (!typeOfKitchen) {
        return null;
      }
      return typeOfKitchen;
    },

    repairQuality() {
      const qualityOfRepair =
        this.getObjectProperties.basic_properties.properties.find(
          (prop) => prop.name === "quality_repair"
        );

      if (!qualityOfRepair || qualityOfRepair.value === -1) {
        return null;
      }

      return {
        title: this.t("decoration.title"),
        properties: [
          {
            ...qualityOfRepair,
            title: this.t(`decoration.${qualityOfRepair.value}`),
          },
        ],
      };
    },

    transfer() {
      if(!this.transferData) {
        return null;
      }
      const [transfer, description] = this.transferData;
      if (!transfer) {
        return null;
      }
      return {
        title: this.t("transferTitle"),
        properties:
          Array.isArray(description) && description && description.length
            ? description
            : [
                {
                  name: "transfer_description",
                  value: this.t("transferDefaultDesc"),
                },
              ],
      };
    },
    isParking() {
      if (!this.getObjectData?.properties) return false;
      const parking = this.getObjectData.properties.parking?.properties?.find(prop => prop.name === "p_parking");
      return parking?.value && parking.value !== 3;
    },
    parking() {
      const properties = this.getObjectData?.properties;
      if (properties?.parking?.properties?.length) {
        const currency = this.getObjectData?.current_price?.currency_code || "RUB";
        const parking = this.processDataParking('parking', properties.parking, currency, this.isHotel);
        return {
          key: parking.key,
          title: properties.parking.title,
          label: parking.label,
          properties: parking.properties.filter(prop => prop.name !== "p_parking"),
        };
      }
      return false;
    },
    properties() {
      const result = _.omit(this.getObjectProperties, [
        "additional_payment",
        "accommodation_rules",
        "documents_rules",
        "enter_name",
        "guests_rules",
        "check_in_out_early_rules",
        "instant_rules",
        "basic_properties",
        "detail_description",
        "additional_properties",
        "hidden_properties",
        "additional_payment",
        "check_in_out_rules",
        "included_in_price",
      ]);

      if (this.isParking) {
        result['parking'] = this.parking;
      } else {
        delete result['parking'];
      }

      if (this.getObjectProperties?.included_in_price?.properties?.length) {
        result["included_in_price"] = {
          title: this.getObjectProperties.included_in_price.title,
          properties: this.getObjectProperties.included_in_price.properties.filter(p => p.name !== "transfer"),
        };
      }

      if (this.repairQuality) {
        result["quality_repair"] = this.repairQuality;
      }

      if (this.transfer) {
        result["transfer"] = this.transfer;
      }

      if (
        this.isHotel &&
        Array.isArray(result?.external_equipment?.properties)
      ) {
        result.external_equipment.properties =
          result.external_equipment.properties.filter(
            (item) => item.name !== "swimpool1"
          );
      }
      return result;
    },
    propertiesArray() {
      const pick = [
        "bathroom",
        "equipment",
        "for_leisure",
        "kitchen_equipment",
        "childs_fun",
        "external_equipment",
        "leisure_around",
        "availability",
        "pay_attention",
        "window_views",
        "parking",
        "included_in_price",
        "quality_repair",
      ];
      const result = [];

      for (const key of pick) {
        if (this.properties[key]) {
          result.push({
            ...this.properties[key],
            key: key,
          });
        }
      }

      return result;
    },
    propertyMain() {
      if(!this.properties) {
        return null;
      }
      let props = []
      const { equipment, quality_repair } = this.properties;
      if(equipment && equipment?.properties.length) {
        props = [...equipment?.properties];
      }
      if(quality_repair && quality_repair?.properties.length) {
          props = [
              ...props,
              ...quality_repair?.properties,
          ];
      }
      if(equipment) {
          return {
              ...equipment,
              properties: props,
          };
      }
      return {
          properties: props,
      };
    },
    propertyBathСnt() {
      let bathCnt =
        this?.getObjectData?.properties?.bathroom?.properties?.reduce(
          (acc, item) => {
            if (typeof item.value === "number" && item.value > 0)
              acc[item.name] = item;
            return acc;
          },
          {}
        );
      return bathCnt;
    },
    propertyBathroom() {
      let bathStr = [];
      this?.getObjectData?.properties?.bathroom?.properties.map((item, i) => {
        if (typeof item.value !== "number") {
          if (i === 0) bathStr.push(item.title);
          else bathStr.push(` ${item.title}`);
        }
      });
      return bathStr.join(",");
    },
    hasProperties() {
      return this.properties && !!Object.keys(this.properties).length;
    },
  },
  methods: {
    comfortShow() {
      this.comfortView = !this.comfortView;
      sendEventGtag("object_sa_m", {
        click: "all_info",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.comfort {
  &__bathroom {
    margin-top: 20px;

    .bathroom {
      &__line {
        display: flex;
        & > span {
          &::first-letter {
            text-transform: lowercase;
          }
          &:first-child {
            flex-grow: 1;
          }
          &:last-child {
            min-width: 30px;
            width: 30px;
            text-align: right;
          }
        }
      }
      &__cnt {
        margin-top: 5px;
      }
      &__str {
        margin-top: 5px;
      }
    }
  }
  &__main {
    margin-top: -15px;
  }
  &__modal {
    padding: 20px;
  }
  &__list {
    display: flex;
    flex-direction: column;
  }
  &__item {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }
  .sub-comfort {
    display: flex;
    flex-wrap: wrap;

    &__item {
      flex-basis: 100%;
      margin-top: 15px;
      color: #444;
      padding-right: 10px;
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  &__title {
    margin-bottom: 5px;
    font-size: 18px;
    color: #000;
  }
  &__button {
    margin-top: 15px;
    .button {
      color: #000;
    }
  }
  ul {
    margin-bottom: 0;
  }
}
.b-info-item-label {
  display: inline-block;
  max-width: 160px;
  margin: 0;
  margin-left: 16px;
  padding: 0px 5px 3px;
  border-radius: 3px;
  background: #f6f6f6;
  &::before {
    content: attr(data-label);
    font-size: 12px;
    font-weight: 400;
  }
}
</style>

<i18n lang="json">
{
	"ru": {
    "bookingPhone": "Ваш телефон для бронирования",
    "questionBonus": "Мы начислим вам бонус после того, как вы забронируете это жилье и напишете отзыв о проживании. Бонус поможет сэкономить на следующих бронированиях.",
    "invalidPhone": "Укажите корректный номер телефона",
    "otherPhone": "Другой номер телефона",
    "agreement": {
      "text": "Нажимая кнопку Продолжить, вы соглашаетесь с условиями {link1} и на {link2}",
      "link1":"<a href='{link}' target='_blank'>пользовательского соглашения</a>",
      "link2":"<a href='{link}' target='_blank'>обработку персональных данных</a>"
    },
    "hintPhone": "Отправим пароль и информацию по бронированию",
    "maxGuestsError": "Жильё вмещает максимум {cnt}",
    "guests":"{n} гостей | {n} гостя | {n} гостей | {n} гостей",
    "wlLabelText": "Забронировать помогает партнёр <br> {n} — Суточно.ру",
    "bonusInfo": "Бонусы применены"
	},
	"en": {
    "bookingPhone": "Booking Phone",
    "questionBonus": "We`ll pay bonuses after you book the housing and write a review about it. The bonus will help to save money in the next booking.",
    "invalidPhone": "Enter the correct phone number",
    "otherPhone": "Another phone number",
    "agreement": {
      "text": "By clicking the Continue button, you agree to the terms of the {link1} and to the {link2}",
      "link1":"<a href='{link}' target='_blank'>user agreement</a>",
      "link2":"<a href='{link}' target='_blank'>processing of personal data</a>"
    },
    "hintPhone": "We will send a password and booking information",
    "maxGuestsError": "Housing accommodates maximum of {cnt}",
    "guests": "{n} guests | {n} guests | {n} guests | {n} guests",
    "wlLabelText": "Partner helps to book <br> {n} - Sutochno.ru",
    "bonusInfo": "Bonuses applied"
	}
}
</i18n>
<template>
  <div class="step-booking">
    <template v-if="isLoading">
      <div v-if="bookingPrice" class="booking__body">
        <div class="booking-value">
          <!-- TODO: VUECLIENT-1067 поле с телефоном пришлось поднять вышел и опустить через css, -->
          <!-- т.к. если ниже размещать то autocomplete на iphone криво подставлет номер -->
          <div class="booking-value--phone">
            <!-- TODO: нужно вынести в десктопе и оттуда брать этот участок -->
            <div v-if="!disabledBlock" class="booking__phone">
              <div class="phone__text">{{ t("bookingPhone") }}</div>
              <div v-if="isAuth" class="phone__select">
                <b-form-select
                  v-if="getPhone"
                  id="max_guests"
                  :modelValue="booking.selectedPhone"
                  :options="getPhone"
                  @update:modelValue="updateSelectedPhone($event)"
                  @change="
                    gaChangeForm(getObjectData?.location?.location),
                      ymChangeForm()
                  "
                ></b-form-select>
              </div>
              <div v-if="!booking.selectedPhone" class="phone__input">
                <InputPhone
                  :modelValue="booking.inputPhone"
                  :is-valid="false"
                  :class="{ error: isPhoneError }"
                  @update:modelValue="updateInputPhone($event)"
                  @change="
                    gaChangeForm(getObjectData?.location?.location),
                      ymChangeForm()
                  "
                  @click="initVkEvents"
                />
              </div>
              <div v-if="isPhoneError" class="phone__errors">
                <div class="error">
                  {{ t("invalidPhone") }}
                </div>
              </div>
              <div v-if="!booking.selectedPhone" class="hint-phone">
                {{ t("hintPhone") }}
              </div>
            </div>
            <!-- /TODO -->
          </div>
          <div class="booking-value--date-guest">
            <div class="step-booking__item step-booking__dates">
              <div
                class="dates-item dates-item--in fake-input"
                @click="clickDates('in')"
              >
                {{ changeFormatDate(getObjectPrice.date_begin) }}
              </div>
              <div
                class="dates-item dates-item--out fake-input"
                @click="clickDates('out')"
              >
                {{ changeFormatDate(getObjectPrice.date_end) }}
              </div>
            </div>
            <SelectGuests
              class="step-booking__item step-booking__guests"
              :view="'page-detail'"
              :is-merge="true"
              :is-blocking-click="true"
              :showPetsDescriptionError="isPetsDescError"
              @click="clickGuests"
            />
            <Warning
              v-if="isGuestsError"
              class="warning__guests"
              vertical="small"
              horizontal="small"
              color="coral"
              :text="isGuestsError"
              :disabled="true"
            />
            <BaseViewPrice
              v-if="getObjectData"
              class="step-booking__price"
              :price="bookingPrice"
              count="top"
              :currency="currencyCalc"
              :default-price="getObjectData.defaultPrice"
              :hot="bookingPrice.object_hot"
              :sale-price="salePrice"
              :old-price="prices.max_price"
            />
            <PriceCalcDetail :prices="prices" />
            <div v-if="sale" class="booking__bonus-sale">
              <span class="booking__bonus-sale-summ">
                - {{ formatCalc(sale) }}
                <span class="booking__bonus-sale-cliff"></span>
                <span class="booking__bonus-sale-cliff"></span>
                <span class="booking__bonus-sale-cliff"></span>
              </span>
              <span class="booking__bonus-sale-info">
                {{ t("bonusInfo") }}
                <span class="booking__bonus-sale-cliff"></span>
                <span class="booking__bonus-sale-cliff"></span>
                <span class="booking__bonus-sale-cliff"></span>
              </span>
            </div>
            <CalcNotifications :sale="sale" />
            <BookingPriceError class="step-booking__error" />
          </div>
        </div>
        <BaseBonus
          class="step-booking__item step-booking__bonus"
          :value="bookingPrice.bonus"
        />
        <!-- так же этот блок в /base/Detail/Aside/Booking.vue -->
        <div v-if="whitelabel && whitelabel.name === 'cian'" class="wl-text">
          <div class="logo">
            <img :src="logoMob" class="img" />
          </div>
          <div class="info">
            <span v-html="t('wlLabelText', { n: whitelabel.title })" />
          </div>
        </div>
        <div
          v-if="!isAuth"
          class="user-agreement tmp-font--small"
          v-html="
            t('agreement.text', {
              link1: t('agreement.link1', { link: '//sutochno.ru/rules' }),
              link2: t('agreement.link2', {
                link: '//sutochno.ru/rules/agreement',
              }),
            })
          "
        ></div>
      </div>
    </template>
    <Placeholder v-else />
    <!-- модалка для ввода и авторизации -->
    <SafeTeleport to="#modal-code">
      <ModalCode v-if="booking.isModalCode" />
    </SafeTeleport>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { format, formatSafari } from "@/utils/format";
import { usePricesObject } from "@/composables/price";

import SelectGuests from "@/components/base/Detail/Aside/Guests";
import BaseViewPrice from "@/components/base/BaseViewPrice";
import CalcNotifications from "@/components/base/Booking/Aside/CalcNotifications";
import BaseBonus from "@/components/base/BaseBonus";
import InputPhone from "@sutochno/inputPhone";
import BookingPriceError from "@/components/base/Booking/Aside/BookingPriceError";
import Placeholder from "@/components/base/Booking/Aside/Placeholder";
import ModalCode from "./ModalCode";
import { Detail } from "@/utils/gtm/";
import Warning from "@/components/base/BaseWarning";
import logoMob from "@/assets/img/logo-mob-new.svg";
import PriceCalcDetail from "@/components/base/PriceCalcDetail/PriceCalcDetail.vue";

import saleBonus from "@/mixins/saleBonus.js";
import { useI18n } from 'vue-i18n';

export default {
  name: "ModalStepBooking",
  components: {
    SelectGuests,
    BaseViewPrice,
    CalcNotifications,
    BaseBonus,
    InputPhone,
    BookingPriceError,
    Placeholder,
    ModalCode,
    Warning,
    PriceCalcDetail
  },
  mixins: [saleBonus],
  setup() {
    const { t } = useI18n();
    const prices = usePricesObject();
    return {
      t,
      prices
    }
  },
  data() {
    return {
      logoMob,
    };
  },
  computed: {
    ...mapState("booking", ["warningInfo", "lastParamsForBookingPriceData"]),
    ...mapState("user", [
      "isAuth",
      "userData",
      "currency",
      "whitelabel",
      "isLoadUserData",
    ]),
    ...mapState("detailBooking", ["booking"]),
    ...mapState("search", ["checkIn", "checkOut", "guests"]),
    ...mapState("detailBooking", ["booking", "openDetailGuestsModal"]),
    ...mapGetters("detail", ["getObjectPrice", "getObjectData"]),
    ...mapGetters("booking", [
      "bookingPrice",
      "loadBookingPrice",
      "currencyCalc",
      "userMobiles",
      "params",
    ]),
    ...mapGetters("detailBooking", ["inputPhoneNumber", "bookingLoading"]),
    ...mapGetters("search", ["guestsCount"]),
    isPetsDescError(){
      return (
        this.guests?.pets?.value &&
        this.guests?.pets?.description === "" &&
        this.openDetailGuestsModal
      );
    },
    disabledBlock() {
      if (this.getObjectPriceErrors?.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    getPhone() {
      return [
        ..._.range(0, this.userMobiles.length).map((p) => {
          return {
            text: this.userMobiles[p],
            id: p,
            value: Number(this.userMobiles[p].match(/\d/g).join("")),
          };
        }),
        {
          text: this.t("otherPhone"),
          value: null,
          id: null,
        },
      ];
    },
    isPhoneError() {
      return this.booking.errors.phone || false;
    },
    isLoading() {
      return (
        !this.loadBookingPrice && this.getObjectData && !this.bookingLoading
      );
    },
    maxGuests() {
      return this.getObjectPrice?.max_guests || 0;
    },
    isGuestsError() {
      const ERROR = this.warningInfo.guests.error
        ? this.warningInfo.guests.error
        : this.guestsCount > this.maxGuests
        ? this.t("maxGuestsError", {
            cnt: this.t("guests", this.maxGuests),
          })
        : "";
      this.setBtnDisabled(ERROR ? true : false);
      return ERROR;
    },
    dateCheck() {
      if (this.getObjectData.properties.check_in_out_rules) {
        return this.getObjectData.properties.check_in_out_rules.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      } else {
        return this.getObjectData.properties.additional_payment.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      }
    },
    dateBeginTextFormat(){
      return format(this.checkIn) + ` ${this.dateCheck.in}`;
    },
    dateEndTextFormat(){
      return format(this.checkOut) + ` ${this.dateCheck.out}`;
    },
  },
  watch: {
    loadBookingPrice(val) {
      this.setBtnLoading(!!val);
    },
    "params.object_id"() {
      this.getObject({
        id: this.params.object_id,
        currency_id: this.currency.id,
      });
    },
  },
  mounted() {
    const OBJECT_ID = Number(this.getObjectData.id);
    const params = {
      guests: this.guestsCount,
      date_begin: this.dateBeginTextFormat,
      date_end: this.dateEndTextFormat,
      objects: OBJECT_ID,
      currency_id: this.currencyCalc.id,
      no_time: 1, // флаг 0 потому что time_begin и time_end
    };

    const checkedParams = this.checkNewParamsValue(params)
    if(!checkedParams) {
      return false
    }
    this.setBookingPriceData(params).then(() => {
      // this.setDraftType(this.params.draft_type);
    });
    this.setLastParamsForBookingPriceData(params)

    // if (this.objectCurrencyId != null && this.objectCurrencyId != 1) {
    //   this.getCurrencyPrice();
    // }
  },
  methods: {
    ...mapMutations("detailBooking", [
      "setModalStep",
      "setBtnLoading",
      "setBtnDisabled",
    ]),
    ...mapActions("booking", ["setBookingPriceData", "getObject", "setLastParamsForBookingPriceData"]),
    ...mapMutations("booking", ["setLastParamsForBookingPriceData"]),
    // ...mapActions("detail", ["setCurrecyPrice"]),
    ...mapActions("detailBooking", ["updateInputPhone", "updateSelectedPhone"]),
    checkNewParamsValue(params){
      let result = false;
      const entriesArray = Object.entries(params);
      for(const elem of entriesArray) {
        const [key, value] = elem;
        if(this.lastParamsForBookingPriceData[key] !== value){
          result = true;
          break
        }
      }
      return result;
    },
    ymChangeForm() {
      return Detail.ymChangeForm();
    },
    gaChangeForm(value) {
      return Detail.gaChangeForm(value);
    },
    initVkEvents() {
      if (this.isVkApp && !Object.keys(this.vkAppInfo).length) {
        /** eventsNameList - содержит эвенты которые ловятся в шапке проекта */
        const eventsNameList = [
          "VK_GET_USER_MAIL",
          "VK_GET_USER_INFO",
          "VK_GET_PHONE_NUMBER",
        ];
        const eventsCollection = [];
        eventsNameList.forEach((collEl) => {
          const evt = new Event(collEl);
          eventsCollection.push(evt);
        });
        eventsCollection.forEach((collEl) => {
          document.dispatchEvent(collEl);
        });
      }
    },
    changeFormatDate(date) {
      if (!date) return null;
      let dateFormat = new Date(formatSafari(date));
      const day = dateFormat.toLocaleString("ru-RU", {
        day: "numeric",
      });
      const month = dateFormat
        .toLocaleString("ru-RU", {
          month: "short",
        })
        .slice(0, 3);
      let weekday = dateFormat.toLocaleString(this.$i18n.locale, {
        weekday: "short",
      });
      if (typeof weekday === "string") {
        weekday = weekday.toLowerCase();
      }
      return `${day} ${month}, ${weekday}`;
    },
    clickGuests() {
      sendEventGtag("draft_sa_m", {
        click: "btn_guests_open_edit",
      });
      this.setModalStep("guests");
    },
    clickDates(param) {
      sendEventGtag("draft_sa_m", {
        click: param === "in" ? "btn_begin_date_open" : "btn_end_date_open",
      });
      sendEventGtag("dates_sa", {
        click: param === "in" ? "object_dates_in" : "object_dates_out",
      });
      this.setModalStep("calendar");
    },
    formatCalc(item) {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: "currency",
        currency: (this.calcCurrency && this.calcCurrency.code) || "RUB",
        minimumFractionDigits: 0,
      }).format(item);
    },
    // getCurrencyPrice() {
    //   const OBJECT_ID = Number(this.getObjectData.id);
    //   this.setCurrecyPrice({
    //     guests: this.guestsCount,
    //     date_begin: format(this.checkIn) + ` ${this.dateCheck.in}`,
    //     date_end: format(this.checkOut) + ` ${this.dateCheck.out}`,
    //     objects: OBJECT_ID,
    //     currency_id: this.objectCurrencyId,
    //     no_time: 1, // флаг 0 потому что time_begin и time_end
    //   })
    // },
  },
};
</script>
<style lang="scss" scoped>
:deep(.booking__body) {
  padding-top: 20px !important;
  padding-bottom: 102px !important;
  overflow: hidden;
}
.step-booking {
  width: 100%;
  .fake-input {
    border: 1px solid #d8d8d8;
    border-radius: 3px;
    padding: 11px 15px;
  }
  &__header {
    padding: 20px;
  }
  &__price {
    :deep(.tmp-font--big_bold) {
      font-size: 16px;
      font-weight: 500;
    }
  }
  &__calc {
    :deep(.button.button_link) {
      font-size: 12px;
    }
  }
  &__item {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  &__dates {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    .dates-item {
      cursor: pointer;
      color: #000000;
    }
  }
  &__guests {
    position: relative;
    display: grid;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    :deep(.select-guests--btn) {
      padding: 12px 46px 12px 15px !important;
      height: 41px !important;
    }
  }
  .warning__guests {
    margin-top: -10px;
    margin-bottom: 10px;
  }
  :deep(.calc-notifications) {
    margin-top: 20px;
    .calc-notifications__no-mb {
      padding-top: 0;
      font-size: 14px;
      line-height: 1.21;
      text-align: left;
      white-space: normal;
    }
  }
  .booking__phone {
    margin-top: 20px;
    .phone {
      &__text {
        font-size: 14px;
        line-height: 1;
      }
      &__select {
        margin-top: 8px;
        .sc-select {
          height: 42px;
        }
      }
      &__input {
        margin-top: 8px;
      }
      &__rule {
        margin-top: 10px;
        .sc-checkbox {
          color: #000;
          font-size: 13px;
        }
      }
      &__errors {
        margin-top: 8px;
        .error {
          color: #e10600;
          font-size: 13px;
          line-height: 14px;
          text-align: center;
        }
      }
    }
    .hint-phone {
      margin-top: 8px;
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
  :deep(.bonus__item) {
    border: none;
    font-size: 12px;
  }

  .user-agreement {
    color: #717171;
    margin: 20px 0 0;
    text-align: center;
    :deep(a) {
      color: #717171;
      text-decoration: underline;
    }
  }
}
.booking__bonus-sale {
  display: flex;
  align-items: center;
  margin-top: 22px;
  &-summ,
  &-info {
    display: block;
    width: 100%;
    height: 27px;
    padding: 6px 9px;
    color: #417505;
    font-size: 12px;
    position: relative;
  }
  &-summ {
    width: auto;
    white-space: nowrap;
    margin-right: 2px;
    font-size: 14px;
    &:before {
      content: "";
      background-color: rgba(255, 169, 0, 0.1);
      transform: rotate(-3deg) translateY(1px);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &-info {
    background-color: rgba(255, 169, 0, 0.1);
  }
  &-cliff {
    display: block;
    width: 6px;
    height: 4px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    right: -2px;
    top: 3px;
    &:nth-child(2) {
      top: 10px;
      right: -3px;
    }
    &:nth-child(3) {
      top: 18px;
      right: -3px;
    }
  }
  &-info &-cliff {
    right: unset;
    left: -3px;
  }
}
.wl-text {
  margin-top: 15px;
  margin-bottom: -5px;
  display: flex;
  align-items: center;

  .logo {
    padding-right: 14px;
  }
  .info {
    color: #000;
    font-size: 12px;
    font-weight: 500;
  }
}
.booking-value {
  display: flex;
  flex-direction: column;
  &--phone {
    margin-top: 20px;
    order: 2;
  }
  &--date-guest {
    order: 1;
    margin-bottom: -20px;
  }
}
</style>

<i18n lang="json">
{
	"ru": {
    "clear": "Чистота",
    "price_quality": "Цена - качество",
    "right_photo": "Соответствие фото",
    "service": "Качество обслуживания",
    "infrastructure": "Расположение",
    "accuracy": "Своевременность заселения",
    "ratingGuests": "Оценка гостей",
    "reviewStr": "на основании отзывов | на основании 1 отзыва | на основании {n} отзывов | на основании {n} отзывов",
    "emptyTitle": "Отзывы",
    "emptySubtitle": "В настоящее время пока никто не оставил отзыв на данный объект"
	},
	"en": {
    "clear": "Clear",
    "price_quality": "Quality - price",
    "right_photo": "Truth a photo",
    "service": "Quality of service",
    "infrastructure": "Good location",
    "accuracy": "Accuracy",
    "ratingGuests": "Оценка гостей",
    "reviewStr": "on basis reviews | on basis 1 review | on basis {n} reviews | on basis {n} reviews",
    "emptyTitle": "Rewiews",
    "emptySubtitle": "No reviews of this property yet"
	}
}
</i18n>
<template>
  <div v-if="isValid" class="reviews__rating">
    <template v-if="(isHotel && getHotelReviews.count === 0) || getObjectReviews.count === 0">
      <h2 class="rating__title">{{ t("emptyTitle") }}</h2>
      <div class="rating__subtitle">
        <span>{{ t("emptySubtitle") }}</span>
      </div>
    </template>

    <template v-else>
      <div class="rating__calc">
        <div class="calc__range">
          <div
            v-for="(rating, name, i) in ratingArray"
            :key="i"
            class="range__item"
          >
            <div class="range__text tmp-font--medium">{{ t(rating.name) }}</div>
            <div class="range__line">
              <div class="line">
                <div
                  class="line_complete"
                  :style="{ width: `${rating.value * 20}%` }"
                ></div>
              </div>
              <div class="range__rating tmp-font--medium">{{ $filters.newRating(rating.value) }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { Messages } from "@/components/api/";
import BaseRating from "@/components/base/BaseRating";
import _ from "lodash";
import { useI18n } from 'vue-i18n';

export default {
  name: "AvgRating",
  components: {
    BaseRating,
  },
  props: {
    id: {
      type: Number,
      default: {},
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('detail', ['avgRating', 'isHotel', 'hotelAvgRating', 'getHotelData', 'getHotelReviews', 'getObjectReviews']),
    isValid() {
      return this.isHotel
        ? this.getHotelReviews.count === 0 || this.getHotelReviews.count > 1
        : this.getObjectReviews.count === 0 || this.getObjectReviews.count > 1;
    },
    ratingArray() {
      let rating = null;
      if(this.isHotel) {
        if (!this.hotelAvgRating?.ratings) return false;
        rating = _.omit(this.hotelAvgRating.ratings, ["avg_rating"])
      } else {
        if (!this.avgRating?.ratings) return false;
        rating = _.omit(this.avgRating.ratings, ["avg_rating"])
      }
      const rateArray = [
        "clear",
        "service",
        "price_quality",
        "infrastructure",
        "right_photo",
        "accuracy",
      ];
      let newArr = [];
      rateArray.map((r) => {
        return Object.entries(rating)
          .filter(([key, value]) => {
            if ([key] == r) {
              newArr.push({
                name: key,
                value: value,
                translate: this.t(key),
              });
            }
          });
      });
      return newArr;
    },
  },
  mounted() {
    this.setAvgRating(this.id);
    if(this.isHotel) {
      this.setHotelAvgRating(this.getHotelData.hotel.id);
    }
  },
  methods: {
    ...mapActions('detail', ['setAvgRating', 'setHotelAvgRating']),
  },
};
</script>

<style lang="scss" scoped>
.reviews__rating {

  .rating {
    &__stars {
      padding-top: 3px;
    }
    &__calc {
      display: flex;

      .calc {
        &__range {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          width: 100%;
          .range {
            &__item {
              display: flex;
              align-items: flex-start;
              min-height: 32px;
            }
            &__text {
              display: flex;
              align-items: center;
              width: 50%;
              padding-right: 10px;
            }
            &__line {
              display: flex;
              align-items: center;
              width: 50%;
              
              .line {
                background-color: #ededed;
                display: inline-block;
                height: 6px;
                width: 100%;
                margin-right: 20px;
                border-radius: 3px;
                overflow: hidden;

                &_complete {
                  background-color: #f51449;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

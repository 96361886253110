<i18n lang="json">
{
  "ru": {
    "cancellationPolicy": "Правила отмены",
    "greeCancellation": "Бесплатная отмена",
    "before": "до",
    "moreDetails": "Подробнее",
    "pleaseEnterDates": "Укажите даты, чтобы узнать подробнее о правилах отмены",
    "freeCancellation": "Бесплатная отмена до",
    "bookingConfirmed": "Бронирование подтверждено",
    "fullRefundPrepayment": "Полный возврат предоплаты",
    "inCaseCancellation": "В случае отмены бронирования — предоплата не возвращается",
    "arrivalDay": "день заезда"
  },
  "en": {
    "cancellationPolicy": "Cancellation policy",
    "greeCancellation": "Free Cancellation",
    "before": "before",
    "moreDetails": "More details",
    "pleaseEnterDates": "Please enter dates to learn more about the cancellation policy",
    "freeCancellation": "Free cancellation until",
    "bookingConfirmed": "Booking confirmed",
    "fullRefundPrepayment": "Full refund of prepayment",
    "inCaseCancellation": "In case of cancellation of booking - prepayment is not refundable",
    "arrivalDay": "arrival day"
  }
}
</i18n>
<template>
  <div>
    <div class="free-cancellation">
      <div class="free-cancellation__title">
        {{ t("cancellationPolicy") }}
      </div>
      <template v-if="isOccupied">
        <div class="free-cancellation__body">
          <strong>{{ t("greeCancellation") }}</strong> {{ t("before") }} {{ freeCancellationDay }}
        </div>
        <div class="free-cancellation__more">
          <span @click="showModal">{{ t("moreDetails") }}</span>
        </div>
      </template>
      <template v-else>
        <div class="free-cancellation__body">
          {{ t("pleaseEnterDates") }}
        </div>
      </template>
    </div>
    <Modal v-model="modal" :title="t('cancellationPolicy')" :footerMerge="true" theme="light">
      <div class="free-cancellation-modal">
        <div class="free-cancellation-modal__title">
          {{ t("freeCancellation") }} {{ freeCancellationDay }}
        </div>
        <div class="free-cancellation-modal__list">
          <span></span>
          <div class="step step-1">
            <div class="step__title">
              {{ t("bookingConfirmed") }}
            </div>
            {{ t("fullRefundPrepayment") }}
          </div>
          <div class="step step-2">
            <div class="step__title">
              {{ freeCancellationDay }}
            </div>
            {{ t("inCaseCancellation") }}
          </div>
          <div class="step">
            <div class="step__title">
              {{ arrivalDay }} — {{ t("arrivalDay") }}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@sutochno/modal"
import { useI18n } from 'vue-i18n';

export default {
  name: "FreeCancellation",
  props: {
    arrivalDay: {
      type: String,
      default: ''
    },
    freeCancellationDay: {
      type: String,
      default: ''
    },
    isOccupied: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      modal: false
    }
  },
  components: {
    Modal
  },
  methods: {
    showModal() {
      this.modal = true
    }
  }
};
</script>

<style lang="scss" scoped>
.free-cancellation {
  background: #fff;
  padding: 20px;
  margin-top: 20px;
  &__title {
    font-size: 18px;
    color: #444444;
    margin-bottom: 10px;
  }
  &__body {
    color: #000000;
    font-size: 14px;
  }
  &__more {
    padding-top: 10px;
    span {
      cursor: pointer;
      color: #2d6cb4;
    }
  }
}

.free-cancellation-modal {
  padding: 20px;
  color: #444444;
  &__title {
    font-size: 16px;
    margin-bottom: 38px;
  }
  &__list {
    padding-left: 40px;
    min-height: 200px;
    position: relative;
    margin-left: 24px;
    &:before {
      content: '';
      width: 4px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: linear-gradient(to top, #e10600 50%, #6fa759 50%);
    }
    &:after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 20px;
      background-color: #feffff;
      position: absolute;
      top: 0;
      left: -6px;
      border: solid 3px #6fa759;
    }
    > span {
      position: absolute;
      top: 50%;
      left: 0;
      bottom: 0;
      &:after, &:before, span {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 20px;
        background-color: #feffff;
        position: absolute;
        left: -6px;
      }
      &:after {
        top: -11px;
        border: solid 3px #444444;
      }
      &:before {
        bottom: 0;
        border: solid 3px #e10600;
      }
    }
    .step {
      font-size: 14px;
      &.step-1 {
        min-height: 89px;
      }
      &.step-2 {
        min-height: 93px;
      }
      &__title {
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
}
</style>

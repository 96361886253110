<i18n lang="json">
{
	"ru": {
    "advantages": "Достоинства",
    "disadvantages": "Недостатки",
    "commentMore": "Показать ещё",
    "date": {
      "01": "Январь",
      "02": "Февраль",
      "03": "Март",
      "04": "Апрель",
      "05": "Май",
      "06": "Июнь",
      "07": "Июль",
      "08": "Август",
      "09": "Сентябрь",
      "10": "Октябрь",
      "11": "Ноябрь",
      "12": "Декабрь"
    },
    "addingTime": {
      "0": "сегодня",
      "1": "вчера",
      "2": "позавчера",
      "3": "3 дня назад",
      "4": "4 дня назад",
      "5": "5 дней назад",
      "6": "6 дней назад",
      "7": "неделю назад",
      "8": "2 недели назад",
      "9": "3 недели назад",
      "10": "месяц назад"
    },
    "ratingText": {
      "0": "не понравилось",
      "1": "терпимо",
      "2": "удовлетворительно",
      "3": "неплохо",
      "4": "хорошо",
      "5": "великолепно"
    },
    "averageRating": "Оценка проживания: ",
    "usefull": "Полезно +",
    "useless": "Бесполезно -",
    "lengthOfStay": "{n} суток | {n} сутки | {n} суток | {n} суток",
    "guests": "{n} гостей | {n} гость | {n} гостя | {n} гостей",
    "children": "с детьми",
    "child": "с ребенком"
	},
	"en": {
    "advantages": "Advantages",
    "disadvantages": "Disadvantages",

    "limitations": "Limitations",
    "commentMore": "Show more",
    "date": {
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      "10": "October",
      "11": "November",
      "12": "December"
    },
    "addingTime": {
      "0": "today",
      "1": "yesterday",
      "2": "day before yesterday",
      "3": "3 days ago",
      "4": "4 days ago",
      "5": "5 days ago",
      "6": "6 days ago",
      "7": "a week ago",
      "8": "2 weeks ago",
      "9": "3 weeks ago",
      "10": "month ago"
    },
    "ratingText": {
      "0": "did not like",
      "1": "be patient",
      "2": "satisfactory",
      "3": "not bad",
      "4": "good",
      "5": "great"
    },
    "averageRating": "Accommodation rating: ",
    "usefull": "Usefull +",
    "useless": "useless -",
    "lengthOfStay": "{n} days | {n} day | {n} days | {n} days",
    "guests": "{n} guests | {n} guest | {n} guests | {n} guests",
    "children": "with kids",
    "child": "with child"
	}
}
</i18n>
<template>
  <div class="review">
    <div class="review__wrap">
      <div class="review__block">
        <div class="review__avatar">
          <img :src="avatar" class="avatar__reviewer" />
        </div>
        <div class="review__main">
          <div class="review__info">
            <div class="review__info_box">
              <p class="review__name tmp-font--medium_bold">{{ name }}</p>
              <CommentRating :rating="rating"/>
            </div>
            <p v-if="getAddingTime" class="review__date tmp-font--small">
              {{ getAddingTime }}
            </p>
          </div>
          <div class="review__booking-desc tmp-font--medium">
            <template v-if="dateStart">
              <span class="icon-app-calendar1 review__icon"></span>
              <span class="review__date-of-stay">{{ dateStart }},&nbsp;</span>
            </template>
            <span class="review__days">{{
              t("lengthOfStay", review.days)
            }}</span
            ><br />
            <span class="icon-app-user-3 review__icon" v-if="guests"></span>
            <span class="review__guests">{{ guests }}</span>
          </div>
        </div>
      </div>
      <div class="review__block">
        <div class="review__comments">
          <div
            v-if="advantageText && advantageText !== 'undefined'"
            class="review__request"
          >
            <span class="icon-app-like"></span>
            <p class="review__text">{{ showAdvantageText }}</p>
            <span
              v-if="!isAllAdvantageText"
              class="review__comment-more tmp-font--small"
              @click="showAllAdvantageText = true"
            >
              {{ t("commentMore") }}
            </span>
          </div>

          <div
            v-if="disadvantageText && disadvantageText !== 'undefined'"
            class="review__request"
          >
            <span class="icon-app-dislike"></span>
            <p class="review__text">{{ showDisadvantageText }}</p>
            <span
              v-if="!isAllDisadvantageText"
              class="review__comment-more tmp-font--small"
              @click="showAllDisadvantageText = true"
            >
              {{ t("commentMore") }}
            </span>
          </div>

          <div
            v-if="!advantageText && !disadvantageText"
            class="review__rating"
          >
            {{ t("averageRating") }}{{ getRatingText }}
          </div>

          <!-- Если отель выводим название катигорию из которой оставлен отзыв -->
          <router-link
            v-if="roomTitle"
            :to="{
              name: 'Detail',
              params: { id: Number(review.object.id) },
            }"
            target="_blank"
            class="review__room"
          >
            <span>{{ roomTitle }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="review__media" v-if="isMedia">
      <div class="img-list">
        <img
          v-for="(item, index) in review.data.media"
          :key="index"
          class="img"
          :src="`//static.sutochno.ru${item.properties.full_path.value}small/${item.properties.filename.value}`"
          alt
          @click="selectSlide(index)"
        />
      </div>
      <Gallery
        v-if="viewImage"
        :media="review.data.media"
        :view-image="viewImage"
        :alt="false"
        :selected-slide="selectedSlide"
      />
    </div>
    <div v-if="review && review.responses" class="review__responses">
      <Response
        v-for="resopnse in review.responses"
        :key="resopnse.id"
        :review="resopnse"
      />
    </div>
    <!--     <div class="review__benefit tmp-font--small">
      <span
        class="review__useless"
        :class="{ 'tmp-font--small_bold': benefit === 'useless' }"
        @click="clickUseless()"
        >{{ t("useless") }}</span
      >
      <span
        class="review__usefull"
        :class="{ 'tmp-font--small_bold': benefit === 'usefull' }"
        @click="clickUsefull()"
        >{{ t("usefull") }}</span
      >
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";

import Rating from "@/components/base/BaseRating";
import CommentRating from '@/components/base/BaseComments/CommentRating';
import { formatSafari } from "@/utils/format";
import Response from "./Response";
import { Detail } from "@/utils/gtm/";
import Gallery from "@sutochno/gallery";
import { useI18n } from 'vue-i18n';
import avatarNew from 'assets/img/default-avatar-new.svg';

export default {
  name: "Review",
  components: {
    Rating,
    Response,
    Gallery,
    CommentRating
  },
  props: {
    review: {
      type: Object,
      default: {},
    },
    isHotel: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      showAllDisadvantageText: false,
      showAllAdvantageText: false,
      benefit: "",
      viewImage: false,
      selectedSlide: 0,
    };
  },
  computed: {
    ...mapGetters("detail", [
      "getObjectData",
      "getObjectReviews",
      "getHotelData",
    ]),
    rating(){
      if (this.review?.data?.properties?.avg_rating?.value) {
        return this.review.data.properties.avg_rating.value;
      } else if (this.review?.data?.properties?.rating?.value){
        return this.review.data.properties.rating.value;
      }
      return 0
    },
    guests() {
      let guests = 0;
      let childrenText = "";
      if (this.review.cnt_man && this.review.cnt_woman) {
        guests = Number(this.review.cnt_man) + Number(this.review.cnt_woman);
      } else if (this.review.cnt_man) {
        guests = Number(this.review.cnt_man);
      } else if (this.review.cnt_woman) {
        guests = Number(this.review.cnt_woman);
      }
      if (guests && this.review.cnt_child) {
        const cnt_child = Number(this.review.cnt_child);
        if (cnt_child === 1) {
          childrenText = this.t("child");
        } else if (cnt_child > 1) {
          childrenText = this.t("children");
        }
      }
      if (!guests && this.review.cnt_guests) {
        guests = this.review.cnt_guests;
      }

      return guests ? `${this.t("guests", guests)} ${childrenText}` : "";
    },
    getRatingText() {
      // Warning!!! расчет берется из 5 бальной системы и преобразует в 10 бальную
      const rating = Number(this.review.data.properties?.rating?.value) * 2;
      if (rating > 10) {
        return "Error";
      }

      if (rating >= 0 && rating <= 2) {
        return this.t("ratingText.0");
      }
      if (rating > 2 && rating <= 4) {
        return this.t("ratingText.1");
      }
      if (rating > 4 && rating <= 6) {
        return this.t("ratingText.2");
      }
      if (rating > 6 && rating <= 7) {
        return this.t("ratingText.3");
      }
      if (rating > 7 && rating <= 9) {
        return this.t("ratingText.4");
      }
      if (rating > 9 && rating <= 10) {
        return this.t("ratingText.5");
      }
      return "";
    },
    name() {
      if (this.review?.user?.data?.properties?.first_name?.value)
        return this.review.user.data.properties.first_name.value;
      else return "Пользователь";
    },
    avatar() {
      const media = this.review?.user?.data?.media;
      if (media?.length && media[0])
        return `//static.sutochno.ru${media[0].properties.full_path.value}/${media[0].properties.filename.value}`;
      else return avatarNew;
    },
    date() {
      return new Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
        .format(new Date(formatSafari(this.review.date_add)))
        .replace(" г.", "");
    },
    reviewPro() {
      const prop = this.review?.data?.properties;
      if (prop.new_pro && prop.new_pro.value.replace(" ", "") !== "")
        return prop.new_pro.value;
      else if (prop.pro && prop.pro.value.replace(" ", "") !== "")
        return prop.pro.value;
      else return false;
    },
    reviewContra() {
      const prop = this.review?.data?.properties;
      if (prop.new_contra && prop.new_contra.value.replace(" ", "") !== "")
        return prop.new_contra.value;
      else if (prop.contra && prop.contra.value.replace(" ", "") !== "")
        return prop.contra.value;
      else return false;
    },
    roomTitle() {
      if (this.review?.object?.data?.hotel?.id && this.isHotel) {
        return this.review?.object?.title?.trim()
          ? this.review.object.title
          : this.review?.object?.title?.type_title?.trim();
      }
      return false;
    },
    dateStart() {
      const date = this.review?.data?.properties?.rent_date_start?.value;

      if (typeof date != "string") {
        console.warn("Review/dateStart: Неверный тип данных");
        return false;
      }
      if (date) {
        let dateArr = date.includes("-") ? date.split("-") : date.split(".");
        if (date.includes("-")) {
          return this.t(`date.${dateArr[1]}`) + " " + dateArr[0];
        } else {
          return this.t(`date.${dateArr[1]}`) + " " + dateArr[2];
        }
      }
      return false;
    },
    // плюсы
    advantageText() {
      if (this.review?.status_id == 3){
        if (
          typeof this.review?.data?.properties?.new_pro?.value == "string" &&
          this.review.data.properties.new_pro.value.trim()
        ) {
          return this.review.data.properties.new_pro.value;
        } else if (
          typeof this.review?.data?.properties?.pro?.value == "string" &&
          this.review.data.properties.pro.value.trim()
        ) {
          return this.review.data.properties.pro.value;
        }

        return "";
      }

      return "";
    },
    isAllAdvantageText() {
      if (this.advantageText.length <= 100 || this.showAllAdvantageText) {
        return true;
      }
      return false;
    },
    showAdvantageText() {
      if (this.isAllAdvantageText) {
        return this.advantageText;
      }
      const newText = this.advantageText.substring(0, 100);
      return newText.trim() + "...";
    },
    // замечания
    disadvantageText() {
      if (this.review?.status_id == 3){
        if (
          typeof this.review?.data?.properties?.new_contra?.value == "string" &&
          this.review.data.properties.new_contra.value.trim()
        ) {
          return this.review.data.properties.new_contra.value;
        } else if (
          typeof this.review?.data?.properties?.contra?.value == "string" &&
          this.review.data.properties.contra.value.trim()
        ) {
          return this.review.data.properties.contra.value;
        }

        return "";
      }

      return "";
    },
    isAllDisadvantageText() {
      if (this.disadvantageText.length <= 100 || this.showAllDisadvantageText) {
        return true;
      }
      return false;
    },
    showDisadvantageText() {
      if (this.isAllDisadvantageText) {
        return this.disadvantageText;
      }
      const newText = this.disadvantageText.substring(0, 100);
      return newText.trim() + "...";
    },
    getAddingTime() {
      if (isNaN(Date.parse(this.review.date_add))) return false;
      const date_add = new Date(formatSafari(String(this.review.date_add)));
      const addingDate = new Date(
        date_add.getFullYear(),
        date_add.getMonth(),
        date_add.getDate()
      );
      const date = new Date();
      const currentDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      const passedDays = (currentDate - addingDate) / 86400000;
      if (passedDays < 1) return this.t("addingTime.0");
      else if (passedDays === 1) return this.t("addingTime.1");
      else if (passedDays === 2) return this.t("addingTime.2");
      else if (passedDays === 3) return this.t("addingTime.3");
      else if (passedDays === 4) return this.t("addingTime.4");
      else if (passedDays === 5) return this.t("addingTime.5");
      else if (passedDays === 6) return this.t("addingTime.6");
      else if (passedDays >= 7 && passedDays <= 13)
        return this.t("addingTime.7");
      else if (passedDays >= 14 && passedDays <= 20)
        return this.t("addingTime.8");
      else if (passedDays >= 21 && passedDays <= 27)
        return this.t("addingTime.9");
      else if (passedDays >= 28 && passedDays <= 38)
        return this.t("addingTime.10");
      else return this.dateComment(this.review.date_add);
    },
    isMedia() {
      return this.review?.data?.media && this.review?.data?.media.length != 0
    }
  },
  methods: {
    dateComment(dateAdd) {
      var date = new Date(formatSafari(String(dateAdd)));
      var formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      formatter = formatter.format(date);
      return formatter;
    },
    clickUsefull() {
      this.benefit = "usefull";
      sendEventGtag("object_sa_m", {
        click: "review_plus",
        value: this.review.id
      });
    },
    clickUseless() {
      this.benefit = "useless";
      sendEventGtag("object_sa_m", {
        click: "review_minus",
        value: this.review.id
      });
    },
    selectSlide(index) {
      this.viewImage = !this.viewImage;
      this.selectedSlide = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.review {
  &__block {
    display: flex;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__avatar {
    flex-basis: 40px;
    min-width: 40px;
    height: 40px;
    .avatar {
      &__reviewer {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  &__name {
    color: #000;
  }
  &__main {
    flex-grow: 1;
    margin-left: 15px;
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40px;
    &_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__date {
    margin-top: 5px;
    color: #717171;
  }

  &__booking-desc {
    margin-top: 10px;
    color: #717171;
  }
  &__icon {
    font-size: 16px;
    margin-right: 10px;
    padding-top: 5px;
    display: inline-block;
  }
  &__days {
    margin-right: 20px;
  }

  &__comments {
    margin-top: 15px;
  }

  &__advantages {
    color: #7f7f7f;
    margin-bottom: 5px;
  }
  &__request {
    position: relative;
    margin-top: 15px;
    margin: 15px 40px 0 55px;
    &:first-child {
      margin-top: 0;
    }
    .icon-app-like,
    .icon-app-dislike {
      position: absolute;
      top: 0px;
      left: -42px;
      font-size: 15px;
    }
    .icon-app-like {
      color: #417505;
    }
    .icon-app-dislike {
      color: #b90d0b;
    }
  }
  &__text {
    margin-top: 0;
  }
  &__comment-more {
    margin-top: 5px;
    color: #2d6cb4;
    cursor: pointer;
  }

  &__room {
    display: inline-block;
    margin: 10px 0px 0px 55px;
    color: #717171;
    background-color: #f6f6f6;
    padding: 7px 10px;
    font-size: 13px;
    line-height: 16px;
  }

  &__rating {
    padding-left: 55px;
  }

  &__benefit {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    color: #717171;
  }
  &__useless {
    padding-right: 10px;
  }
  &__usefull {
    padding-left: 10px;
  }
  &__media {
    margin-left: 55px;
    margin-right: -20px;
    padding: 15px 0;
    .img-list {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      overflow: auto;
      img {
        height: 98px;
        object-fit: cover;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    :deep(.gallery__preview .preview .preview-img) {
      max-width: 70vmax;
      max-height: 70vmax;
    }
  }
}

</style>

<i18n lang="json">
{
  "ru": {
    "adult": "0|{n} взрослый | {n} взрослых | {n} взрослых",
    "child": " без детей | {n} ребёнок | {n} ребёнка | {n} ребёнка",
    "children": "{n} детей",
    "withPets": "питомец",
    "errorText": "Укажите с каким животным вы путешествуете"
  },
  "en": {
    "adult": "0|{n} adult | {n} adult | {n} adult",
    "child": " without children |{n} child | {n} child's | {n} child's",
    "children": "{n} children's",
    "withPets": "pet",
    "errorText": "Please indicate which animal you are traveling with"
  }
}
</i18n>
<template>
  <div class="select-guests page-detail">
    <button
      class="select-guests--btn page-inner"
      :class="{
        'active-simple': isOpenGuestsSelect,
        error: showPetsDescriptionError,
      }"
      @click="showSelect"
    >
      <div class="body" ref="bodyText">
        {{ getGuestsText }}
        <template v-if="!guests.childrens.length && !guests.pets.value">
          <span class="empty">{{ t("child", 0) }}</span>
        </template>
      </div>
      <span class="ico-arrow icon-app-select"></span>
    </button>
    <p v-if="showPetsDescriptionError" class="error-text">
      {{ t("errorText") }}
    </p>
    <ModalGuests v-model="isOpenGuestsSelect" v-if="!isBlockingClick"/>
  </div>
</template>

<script>
import ModalGuests from "@/components/base/SelectGuestsCopy/ModalGuests";
import { mapState, mapMutations } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  name: "SelectGuestsDesktop",
  components: {
    ModalGuests,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      isOpenGuestsSelect: false,
      adults: 1,
      children: [],
      petsValue: false,
    };
  },
  props: {
    isBlockingClick:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("search", ["guests"]),
    ...mapState("detailBooking", ["openDetailGuestsModal"]),
    getGuestsText(){
      let result = this.t("adult", this.guests.adults);
      if (this.guests.childrens.length){
        if (this.guests.childrens.length >= 5){
          result += ", " + this.t("children", this.guests.childrens.length);
        } else {
          result += ", " + this.t("child", this.guests.childrens.length)
        }
      }
      if (this.guests?.pets?.value){
        result += ", " + this.t("withPets");
      }

      return result;
    },
    showPetsDescriptionError(){
      return this.guests.pets?.value && !this.guests.pets?.description;
    }
  },
  watch: {
    isOpenGuestsSelect(val) {
      this.$emit(val ? "show" : "close");
      !val && this.setOpenDetailGuestsModal(false)
    },
    openDetailGuestsModal(val){
      if (val) {
        this.$nextTick(()=>{
          this.isOpenGuestsSelect = true;
        })
      }
    }
  },
  methods: {
    ...mapMutations("detailBooking", ["setOpenDetailGuestsModal"]),
    showSelect() {
      if (this.isBlockingClick) {
        this.$emit("click");
      } else {
        if (!this.isOpenGuestsSelect) {
          this.$emit("click");
        }
        this.isOpenGuestsSelect = !this.isOpenGuestsSelect;
      }
      this.$emit("clickGuests");
    },
  },
  mounted(){
    if (this.$route.query.open_guest_modal) {
      this.isOpenGuestsSelect = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.select-guests {
  position: relative;
  width: 100%;

  &--btn {
    position: relative;
    margin: 0;
    padding: 12px 46px 12px 15px;
    width: 100%;
    min-width: 264px;
    height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    outline-style: none;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
    background: #fff;
    font-size: 14px;
    line-height: 1;
    color: #000;
    white-space: nowrap;
    box-shadow: inset 0 0 0 1px #fff;
    transition: box-shadow 0.3s;

    .body {
      width: 100%;
      text-align: left;
      span {
        &.empty {
          color: #717171;
          padding-right: 10px;
        }
      }
    }
    &.active-simple {
      .ico-arrow {
        transform: translateY(-50%) scaleY(-1);
      }
    }
    .ico-arrow {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.3s;
      font-size: 14px;
    }
    &.active {
      box-shadow: inset 0 0 0 1px #f51449;
    }
    &.page-inner {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
}
.error{
  border: 1px solid #d12d1e;
  animation: blink 0.4s ease-in-out 4 alternate;
}
.error-text{
  margin-top: 5px;
  color: #D12D1E;
  font-size: 12px;
  line-height: 16px;
}
@keyframes blink {
  from {
    background-color: #fff;
  }
  to {
    background-color: #ffe8e8;
  }
}
</style>


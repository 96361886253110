<i18n>
{
  "ru": {
    "dop_persons": "Доплата за {n} гостей | Доплата за {n} гостя | Доплата за {n} гостей",
    "cleaning": "Плата за финальную уборку",
    "sale": "Горящее предложение",
    "interval": "Скидка при проживании от",
    "bonuses": "Применены бонусы Суточно.ру",
    "nights": "менее суток | {n} сутки | {n} суток | {n} суток",
    "popover": {
      "one_day_price": "Средняя цена за сутки была округлена",
      "bonuses": "Бонусами можно оплатить до 10% от общей стоимости проживания (не более 100000 ₽)"
    }
  },
  "en": {
    "dop_persons": "Surcharge for {N} guests / Surcharge for {N} guests / Surcharge for {N} guests",
    "cleaning": "Final cleaning fee",
    "sale": "Last minute offer",
    "interval": "discount on the price from",
    "bonuses": "Sutochno.ru bonuses applied",
    "nights": "less than a day | {n} a day | {n} a day | {n} a day",
    "popover": {
      "one_day_price": "The average price per day has been rounded",
      "bonuses": "Bonuses can be used to pay up to 10% of the total cost of stay (no more than 100000 ₽)"
    }
  }
}
</i18n>

<template>
  <div class="price-item">
    <div class="price-item__title">
      <span class="price-item__title-text">{{ getTitlePrice }} </span>
      <SaleHint v-if="priceItem.type === 'sale'"/>
    </div>
    <VDropdown
      v-if="priceItem.type === 'one_day_price' || priceItem.type === 'bonuses'"
      class="bonus__popover"
      distance="5"
      placement="top"
      :triggers="['click']"
      popper-class="v-popper__popper--max-width-250 calc-price-item"
    >
      <span class="price-item-ico-question"></span>
      <template #popper>
        <div class="tooltip-text">
          <p class="text-left">{{ t(`popover.${priceItem.type}`) }}</p>
          <div v-close-popper class="tooltip-close"></div>
        </div>
      </template>
    </VDropdown>
    <span class="price-item__value" :class="classes">{{ getPrice }}</span>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { formatPriceCeil } from "@/utils/format";
import SaleHint from "./SaleHint.vue";

const props = defineProps({
  priceItem: {
    type: Object,
    default: null,
  },
  hotItem: {
    type: Object,
    default: null,
  },
  currencyItem: {
    type: Object,
    default: null,
  },
});

const i18n = useI18n();
const { t, locale } = i18n;

const priceItem = computed(() => props.priceItem);
const currencyCode = computed(() => props.currencyItem?.title || "RUB");

const actionsCostPrice = {
  one_day_price: () =>
    formatPriceCeil(
      priceItem.value.cost * priceItem.value.nights,
      currencyCode.value,
      locale.value
    ),
  dop_persons: () =>
    formatPriceCeil(
      priceItem.value.cost * priceItem.value.nights,
      currencyCode.value,
      locale.value
    ),
  cleaning: () => formatPriceCeil(priceItem.value.cost, currencyCode.value, locale.value),
  sale: () =>
    `-${formatPriceCeil(priceItem.value.cost, currencyCode.value, locale.value)}`,
  interval: () =>
    `-${formatPriceCeil(priceItem.value.cost, currencyCode.value, locale.value)}`,
  bonuses: () =>
    `-${formatPriceCeil(priceItem.value.cost, currencyCode.value, locale.value)}`,
};
const actionsTitlePrice = {
  one_day_price: () =>
    `${formatPriceCeil(priceItem.value.cost, currencyCode.value, locale.value)} x ${t(
      "nights",
      priceItem.value.nights
    )}`,
  dop_persons: () => t("dop_persons", priceItem.value.guests),
  cleaning: () => t("cleaning"),
  sale: () => t("sale"),
  interval: () => `${t("interval")} ${t("nights", priceItem.value.days)}`,
  bonuses: () => t("bonuses"),
};

const getPrice = computed(() => {
  if (actionsCostPrice[priceItem.value.type]) {
    return actionsCostPrice[priceItem.value.type]();
  }
  return '';
});

const getTitlePrice = computed(() => {
  if (actionsTitlePrice[priceItem.value.type]) {
    return actionsTitlePrice[priceItem.value.type]();
  }
  return '';
});

const classes = computed(() =>
  ["sale", "interval", "bonuses"].includes(priceItem.value.type)
    ? "price-item__value--sale"
    : ""
);
</script>

<style lang="scss" scoped>
$color-gray-g5: #5a5d63;
$color-gray-g6: #252525;
$color-light: #1c1c1c;

.price-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
  &__title {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: $color-gray-g5;
    white-space: pre-line;
    display: flex;
    align-items: center;
  }
  &-ico-question {
    display: block;
    width: 18px;
    height: 18px;
    margin-left: 4px;
    background-image: url(~/assets/img/icon/help.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  &__value {
    flex: 1;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: right;
    color: $color-gray-g6;
    &--sale {
      color: #f51449;
    }
  }
}
</style>

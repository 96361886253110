<i18n lang="json">
{
	"ru": {
    "maxGuests": "ни одного | 1 гость | {n} гостя | {n} гостей",
    "beds": "нет кроватей | 1 кровать | {n} кровати | {n} кроватей",
    "meter": "м²",
    "more": "Подробнее",
    "booking": "Забронировать",
    "default": "Цена и доступность",
    "moreOfRoom": "Подробнее о номере",
    "errorGuest": "Объект не может вместить указанное количество гостей.",
    "errorDate": "Забронировано на ваши даты! Жилье пользуется спросом.",
    "errorChooseDates":"Выберите даты заезда и выезда"
	},
	"en": {
    "maxGuests": "no guests | 1 guest | {n} guests | {n} guests",
    "beds": "no beds | 1 bed | {n} beds | {n} beds",
    "meter": "m²",
    "more": "More",
    "booking": "Booking",
    "default": "Price and availability",
    "moreOfRoom": "More of room",
    "errorGuest": "The property cannot accommodate the specified number of guests.",
    "errorDate": "Booked for your dates! Housing is in demand.",
    "errorChooseDates":"Select check-in and check-out dates"
	}
}
</i18n>
<template>
  <div class="room" v-if="room">
    <div class="room__wrap">
      <template v-if="!hotelObjectsLoad">
        <div class="room__title">{{ room.type_title }}</div>
        <div class="room__preview">
          <div class="room__img" v-if="img">
            <img :src="img" :alt="alt" />
          </div>
          <div class="room__info">
            <div class="info__top">
              <p
                class="info__type"
                v-if="room.hotel_category && room.hotel_category.title"
              >
                {{ room.hotel_category.title }}
              </p>
              <div class="info__options" v-if="options">
                <div class="options__main">
                  <div class="options__item" v-if="options.max_guests">
                    {{ t("maxGuests", options.max_guests.value) }}
                  </div>
                  <div class="options__item" v-if="options.beds">
                    {{ t("beds", options.beds.value) }}
                  </div>
                </div>
                <div class="options__area" v-if="options.area">
                  {{ `${options.area.value} ${t("meter")}` }}
                </div>
              </div>
            </div>
            <div class="info-bottom" v-if="!dates">
              <router-link
                target="_blank"
                :to="{
                  name: 'Detail',
                  params: {
                    id: room.id,
                  },
                }"
                class="info__more button button_link"
                >{{ t("moreOfRoom") }}</router-link
              >
            </div>
          </div>
        </div>
        <template v-if="price && price.success && dates">
          <div class="room__view-price">
            <BaseViewPrice
              :price="price.data"
              count="top"
              :currency="currency"
              :default-price="1"
              :prepay="t('questionPrepayment')"
              :hot="price.data.object_hot"
              :old-price="oldPrice"
              :sale-price="salePrice"
            />
          </div>
          <div class="room__calc">
            <BaseCalcPrice
              :data="price.data.detail"
              :dop="price.data.detail_dop"
              :currency="currency"
              :hot="price.data.object_hot"
            />
          </div>
        </template>
        <div class="room__buttons">
          <template v-if="dates">
            <template v-if="price && price.data.busy !== 'busy'">
              <div class="room__button">
                <router-link
                  target="_blank"
                  :to="{
                    name: 'Detail',
                    params: {
                      id: room.id,
                    },
                  }"
                  class="button button_w-100 button_size_md room__button-link"
                  >{{ t("more") }}</router-link
                >
              </div>
              <div class="room__button" v-if="this.guestsSearch.pets?.value">
                <router-link
                  target="_blank"
                  :to="{
                    name: 'Detail',
                    params: {
                      id: room.id,
                    },
                    query: {
                      open_guest_modal: true,
                    }
                  }"
                  class="link-btn button button_w-100 button_green button_size_md"
                  >{{ t("booking") }}</router-link
                >
              </div>

              <div class="room__button" v-else>
                <button
                  class="button button_w-100 button_green button_size_md"
                  @click="bookingStart()"
                >
                  {{ t("booking") }}
                </button>
              </div>
            </template>
          </template>

          <template v-else>
            <button
              class="button button_w-100 button_size_md room__button-link"
              @click="datesDefault()"
            >
              {{ t("default") }}
            </button>
          </template>
        </div>

        <template v-if="dates && price">
          <div class="room__error" v-if="price.errors.length">
            <p>{{ price.errors[0] }}</p>
          </div>
          <div class="room__error" v-if="price.data.busy == 'busy'">
            <span class="icon-app-clock"></span>
            <p>{{ t("errorDate") }}</p>
          </div>
          <div
            class="room__error"
            v-else-if="
              price.data.cnt_guests >
              price.data.max_guests * price.data.rooms_cnt
            "
          >
            <span class="icon-app-guest"></span>
            <p>{{ t("errorGuest") }}</p>
          </div>
        </template>
      </template>

      <template v-else>
        <div class="room__load">
          <div class="loading"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import BaseCalcPrice from "@/components/base/BaseCalcPrice";
import BaseViewPrice from "@/components/base/BaseViewPrice";
import { format } from "@/utils/format.js";
import _ from "lodash";
import dataLayerPushGA4 from "@/mixins/dataLayerPushGA4.js";
import { useI18n } from 'vue-i18n';

export default {
  name: "Room",
  mixins: [dataLayerPushGA4],
  components: {
    BaseViewPrice,
    BaseCalcPrice,
  },
  props: {
    room: {
      type: Object,
      default: {},
    },
    price: {
      type: Object,
      default: {},
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("detail", ["hotelObjectsLoad"]),
    ...mapGetters("detail", ["getBookingData", "isBronevik"]),
    ...mapState("search", ["checkIn", "checkOut"]),
    ...mapState("user", ["isAuth", "userData"]),
    ...mapState("search", { guestsSearch: "guests" }),
    ...mapState("search", ["checkIn", "checkOut"]),
    dates() {
      if (!this.checkIn || !this.checkOut) return false;
      else return true;
    },
    img() {
      if (!this?.room?.media && !this?.room?.media?.at(0)?.image_url) return false;
      else return this.room.media?.at(0)?.image_url?.replace("source", "small");
    },
    alt() {
      if (!this?.room?.media && !this.room.media[0]?.comment) return false;
      else return this.room.media[0].comment;
    },
    options() {
      const optionsArr = ["beds", "max_guests", "area"];
      return this?.room?.properties?.basic_properties?.properties?.reduce(
        (acc, item) => {
          if (optionsArr.includes(item.name)) {
            acc[item.name] = item;
          }
          return acc;
        },
        {}
      );
    },
    currency() {
      return {
        id: this.price?.data?.currency?.id ? this.price.data.currency.id : 1,
        title: this.price?.data?.currency?.code
          ? this.price.data.currency.code
          : "RUB",
      };
    },
    getPhone() {
      if (
        this.isAuth &&
        this.userData &&
        this.userData.user &&
        this.userData.user.contacts
      ) {
        return this.userData.user.contacts.reduce((acc, item, index) => {
          if (item.type_id == 2 && item.visible == 1) {
            acc = item.value;
          }
          return acc;
        }, []);
      } else {
        return "";
      }
    },
    guestsSend() {
      let countChild = 0;
      return _.range(
        0,
        this.guestsSearch.adults + this.guestsSearch.childrens.length
      ).map((g, i) => {
        if (i <= this.guestsSearch.adults - 1) {
          return (g = { type: 0 });
        } else {
          g = { type: 3, age: this.guestsSearch.childrens[countChild] };
          countChild += 1;
          return g;
        }
      });
    },
    dateCheck() {
      if (this.room.properties.check_in_out_rules) {
        return this.room.properties.check_in_out_rules.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      } else {
        return this.room.properties.additional_payment.properties.reduce(
          (acc, v) => {
            if (v.name == "check_in") {
              acc = {
                ...acc,
                in: v.value,
              };
            }
            if (v.name == "check_out") {
              acc = {
                ...acc,
                out: v.value,
              };
            }
            return acc;
          },
          {}
        );
      }
    },
    currencyCalc() {
      if (this.price.data?.currency) {
        return {
          id: this.price.data.currency.id,
          title: this.price.data.currency.code,
        };
      } else {
        return this.currency;
      }
    },
    sale() {
      if (
        Number(this.currencyCalc.id) === 1 &&
        Number(this.currency.id === 1) &&
        this.isAuth
      ) {
        const sale = Math.floor(this.oldPrice * 0.1);
        if (this.userData?.balance_virtual) {
          return sale <= this.userData?.balance_virtual
            ? sale
            : this.userData.balance_virtual;
        }
      }
      return 0;
    },
    salePrice() {
      return this.oldPrice - this.sale;
    },
    oldPrice() {
      return this.price.data?.price
        ? this.price.data.price
        : this.price.data.price_default;
    },
  },
  methods: {
    ...mapActions("detail", ["setBookingData"]),
    async bookingStart() {
      if (this.isBronevik) {
        // Пока костыль
        this.modalBronevik = true;
        sendEventGtag("test_bronevik", {
          click: "booking",
        });
      } else {
        await this.setBookingData({
          phone: this.getPhone,
          guests: this.guestsSend,
          rooms_cnt: 1,
          hotel_booking: true,
          time_begin: this.dateCheck.in.match(/\d{2}/),
          time_end: this.dateCheck.out.match(/\d{2}/),
          date_begin: format(this.checkIn),
          date_end: format(this.checkOut),
          object_id: this.room.id,
          price: this.price.data.price ? this.price.data.price : null,
          no_time: 1,
          tm: "-240",
        });
        // https://jira.webpower.ru/browse/VUECLIENT-1811
        // this.dataLayerPush("add_to_cart");
        this.$router
          .push({
            name: "Booking",
            params: { orderCode: this.getBookingData.order_code },
          })
          .catch(() => {
            this.$router.push({
              name: "Booking",
              params: { orderCode: this.getBookingData.order_code },
            });
          });
      }
    },
    datesDefault() {
      setTimeout(() => {
        document
          .querySelector(".availability__datepicker")
          .dispatchEvent(new Event("click"));
      }, 100);
    },
    toBooking() {},
  },
};
</script>
<style lang="scss" scoped>
.room {
  margin-top: 20px;
  &__wrap {
    background-color: #fff;
    padding: 20px;
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    font-weight: 800;
  }

  &__preview {
    display: flex;
    margin-top: 15px;
  }

  &__img {
    position: relative;
    width: 100px;
    min-width: 100px;
    height: 70px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  &__info {
    flex-grow: 1;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .info {
      &__type {
        font-size: 14px;
        line-height: 14px;
        color: #000;
      }
      &__options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        .options {
          &__main {
            display: flex;
          }
          &__item {
            margin-right: 20px;
            font-size: 14px;
            line-height: 14px;
          }
          &__area {
            display: flex;
            align-items: center;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 16px;
            border-radius: 2px;
            padding: 0 6px;
            height: 18px;
            margin-right: 10px;
            color: #000;
            font-size: 12px;
            background-color: #f6f6f6;
          }
          @media screen and (max-width: 359px) {
            &__area {
              margin-right: 0;
            }
            &__item {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  &__view-price {
    margin-top: 15px;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  &__button {
    flex-basis: calc(50% - 5px);
  }
  &__button-link {
    display: flex;
    justify-content: center;
    color: #000;
  }
  &__error {
    display: block;
    position: relative;
    margin-top: 15px;
    padding: 10px 10px 10px 36px;
    width: 100%;
    background: #fdd2d2;
    font-size: 13px;
    line-height: 17px;
    color: #e10600;

    .icon-app-guest,
    .icon-app-clock {
      position: absolute;
      left: 10px;
      top: 10px;
      font-size: 16px;
    }
    p {
      margin-top: 0;
    }
  }
  &__load {
    position: relative;
    width: 100%;
    height: 240px;
  }
}
.link-btn{
  display: flex;
  justify-content: center;
}
</style>

<i18n lang="json">
{
	"ru": {
    "toSearch": "К поиску"
	},
	"en": {
    "toSearch": "To search"
	}
}
</i18n>
<template>
  <div class="panel" :class="{ inverse }" v-scroll="handleScroll">
    <!-- <router-link
      :to="{
        name: 'Search',
        query: query
      }"
      class="panel__close icon-app-arrow-left"
      v-slot="{ href }"
    >
      <a :href="href" @click="$ga.event('object_sa_m', 'click', 'back2search');"></a>
    </router-link> -->
    <div class="panel__close icon-app-arrow-left" @click="toSearch"></div>
    <div
      @click="onModalCheck"
      class="panel__text"
      v-show="inverse"
      v-if="checkIn && checkOut"
    >
      <span>{{ formatDate(checkIn) }} — {{ formatDate(checkOut) }}</span>
    </div>
    <div class="panel__options">
      <Options :id="id" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { isNavigationFailure } from "vue-router";
import _ from "lodash";
import { isIOS } from "mobile-device-detect";

import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { formatDatePrint } from "@/utils/format";

import { Search } from "@/components/api";
import Options from './Options'
import sutochnoMetrika from "@/utils/sutochnoMetrika";

export default {
  name: "Panel",
  props: {
    id: {
      type: String,
    },
  },
  components: {
    Options,
  },
  data() {
    return {
      inverse: false,
      citySuggestions: "",
    };
  },
  computed: {
    ...mapGetters("search", ["getQuery", "commonParamsForMetrika"]),
    ...mapGetters("detail", ["getObjectData"]),
    ...mapState("search", ["bound"]),
    ...mapState("user", ["isVkApp"]),
    ...mapState("search", ["checkIn", "checkOut"]),
    query() {
      let query = {
        ...this.getQuery.query,
        term: this.getObjectData.location.location,
      };
      // let query = _.pickBy({
      //   occupied: this.getQuery.query.occupied,
      //   guests_adults: this.getQuery.query.guests_adults,
      //   guests_childrens: this.getQuery.query.guests_childrens,
      //   term: this.getObjectData.location.location
      // });
      return query;
    },
  },
  methods: {
    ...mapMutations("detailBooking", ["setModalStep", "setIsModalOpen"]),
    handleScroll(evt, el) {
      if (window.scrollY > 50) this.inverse = true;
      else this.inverse = false;
    },
    getTermSuggestions(request) {
      return Search.getTermSuggestionsWithBoundingBox(request)
        .then((response) => {
          this.citySuggestions = response.data.data.suggestions;
          return response;
        })
        .catch((error) => {
          console.log("Ajax error:", error);
        });
    },
    async toSearch() {
      if (
        document.referrer &&
        !(
          document.referrer.includes("sutochno.ru") ||
          document.referrer.includes("front/searchapp/")
        )
      ) {
        document.location.href = document.referrer;
        return true;
      }
      await this.getTermSuggestions(this.getObjectData.location.location);

      let { location, bbox, title, title_ext } = this.citySuggestions[0];

      // Для перехода с имеющимся query или без него
      const { id, type } = this.getQuery.query;

      if ((id == 1 && type == "country") || !id || !type) {
        this.$store.dispatch("search/setLocation", {
          id: location?.id || 1,
          type: location?.type || "city",
          bbox,
          term: title,
        });
      } else {
        this.$store.dispatch("search/setLocation", {
          id: location?.id || 1,
          type: location?.type || "city",
          bbox: this.bound,
          term: title,
        });
      }

      await this.$nextTick(() => {});

      sendEventGtag("object_sa_m", {
        click: "back2search",
      });

      await new Promise((resolve) => setTimeout(resolve, 300));
      this.$router
        .push({
          name: "Search",
          query: { ...this.getQuery.query, objectScroll: this.id },
        })
        .then((failure) => {
          if (isNavigationFailure(failure)) {
            this.$router.push({
              name: "Search",
              query: { ...this.getQuery.query, objectScroll: this.id },
            });
          }
        });
    },
    /**
     * @description - iosVkScrolHandler - смотрит скрол от общего контейнера приложения и выставляет флаг инверсии true/false
     */
    iosVkScrolHandler() {
      if (document.getElementById("app").scrollTop > 50) {
        this.inverse = true;
      } else {
        this.inverse = false;
      }
    },
    formatDate(value) {
      return formatDatePrint(value);
    },
    onModalCheck() {
      this.setModalStep("calendar");
      this.setIsModalOpen(true);

      sutochnoMetrika.detail("click", "dates ", {
        ...this.commonParamsForMetrika,
        page: "detail",
      });
    },
  },
  mounted() {
    if (this.isVkApp && isIOS) {
      document
        .getElementById("app")
        .addEventListener("scroll", this.iosVkScrolHandler, { passive: true });
    }
  },
  unmounted() {
    if (this.isVkApp && isIOS) {
      document
        .getElementById("app")
        .removeEventListener("scroll", this.iosVkScrolHandler, {
          passive: true,
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.panel {
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  height: 50px;
  font-size: 16px;
  background-color: transparent;
  font-weight: 500;
  color: #fff;
  padding: 0 20px;
  transition: background-color 0.2s ease, color 0.2s ease;

  &.inverse {
    display: flex;
    background-color: #fff;
    color: #444;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);

    .panel__text {
      color: #000;
    }

    .panel__close {
      color: #444;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__close {
    position: absolute;
    left: 20px;
    display: flex;
    align-items: center;
    height: 100%;
    color: #fff;
  }
  &__options {
    position: absolute;
    right: 10px;
    height: 100%;
    :deep() {
      .icon-app-arrow-left, .icon-app-heart-tile, .icon-app-share-simple {
        font-weight: 800;
      }
    }
  }
}
</style>

<template>
  <div
    class="booking"
    :class="{ upper: isModalOpen || errorPopap, 'hide-btn': !visibleBtnMobile }"
  >
    <ButtonDisabled v-if="isDisabled" />
    <ButtonSwitch
      v-else-if="!isModalOpen"
      @setErrorPopap="setErrorPopap"
      :precost="precost"
      :price="checkInCost"
      :sale="bonuses"
      :totalPrice="totalPrice"
      :old-price="getPrices.max_price"
    />
    <Booking
      :precost="precost"
      :price="checkInCost"
      :sale="bonuses"
      :old-price="getPrices.price"
    />
    <!-- v-if="!isModalOpen" -->
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { usePricesObject, useCurrencyCalc, useBonuses } from "@/composables/price";
import { formatPriceCeilForCurrency, formatPriceFloorForCurrency } from "@/utils/format";

import Booking from "./Booking";
import ButtonSwitch from "./Booking/ButtonSwitch";
import ButtonDisabled from "./Booking/ButtonDisabled";

const store = useStore();
const { locale } = useI18n();

const bonuses = useBonuses();
const currencyCalc = useCurrencyCalc();
const getPrices = usePricesObject();

const visibleBtnMobile = computed(() => store.state.detail.visibleBtnMobile);
const isModalOpen = computed(() => store.getters['detailBooking/isModalOpen']);
const isDisabled = computed(() => store.getters['detail/getObjectPermissions'].includes("hasNoShowInSearchResult"));

const checkInCost = computed(() => formatPriceFloorForCurrency(getPrices.value.check_in_cost, currencyCalc.value.title, locale.value));
const totalPrice = computed(() => formatPriceCeilForCurrency(getPrices.value.total_price, getPrices.value.currency, locale.value));
const precost = computed(() => formatPriceCeilForCurrency(getPrices.value.new_pre_cost, getPrices.value.currency, locale.value));

const errorPopap = ref(false);
const setErrorPopap = (value) => errorPopap.value = value;

const getModalQuery = () => {
  const STEP = store.getters['search/getQuery'].query?.step;
  if (STEP) {
    store.commit('detailBooking/setModal', {
      isModalOpen: true,
      step: STEP,
    });
  }
}

onMounted(() => {
  nextTick(() => getModalQuery());
});

</script>

<style lang="scss" scoped>
.booking {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  margin: 0 !important;
  background-color: #fff;
  padding: 17px 20px calc(15px + env(safe-area-inset-bottom));
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
  transition: transform .3s ease;

  &.hide-btn {
    transform: translateY(100%);
  }

  &.upper {
    z-index: 200;
    top: 0;
  }
}
</style>

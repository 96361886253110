<i18n lang="json">
{
	"ru": {
    "btnDate": "Выбрать даты",
    "yes": "Да, продолжить",
    "no": "Нет",
    "clarifyBooking": {
      "party": "Вы бронируете это жильё для вечеринки или мероприятия?",
      "pets": "Вы будете проживать с питомцем?",
      "partyAndPets": "Вы бронируете это жильё для вечеринки (мероприятия) и будете проживать с питомцем?"
    },
    "modalTitle": "Уточните запрос",
    "btnRequest": "Хочу забронировать",
    "btnInstant": "Забронировать",
    "forPrice":"за {price}",
    "btnChoice": "Продолжить",
    "btnNext": "Продолжить",
    "nights": "менее суток | За сутки | За {n} суток | За {n} суток",
    "today": "за сегодня",
    "titleBooking": "Расчет стоимости",
    "inModerate": "Хозяин отредактировал объявление, изменения проверяются модератором. Попробуйте забронировать чуть позже.",
    "error": "Бронирование временно недоступно",
    "ok": "Понятно",
    "prepayment": "предоплата",
    "uponCheckIn": "{price} <span>при заселении</span>",
    "bonuses": "- {bonuses} Бонусов",
    "from": "от ",
    "warn": "Предоплата не менее {n}. Хозяин жилья укажет точную сумму при подтверждении брони."
	},
	"en": {
    "btnDate": "Select dates",
    "yes": "Yes, continue",
    "no": "No",
    "clarifyBooking": {
      "party": "Are you booking this accommodation for a party or event?",
      "pets": "Will you live with your pet?",
      "partyAndPets": "Are you booking this party (event) accommodation and will be accommodating with a pet?"
    },
    "modalTitle": "Refine your request",
    "btnRequest": "Booking",
    "forPrice":"for {price}",
    "btnInstant": "Booking",
    "btnChoice": "Proceed",
    "btnNext": "Proceed",
    "nights": "less night | in {n} night | in {n} nights | in {n} nights",
    "today": "for today",
    "titleBooking": "Calculate price",
    "inModerate": "The owner has edited this ad. The changes are checking by moderator right now. Please try to book it again a little later.",
    "error": "Reservations are temporarily unavailable",
    "ok": "Ok",
    "prepayment": "prepayment",
    "uponCheckIn": "{price} <span>upon check-in</span>",
    "bonuses": "- {bonuses} bonuses",
    "from": "from ",
    "warn": "Prepayment not less than {n}. The host will indicate the exact amount at the time of booking confirmation."
	}
}
</i18n>
<template>
  <div
    v-if="!isPlaceholder"
    class="booking__block"
    :class="{
      'select-date': checkIn && checkOut,
      'booking__grid': !!sale,
      'btn-w100': !isPriceView,
      'new-view': isNewViewBtn
    }"
  >
    <div class="booking__text" :class="{'load': !isSetObjectPrice}" @click="scrollToFormBooking" v-if="isPriceView">
      <template v-if="isSetObjectPrice">
        <p
          v-if="checkIn && checkOut && getObjectPriceErrors.length"
          class="booking__text-price error"
        >
          {{ objectPriceErrorsText }}
        </p>
        <template
          v-else-if="
            precost &&
            price &&
            checkIn &&
            checkOut &&
            isBusinessScheme &&
            !canHold &&
            !isInstant &&
            !objectPriceError
          "
        >
          <p class="booking__precost in-from">
            <em style="font-style: normal"><span>{{t("from")}}</span>{{ precost }}</em>
            <span class="booking__precost-popover">
              {{ t("prepayment") }}&nbsp;
              <VDropdown
                theme="tooltip"
                distance="10"
                placement="top"
                :triggers="['click', 'hover']"
                popper-class="v-popper__popper--max-width-300"
              >
                <span class="icon-app-question"></span>
                <template #popper>
                  <div class="tooltip-text">
                    <p class="text-left" v-html="t('warn', { n: precost })"></p>
                    <div v-close-popper class="tooltip-close"></div>
                  </div>
                </template>
              </VDropdown>
            </span>
          </p>
          <p class="booking__check-in in-from" v-html="t('uponCheckIn', { price: price })"></p>
        </template>
        <template v-else-if="precost && price && checkIn && checkOut && !objectPriceError">
          <p class="booking__precost"><em style="font-style: normal">{{ precost }}</em> <span>{{ t('prepayment') }}</span></p>
          <p class="booking__check-in" v-html="t('uponCheckIn', { price: price })"></p>
        </template>
        <p v-else class="booking__text-price">
          <span class="booking__price tmp-font--average">{{ formatPrice(isPriceDefault ? priceDefault.value : getObjectPrice.price) }}</span>
          <span class="booking__count tmp-font--small">{{ t('today') }}</span>
        </p>
      </template>
      <div v-else class="loading"></div>
    </div>
    <div class="booking__button" :class="{ 'w-100': hideText }">
      <button
        v-if="isStep"
        class="button button_green button_size_md button_w-100"
        :class="{
          'loading button_disabled': btnStepLoading,
          button_disabled: btnDisabled,
        }"
        :disabled="btnDisabled"
        @click="$emit('clickSave')"
      >
        <template v-if="!btnLoading">
          <span v-if="modalStep === 'booking'">{{ t("btnNext") }}</span>
          <span v-else>{{ t("btnChoice") }}</span>
        </template>
      </button>

      <button
        v-else
        class="button button_green button_size_md button_w-100"
        :class="{
          'loading button_disabled': btnLoading || btnStepLoading,
          button_disabled: btnDisabled || btnStepLoading,
        }"
        :disabled="btnDisabled"
        @click="clickBtnSwitch()"
      >
        <template v-if="!btnLoading">
          <template v-if="modalStep === 'calendar' && !checkIn && !checkOut || objectPriceError">
            <span>{{ t("btnDate") }}</span>
          </template>
          <template v-else-if="getInstant">
            <span class="icon-app-lightning"></span>
            <span>{{ t("btnInstant") }}</span>
            <span v-if="oldPrice" class="price-btn-val">&nbsp;{{ t("forPrice", {price: formatPrice(oldPrice)}) }}</span>
          </template>
          <template v-else>
            <span class="on-request-text-old">{{ t("btnRequest") }}</span>
            <span class="on-request-text">{{ t("btnInstant") }}</span>
            <span v-if="oldPrice" class="price-btn-val">&nbsp;{{ t("forPrice", {price: formatPrice(oldPrice)}) }}</span>
          </template>
        </template>
      </button>
    </div>
    <div class="booking__info" v-if="sale && checkIn && checkOut && isPriceView">
      <p class="booking__price-info">
        <span class="booking__price-nights">{{t('nights', getObjectPrice.cnt)}}:&nbsp;</span>
        <span class="booking__price-del">{{ formatPrice(oldPrice) }}</span>
        <span class="booking__check-in">{{ totalPrice }}</span>
      </p>
      <p class="booking__sale">{{ t('bonuses', { bonuses: Math.floor(sale) }) }}</p>
    </div>
    <Modal
      v-model="inModerationModal"
      :title="t('error')"
    >
      <div class="moderate-text tmp-font--medium">{{ t("inModerate") }}</div>
      <template #footer>
        <button
          class="btn btn-primary w-100"
          @click="inModerationModal = false"
        >
          {{ t("ok") }}
        </button>
      </template>
    </Modal>
    <BottomSheet
      v-model="clarifyBookingModal"
      :title="t('modalTitle')"
    >
      <div class="moderate-text tmp-font--medium">
        {{ t(`clarifyBooking.${isGetFilterParams}`) }}
      </div>
      <div class="flex-column">
        <button
            class="btn btn-outline-dark"
            style="margin-top: 15px; line-height: 20px"
            @click="routeBuking">
          {{t('yes')}}
        </button>
        <button
            class="btn btn-outline-dark"
            style="margin-top: 15px; line-height: 20px"
            @click="continueBooking">
          {{t('no')}}
        </button>
      </div>
    </BottomSheet>
  </div>
  <Placeholder v-else />
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import { isNavigationFailure } from "vue-router";
import { Offers } from '@/components/api';
import { event as sendEventGtag } from "@/composables/gtag/index.js";

import Placeholder from "./Placeholders/ButtonSwitch";
import Modal from "@sutochno/modal";
import BottomSheet from '@/components/base/BottomSheet';
import objectTimeLocation from "@/mixins/objectTimeLocation.js";
import dataLayerPushGA4 from "@/mixins/dataLayerPushGA4.js";
import { useI18n } from 'vue-i18n';
import sutochnoMetrika from "@/utils/sutochnoMetrika";
import { formatPriceCeil } from "@/utils/format";

export default {
  name: "ButtonSwitch",
  components: {
    BottomSheet,
    Placeholder,
    Modal
  },
  mixins: [objectTimeLocation, dataLayerPushGA4],
  props: {
    isStep: {
      type: Boolean,
      default: false,
    },
    price: {
      type: [String, Number],
      default: '0 Р'
    },
    precost: {
      type: [String, Number],
      default: '0 Р'
    },
    sale: {
      type: [String, Number],
      default: 0
    },
    totalPrice: {
      type: String,
      required: true,
    },
    oldPrice: {
      default: 0
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      bookingView: false,
      inModerationModal: false,
      clarifyBookingModal: false,
      target: null,
    };
  },
  computed: {
    ...mapState("search", ["checkIn", "checkOut", "filters"]),
    ...mapState("detailBooking", ["modal", "booking", "canHold"]),
    ...mapState("detail", ["isSetObjectPrice", "currentOffer", "objectPriceError"]),
    ...mapGetters("detail", [
      "isInModerationStatus",
      "getObjectPrice",
      "getObjectPriceErrors",
      "getObjectData",
      "getInstant",
      "getBookingData",
      "getObjectPermissions",
    ]),
    ...mapGetters("detailBooking", [
      "modalStep",
      "btnDisabled",
      "btnLoading",
      "petsObject",
      "bookingLoading",
      "isInstant"
    ]),
    ...mapGetters("search", ["guestsSend"]),
    ...mapState("user", ["isAuth", "userData"]),
    isBusinessScheme(){
      if (!this.getObjectPermissions) return false;
      if(
        this.getObjectPermissions.includes("hasOwnerServiceConditionBusiness") ||
        this.getObjectPermissions.includes("hasOwnerServiceConditionBusinessmaximum")
      ) return true;

      return false;
    },
    userPhones() {
      return this.userData?.user?.contacts?.filter((contact) => Number(contact.type_id) === 2);
    },
    isGetFilterParams() {
      // дает добро для открытия модалки сопоставляя парметры фильтра и правила размещения
      // отвечает за показ нужного текста в модалке
      const properties =
        this.getObjectData?.properties?.accommodation_rules?.properties;
      if (!properties) return false;
      const party = properties.find((item) => item.name === "party");
      if (this.filters.party) {
        return party?.value === 2 ? "party" : false;
      }
      if (this.filters.party) {
        if (party?.value === 2) return "party";
        return false;
      }
      return false;
    },
    isPriceDefault() {
      return !!(
        !this.getObjectPrice ||
        !this.getObjectPrice.price ||
        this.getObjectPrice.error
      );
    },
    priceDefault() {
      return this.getObjectData.extprices.find(
        (item) => item.interval_start === 0
      );
    },
    hideText() {
      return this.isStep && this.modalStep === "booking";
    },
    btnStepLoading() {
      // в шаге booking может быть ожидание ответа api и контент может еще не отобразиться
      if (this.modalStep === "booking")
        return this.btnLoading || this.bookingLoading;
      else return this.btnLoading;
    },
    isPlaceholder() {
      return (
        !this.isStep &&
        (this.checkIn || this.checkOut ? !this.getObjectPrice : false) && !this.objectPriceError
      );
    },
    todayTimeBegin() {
      if (this.isTodayBooking) {
        const instantRules = this.getObjectData?.properties?.instant_rules?.properties.find(item => item.name === "before_hours");
        let beforeHours = instantRules ? instantRules.value : 0;
        if (beforeHours === 24 || !this.isInstant) {
          beforeHours = 0;
        }
        let time = this.objectTime
          ? Number(this.objectTime.hour) + beforeHours + 1
          : 0;
        return time >= 23 ? 23 : time;
      } else {
        return 0;
      }
    },
    // fix время из dates
    parseTime() {
      if (this.getObjectData) {
        const dateIn = Number(
          this.getObjectData.properties.check_in_out_rules
            ? this.getObjectData.properties.check_in_out_rules.properties
                .find((c) => c.name == "check_in")
                .value.toString()
                .match(/\d{2}/)
            : this.getObjectData.properties.additional_payment.properties
                .find((c) => c.name == "check_in")
                .value.toString()
                .match(/\d{2}/)
        );

        const dateOut = Number(
          this.getObjectData.properties.check_in_out_rules
            ? this.getObjectData.properties.check_in_out_rules.properties
                .find((c) => c.name == "check_out")
                .value.toString()
                .match(/\d{2}/)
            : this.getObjectData.properties.additional_payment.properties
                .find((c) => c.name == "check_out")
                .value.toString()
                .match(/\d{2}/)
        );

        return {
          in: this.todayTimeBegin ? this.todayTimeBegin : dateIn ? dateIn : 14,
          out: dateOut ? dateOut : 12,
        };
      } else {
        return {
          in: this.todayTimeBegin ? this.todayTimeBegin : 14,
          out: 12,
        };
      }
    },
    isPriceView() {
      return this.modal.isModalOpen && this.modal.step === "booking" ? false : true
    },
    isNewViewBtn() {
      return this.userData && !this.userData?.balance_virtual;
    },
    objectPriceErrorsText() {
      return this.getObjectPriceErrors.join(" ");
    }
  },
  watch: {
    getObjectPrice(val) {
      if (!!val?.error?.cnt_min || !!(!this.checkIn && !this.checkOut)) {
        this.setModalStep("calendar");
      }
    },
    isPriceDefault(val) {
      if (!this.isStep && !val && this.checkIn && this.checkOut) this.setModalStep("booking");
    },
    inModerationModal(){
      this.$emit("setErrorPopap", this.inModerationModal)
    },
  },
  methods: {
    ...mapMutations("detailBooking", [
      "setModalStep",
      "setIsModalOpen",
      "setBookingLoading",
      "setIsPets"
    ]),
    ...mapActions("booking", ["setParams", "updateOrder", "bookingToAction"]),
    ...mapActions("detail", ["setBookingData", "onSubmitAnalytics"]),
    ...mapActions("detailBooking", ["routeBooking"]),
    formatPrice(num) {
      return formatPriceCeil(num, this.getObjectPrice?.currency?.code || "RUB", this.$i18n.locale);
    },
    async initBookingAction() {
      this.setBookingLoading(true);
      // fix время из dates
      const dateIn = new Date(
          new Date(this.checkIn).setHours(this.parseTime.in)
      );
      const dateOut = new Date(
          new Date(this.checkOut).setHours(this.parseTime.out)
      );
      await this.setBookingData({
        guests: this.guestsSend,
        date_begin: dateIn,
        date_end: dateOut,
        object_id: this.getObjectData.id,
        price: this.getObjectPrice.price,
        is_pets: this.petsObject.isPets ? 1 : 0,
        pets: this.petsObject.pets,
        phone: this.booking.selectedPhone,
        target: this.target,
        name: this.userData?.user?.name,
      }).then((response) => {
        if (this.openOldBooking) {
          // https://jira.webpower.ru/browse/VUECLIENT-1811
          // this.dataLayerPush("add_to_cart");
          // если животные и вечеринка по запросу и в фильтрах выбран один из них то открывется старая страница брони
          this.$router
              .push({
                name: "Booking",
                params: { orderCode: this.getBookingData.order_code },
              })
              .then((failure) => {
                if (isNavigationFailure(failure)) {
                  this.$router.push({
                    name: "Booking",
                    params: { orderCode: this.getBookingData.order_code },
                  });
                }
              })
          return false;
        }
        if (response.data?.data?.order_code) {
          // https://jira.webpower.ru/browse/VUECLIENT-1811
          // this.dataLayerPush("add_to_cart");
          this.setParams({ draftCode: response.data.data.order_code }).then(
              (response) => {
                this.setBookingLoading(false);
                // костыль на проверку гостей, если мы не были на шаге заполнения гостей
                this.updateOrder({
                  name: "guests",
                });
                this.onSubmitAnalytics();
                this.dataLayerPush('view_item')
              }
          );
        }
      });
    },
    async clickBtnSwitch() {
      sutochnoMetrika.detail("click", "book",{
        objectIdsArray: this.getObjectData.id,
        userID: this?.userData?.user?.id,
        userPhone: this.userPhones?.at(0)?.value,
        dateIn: this.checkIn,
        dateOut: this.checkOut,
        guests: JSON.stringify(this.guests),
        cost: this.oldPrice - this.sale,
        page: "draft",
      });
      if(this.currentOffer) {
        const data = await Offers.setBookingByOffer({ offerId: this.currentOffer.id })
        const { order_id: orderId } = data?.data?.data
        if(!orderId) {
          return;
        }
        const { protocol, host } = window.location;
        window.location = `${protocol}//${host}/pay/${orderId}`
        return;
      }
      if (this.isGetFilterParams && this.modalStep === "booking") {
        this.clarifyBookingModal = true;
      } else {
        this.clickDefault();
      }
    },
    async clickDefault() {
      if(this.isInModerationStatus){
        this.inModerationModal = true;
        return false;
      }
      if(!this.openOldBooking){
        this.setIsModalOpen(true);
      }
      if (this.modalStep === "calendar") {
        sendEventGtag("draft_sa_m", {
          click: "btn_object_date_open",
        });
        return;
      } else if (this.getObjectPrice && this.getObjectData) {
        await this.initBookingAction()
      }
    },
    scrollToFormBooking () {
      const form = document.querySelector('.booking-form');
      if (form) {
        const offsetTop = form.offsetTop;
        const scrollY = window.scrollY;
        window.scrollBy({
          top: -(scrollY - offsetTop + 30),
          left: 0,
          behavior: 'smooth'
        });
      }
    },
    routeBuking() {
      this.openOldBooking = true;
      this.clarifyBookingModal = false;
      this.target = this.isGetFilterParams ==="party" ? 4 : null;
      this.clickDefault();
    },
    continueBooking() {
      this.clarifyBookingModal = false;
      this.clickDefault();
    },
  },
};
</script>

<style lang="scss" scoped>

.flex-column {
  display: flex;
  flex-direction: column;
}
.icon-app-question{
  font-size: 15px;
}

.clarify-modal {
  :deep() {
    .sc-modal-header {
      padding: 0 20px;
      text-indent: 0;
    }
    .sc-modal-footer{
      padding: 30px 20px 0px;
    }
  }
  .moderate-text{
    padding: 20px;
  }
}
.modal-flex {
  display: flex;
  justify-content: center;
}
.btn-left{
  margin-right: 20px;
}

.moderate-text {
  padding: 10px;
}

.booking {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  margin: 0 !important;

  &.upper {
    z-index: 200;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-app-lightning {
      font-size: 16px;
      padding-right: 10px;
    }
  }

  .booking {
    &__grid {
      display: grid;
      grid-template-areas: "precost btn" "info info";
      grid-template-columns: 1fr 1fr;
      &.btn-w100 {
        grid-template-areas: "btn";
        grid-template-columns: 1fr;
      }

      .booking__text {
        grid-area: precost;
        position: relative;
        top: 1px;
      }
      .booking__button {
        grid-area: btn;
        width: 100%;
      }
      .booking__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-area: info;
        margin-top: 10px;
        font-size: 12px;
      }
      .booking__price-info {
        display: flex;
        align-items: center;
        color: #717171;
      }
      .booking__price-del {
        margin-right: 5px;
        &::before {
          content: '';
          display: block;
          width: 100%;
          border-top: 1px solid #ff0000;
          transform: rotate(-10deg) translate(0px, 8px);
        }
      }
      .booking__sale,
      .booking__check-in {
        margin: 0;
      }
      .booking__sale {
        color: #e10600;
        font-weight: 500;
        text-align: right;
      }
    }

    &__text,
    &__button {
      width: 50%;
    }
    &__button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    &__text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      font-size: 12px;
      &-price {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 12px;
        &.error {
          color: #ff0000;
        }
      }
    }
    &__precost {
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 16px;
      &-popover{
        display: inline-flex;
        align-items: center;
        flex-wrap: nowrap;
      }
      span {
        font-size: 12px;
      }
    }
    &__check-in {
      margin-top: 4px;
      color: #717171;
    }
    &__price {
      color: #417505;
      font-weight: bold;
    }
    &__count {
      margin-left: 5px;
    }
  }
  &__block {
    width: 100%;
    display: flex;
    align-items: center;
  }
}
.moderate-text{
  padding: 15px 10px;
}

.price-btn-val {
  display: none;
}

.on-request-text {
  display: none;
}

.booking .booking__precost-popover {
  margin-left: 4px;
}
.booking__block.new-view.select-date {
  .booking__precost-popover {
    margin-left: 0;
  }
  .booking__precost {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    font-size: 14px;
    color: #000000;
    white-space: nowrap;
    margin-bottom: 1px;
    span {
      margin-right: 4px;
      font-size: 14px;
      &:first-letter {
        text-transform: uppercase;
      }
      &.booking__precost-popover {
        margin-right: 0;
        text-transform: capitalize;
      }
    }
    em {
      span {
        margin-right: 0;
      }
    }
    &.in-from {
      position: relative;
      padding-right: 22px;
      .v-popover {
        position: absolute;
        right: 0;
        top: 1px;
      }
    }
  }
  .booking__check-in {
    white-space: nowrap;
  }
  .booking__text {
    width: auto;
    padding-right: 20px;
  }
  .booking__button {
    width: 100%;
  }
  .button {
    padding: 0 12px;
  }
  .button.button_green {
    background: #6fa759;
    border-color: transparent;
    line-height: 46px;
    min-height: 48px;
  }
}
</style>

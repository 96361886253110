<i18n lang="json">
{
  "ru": {
    "title": "Подтвердите телефон",
    "textModal": {
      "0": "Мы отправили вам пароль в СМС на номер",
      "1": "Мы отправили вам код в СМС на номер"
    },
    "textError": "Проверьте правильность введенного {n}",
    "receiveYourPassword": {
      "0": "Пароль не пришел?",
      "1": "Код не пришел?"
    },
    "retrievePasswordAgain": "Получить {n} повторно",
    "textsuccess": "Телефон успешно проверен",
    "confirmationPayment": "К подтверждению и оплате…",
    "youCanRequestNewPasswordVia":"Вы можете запросить новый {n} через",
    "minutes": "0|{n} минуту |{n} минуты|{n} минут",
    "seconds": "0|{n} секунду |{n} секунды|{n} секунд",
    "password": "пароль | пароля",
    "code": "код | кода",
    "continue": "Продолжить",
    "enterCode": "Ошибка авторизации, введите код с картинки через:"
  },
  "en": {
    "title": "Confirm phone",
    "textModal": {
      "0": "We have sent you a password in SMS to the number",
      "1": "We have sent you a code in SMS to the number"
    },
    "textError": "Check that {n} entered is correct",
     "receiveYourPassword": {
      "0": "Password not received?",
      "1": "Code not received?"
    },
    "retrievePasswordAgain": "Retrieve {n} again",
    "textsuccess": "Phone verified successfully",
    "confirmationPayment": "For confirmation and payment ...",
    "youCanRequestNewPasswordVia":"You can request a new {n} via",
    "minutes": "0|{n} minute |{n} minutes|{n} minutes",
    "seconds": "0|{n} second |{n} seconds|{n} seconds",
    "password": "password | password",
    "code": "code | code",
    "continue": "Continue",
    "enterCode": "Authorization error, enter the code from the image via:"
  }
}
</i18n>
<template>
  <!-- модалка для ввода и авторизации -->
  <BottomSheet
    v-model="isModalCode"
    class="modal-sms"
    :class="{ 'modal-sms--loading': isLoading }"
    :title="t('title')"
    :is-loading="isLoading"
  >
    <template v-if="getCaptchaData?.captcha_image">
      <Captcha
        v-model="captchaValue"
        :captchaImage="getCaptchaData.captcha_image"
      />
      <div class="field-error" v-if="currentTime">
        {{ t("enterCode") }}
        <template v-if="Math.floor(currentTime / 60)">{{ t("minutes", Math.floor(currentTime / 60)) }}</template>
        {{ t("seconds", currentTime % 60) }}
      </div>
      <button 
        class="button button_w-100 button_green button_size_md captcha_submit" 
        :class="{'loading': isLoading}" 
        @click="sendCode(true)"
        :disabled="buttonDisabled"
      >
        {{ t("continue") }}
      </button> 
    </template>
    <template v-else>
      <template v-if="!isPartner">
        <template v-if="isLoading">
          <div class="shadow"></div>
          <p class="ph-item ph-item--h34"></p>
        </template>
        <p v-else class="tmp-font--medium">
          {{ t(isNewUser ? "textModal.0" : "textModal.1") }}
          <span v-if="inputPhoneNumberToString" class="modal-sms__phone">{{
            inputPhoneNumberToString
          }}</span>
        </p>
        <div class="inputs-wrap">
          <input
            v-for="(el, i) in 5"
            :key="i"
            :ref="`input${i}`"
            :class="{
              'input--invalid': error,
              'input--success': success,
            }"
            class="input"
            type="number"
            inputtype="numeric"
            maxLength="1"
            size="1"
            min="0"
            max="9"
            pattern="\d [0-9]"
            @keyup="fillCode($event, i)"
            @keydown="onKeyDown"
            @paste="onPaste"
            @change="onInput"
            @input="onInput"
            @click="selectInput(i)"
          />
        </div>
        <p v-if="isLoading" class="ph-item"></p>
        <template v-else>
          <span v-if="retrievePasswordAgain">
            <p v-if="error" class="error tmp-font--small">
              <template v-if="textError">
                {{ textError }}
              </template>
              <template v-else>
                {{
                  t("textError", {
                    n: isNewUser ? t("password", 1) : t("code", 1),
                  })
                }}
              </template>
            </p>
            <template v-if="currentTime > 1 && btnAuth">
              <div class="new-password-via">
                {{
                  t("youCanRequestNewPasswordVia", {
                    n: isNewUser ? t("password", 0) : t("code", 0),
                  })
                }}<br />
                {{ t("minutes", Math.floor(currentTime / 60)) }}
                {{ t("seconds", currentTime % 60) }}
              </div>
            </template>
            <template v-else>
              <span class="tmp-font--small color-gray">{{
                t(
                  isNewUser ? "receiveYourPassword.0" : "receiveYourPassword.1"
                )
              }}</span>
              &nbsp;
              <a
                class="link tmp-font--link"
                @click.prevent="sendCodeAgain()"
                @mousedown.prevent=""
                >{{
                  t("retrievePasswordAgain", {
                    n: isNewUser ? t("password", 0) : t("code", 0),
                  })
                }}</a
              >
            </template>
          </span>
          <!-- <div v-if="pending" class="loading loader"></div> -->
          <template v-if="success">
            <p class="tmp-font--small success">{{ t("textsuccess") }}</p>
            <div class="loading loader loading-success"></div>
            <p class="tmp-font--small redirect-text">
              {{ t("confirmationPayment") }}
            </p>
          </template>
        </template>
      </template>
      <template v-else>
        <PartnerHint :is-mobile="true" />
        <div class="partner-modal__button">
          <button
            class="button button_blue button_size_md button_w-100"
            @click="nextStep"
          >
            {{ t("continue") }}
          </button>
        </div>
      </template>
    </template>
  </BottomSheet>
</template>
<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import { isNavigationFailure } from "vue-router";
import _ from "lodash";
import { event as sendEventGtag } from "@/composables/gtag/index.js";
import { Detail } from "@/utils/gtm/";

//TODO: TMPL-1598 - отрефакторить в нормальную модалку чтобы ее можно было переиспользовать и завести с текстовые константы и логичным неймингом стилем
import { Users } from "@/components/api/";
import { formatWithTime } from "@/utils/format";
import BottomSheet from "@/components/base/BottomSheet";
import PartnerHint from "@/components/base/PartnerHint";
import Captcha from "@sutochno/captcha";
import { useI18n } from 'vue-i18n';

export default {
  name: "ModalCode",
  components: {
    BottomSheet,
    PartnerHint,
    Captcha,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      codeAccess: [null, null, null, null, null],
      error: null,
      retrievePasswordAgain: true,
      success: false,
      pending: false,
      isBooking: false, //бронирование
      currentTime: 0,
      timer: null,
      btnAuth: false,
      textError: "",
      isPartner: false,
      excludeKeys: ["Delete", "Backspace"],
      isLoading: false,
      isNewUser: false,
      allTime: 0,
      captchaValue: "",
      captchaData: null,
      passwordForNewUser: false
    };
  },
  computed: {
    ...mapState("user", ["whitelabel", "isAuth"]),
    ...mapState("booking", [
      "password",
      "getPassword",
      "draftType",
      "warningInfo",
    ]),
    ...mapState("detailBooking", ["booking"]),
    ...mapState("search", ["checkIn", "checkOut"]),
    ...mapGetters("booking", ["params", "paramsWarning", "bookingPrice"]),
    ...mapGetters("detail", ["getObjectData"]),
    ...mapGetters("detailBooking", [
      "inputPhoneNumber",
      "inputPhoneNumberToString",
      "isPhoneCorrect",
    ]),
    ...mapGetters("search", ["maxGuests"]),
    userBalance() {
      return this.userData?.balance
        ? Number(this.userData.balance.split("<span")[0].replace(/\s/g, ""))
        : 0;
    },
    isDefaultData() {
      return !(
        this.params &&
        (this.params.is_doc ||
          this.params.is_pets ||
          this.params.target === 4 ||
          this.params.is_legal_person)
      );
    },
    isDefaultTime() {
      const TIME_BEGIN = this.warningInfo.time_begin;
      const TIME_END = this.warningInfo.time_end;
      return !(
        TIME_BEGIN.error ||
        TIME_END.error ||
        TIME_BEGIN.warning ||
        TIME_END.warning
      );
    },
    btnHold() {
      const CAN_HOLD =
        !this.btnView &&
        this.isDefaultData &&
        this.isDefaultTime &&
        this.userBalance === 0 &&
        this.bookingPrice?.can_hold;
      this.setCanHold(CAN_HOLD);
      this.setCanHoldDetail(CAN_HOLD);
      return CAN_HOLD;
    },
    btnView() {
      if (this.draftType === "mb" || this.draftType === "hot") {
        return true;
      } else if (this.draftType === "order") {
        return false;
      }
      if (this.btnInit) {
        if (
          this.params &&
          ((this.params.is_doc && this.paramsWarning.documents) ||
            (this.params.is_pets && this.paramsWarning.pets) ||
            (this.params.target == 4 && this.paramsWarning.party) ||
            this.params.is_legal_person)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    isModalCode: {
      get() {
        return this.booking.isModalCode;
      },
      set(val) {
        this.setBookingIsModalCode(val);
      },
    },
    getCaptchaData() {
      if (this.captchaData?.captcha_code) {
        return this.captchaData;
      }
      return null;
    },
    buttonDisabled() {
      return !this.captchaValue || this.currentTime;
    }
  },
  watch: {
    isModalCode(val) {
      if (val && this.isPhoneCorrect) {
        this.init();
      }
      if (!val) {
        this.error = false;
        this.codeAccess = [null, null, null, null, null];
      }
    },
    btnHold(val) {
      this.setCanHoldDetail(val);
    },
  },
  methods: {
    ...mapMutations("booking", [
      "getPasswordMutate",
      "setCanHold",
      "passwordMutate",
    ]),
    ...mapMutations("detailBooking", [
      "setCanHoldDetail",
      "setBookingIsModalCode",
      "setIsModalOpen",
    ]),
    ...mapActions("booking", [
      "authAction",
      "codeAction",
      "getMobiles",
      "setBookingPriceData",
    ]),
    ...mapActions("detailBooking", ["updateSelectedPhone", "routeBooking"]),
    ...mapActions("user", ["loadUserData"]),
    nextStep() {
      sessionStorage.setItem("wl_modal_pratner", JSON.stringify(true));
      this.success = true;
      this.routeBookingLocal();
    },
    onInput(e) {
      const VALUE = e.target?.value?.trim();
      if (VALUE?.length === 5) {
        for (var i = 0; i < VALUE.length; i++) {
          let char = Number(VALUE[i]);
          this.codeAccess[i] = char;
          this.$refs[`input${i}`][0].value = char;
        }
        this.checkCode();
      }
      e.preventDefault();
      return false;
    },
    onPaste(e) {
      //e.target.value будет пустым хотя если вывести event там все будет, какой то баг
      //поэтому нашел такой костыль
      const VALUE = e.clipboardData.getData("text").trim();
      if (VALUE.length === 5) {
        for (var i = 0; i < VALUE.length; i++) {
          let char = Number(VALUE[i]);
          this.codeAccess[i] = char;
          this.$refs[`input${i}`][0].value = char;
        }
        this.checkCode();
      }
      e.preventDefault();
      return false;
    },

    init() {
      this.isLoading = true;
      this.error = false;
      this.sendCode();
    },
    onKeyDown(event) {
      const VALUE = event.target.value || event.key;

      if (VALUE && VALUE.length > 0) {
        event.target.value = VALUE[0];
        event.preventDefault();
      }

      if (this.excludeKeys.includes(event.key) || /^\d+$/.test(VALUE)) {
        return true;
      }

      event.target.value = "";
      event.preventDefault();
      return false;
    },

    fillCode(event, index) {
      const VALUE = event.target.value || event.key;

      if (this.excludeKeys.includes(event.key)) {
        //если клавиши удаления то очищаем
        this.$refs[`input${index}`][0].value = "";
        this.codeAccess[index] = null;
        const prevInput = this.$refs[`input${index - 1}`]
          ? this.$refs[`input${index - 1}`][0]
          : null;
        if (prevInput) {
          prevInput.select();
        }
        return true;
      } else if (/^\d+$/.test(VALUE)) {
        // если цифры, то заполняем
        // const val = this.$refs[`input${index}`][0].value;
        this.codeAccess[index] = Number(VALUE);
        if (!this.codeAccess.includes(null)) {
          this.checkCode();
        }
        const nextInput = this.$refs[`input${index + 1}`]
          ? this.$refs[`input${index + 1}`][0]
          : null;
        if (nextInput) {
          nextInput.select();
        } else {
          this.$refs[`input${index}`][0].blur();
        }
        return true;
      } else {
        event.target.value = "";
        event.preventDefault();
        return false;
      }
    },
    selectInput(numInput) {
      if (this.$refs[`input${numInput}`][0]) {
        this.$refs[`input${numInput}`][0].select();
      }
    },
    clearInput() {
      setTimeout(() => {
        this.codeAccess.forEach((item, index) => {
          this.codeAccess[index] = null;
          const input = this.$refs[`input${index}`]
            ? this.$refs[`input${index}`][0]
            : null;
          if (input) {
            input.value = "";
            input.classList.remove("input--invalid");
          }
        });
      }, 40);
    },
    startTimer() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.currentTime > 0) {
            this.currentTime--;
          } else {
            clearTimeout(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async checkCode(
      phone = this.inputPhoneNumber,
      code = this.codeAccess.join(""),
      terms = 1,
      captcha_code = this.getCaptchaData?.captcha_code
    ) {
      this.pending = true;
      if (this.isAuth && this.phone) {
        await this.approveContact();
      } else {
        await this.codeAction({ phone, code, terms, captcha_code }).then((res) => {
          this.pending = false;
          if (!res.data?.success) {
            Detail.gaLoginStatus("error", "sms");
            sendEventGtag("draft_sa_m", {
              click: "pwd_error",
            });
            this.error = true;
            this.retrievePasswordAgain = true;
            this.currentTime = res.data.data.timeout || 0;
            if (this.currentTime > 1) {
              this.btnAuth = true;
              this.startTimer();
            }
          }
          if (res.data?.success) {
            Detail.gaLoginStatus("success", "sms");

            this.setBookingPriceData({
              guests: this.maxGuests,
              time_begin: this.getObjectData.checkIn, //надо показывать время объекта иначе ошибке на беке
              time_end: this.getObjectData.checkOut, //надо показывать время объекта иначе ошибке на беке
              date_begin: formatWithTime(this.checkIn),
              date_end: formatWithTime(this.checkOut),
              objects: this.getObjectData.id,
              rooms_cnt: 1,
              no_time: 0, // флаг 0 потому что time_begin и time_end
            }).then(() => {
              this.loadUserData();
              this.updateSelectedPhone(this.inputPhoneNumber);

              sendEventGtag("draft_sa_m", {
                click: "pwd_ok",
              });
              this.error = false;
              this.retrievePasswordAgain = false;
              this.success = true;
              this.btnAuth = false;
              this.getMobiles();
              if (
                !sessionStorage.getItem("wl_modal_pratner") &&
                this.whitelabel
              ) {
                this.isPartner = true;
              } else {
                this.routeBookingLocal();
              }
            });
          }
          this.checkCaptcha(res);
          if (Array.isArray(res.data.errors) && res.data.errors.length) {
            this.textError =
              res.data.errors[0] == "логин или пароль указаны неверно"
                ? this.t("textError")
                : res.data.errors[0];
          }
        }).catch(() => {
            Detail.gaLoginStatus("error", "sms");
          });
      }
      this.pending = false;
    },
    sendCode(isSendCapchaCode) {
      if (this.error) {
        this.clearInput();
      }
      if (!this.getPassword) {
        this.getPasswordMutate(true);
      }
      this.$nextTick(() => this.passwordMutate(""));
      let params = { phone: this.inputPhoneNumber, terms: 1, forgot: this.passwordForNewUser ? 1 : "" };
      if (isSendCapchaCode) {
        params = {
          phone: this.inputPhoneNumber,
          value: this.inputPhoneNumber,
          terms: 1,
          captcha_code: this.getCaptchaData.captcha_code,
          captcha_value: this.captchaValue,
        };
      }
      // методы для авторизованного пользователя
      if (this.isAuth) {
        this.addContact(this.inputPhoneNumber);
      } else {
        this.codeAction(params)
          .then((res) => {
            this.checkCaptcha(res);
            if (res.data.actions.auth) {
              this.isNewUser =
                res.data.actions.auth === "code sent" ? false : true;
              this.passwordForNewUser =
                res.data.actions.auth === "show password for new user" ? true : false;
            } else {
              this.isNewUser = true;
              this.passwordForNewUser = false;
            }
            this.allTime = res.data.data.timeout || 7;
            this.currentTime = res.data.data.timeout || 0;
            if (this.currentTime > 1) {
              this.btnAuth = true;
              this.startTimer();
            }
            if (Array.isArray(res.data.errors) && res.data.errors.length) {
              this.textError = res.data.errors[0];
            }

            if(isSendCapchaCode) {
              const captchaCode = res.data?.data?.messages?.captcha_code;

              sendEventGtag("login_captcha", {
                show: captchaCode ? "error" : "success", 
                login_type: 'fast'
              });
            }
            this.isLoading = false;
          })
          .catch((err) => {
            if (err) console.error(err);
            this.error = true;
            this.textError = "ошибка запроса кода";
            this.isLoading = false;
          });
      }
    },
    sendCodeAgain() {
      this.btnAuth = true;
      if (this.isAuth) {
        this.requestApproveContact(this.inputPhoneNumber);
      } else {
        this.sendCode();

        if(this.passwordForNewUser) {
          sendEventGtag("login_interference", {
            click: 'show_password_new_user',  
            login_type: 'fast'
          });
        }
      }
    },
    // добавление телефона в лк пользователя
    addContact(phone) {
      // this.isPhoneError = false;
      let formData = new FormData();

      formData.append("contactValue", phone || "");
      formData.append("typeId", "2");
      formData.append("isLandline", "0");

      Users.addContact(formData)
        .then((response) => {
          // если нас просит редиректнуть на повторную отправку, то выходим
          if (
            response.data.actions &&
            response.data.actions.redirect === "users/requestApproveContact"
          ) {
            this.requestApproveContact(phone);
          } else if (
            Array.isArray(response.data.errors) &&
            response.data.errors.length
          ) {
            this.textError = response.data.errors[0];
          }
          // есть еще сценарий где в ответе приходит контакт, но это неприципиально т.к.
          // requestApproveContact приходится запрашивать в любом случае
          this.isLoading = false;
        })
        .catch((err) => {
          if (err) console.error(err);
          this.error = true;
          this.textError = "ошибка добавления номера";
          this.isLoading = false;
        });
    },
    //повторный запрос пароля
    requestApproveContact(phone) {
      let formData = new FormData();
      formData.append("value", phone);
      Users.requestApproveContact(formData)
        .then((res) => {
          this.currentTime = res.data.data.timeout || 0;
          if (res.data.data.timeout > 0) {
            this.btnAuth = true;
            this.startTimer();
          }
          this.isLoading = false;
        })
        .catch((err) => {
          if (err) console.error(err);
          this.error = true;
          this.textError = "ошибка повторного запроса пароля";
          this.isLoading = false;
        });
    },
    routeBookingLocal() {
      this.routeBooking().catch((err) => {
        console.warn(err);
        // VUECLIENT-819
        // Миша сказал перенаправлять при всех ошибках
        // в routeBooking есть throw
        this.$router
          .push({
            name: "Booking",
            params: { orderCode: this.params.code },
          })
          .then((failure) => {
            if (isNavigationFailure(failure)) {
              this.$router.push({
                name: "Booking",
                params: { orderCode: this.params.code },
              });
            }
          });
        setTimeout(() => {
          this.setIsModalOpen(false);
        }, 300);
      });
    },
    showCaptcha(messages) {
      this.captchaValue = "";
      this.captchaData = messages;
    },
    removeCaptcha() {
      this.captchaValue = "";
      this.captchaData = null;
    },
    checkCaptcha(res) {
      if (res.data?.data?.messages?.captcha_code) {
        const messages = res.data.data.messages;
        const timer =  messages.timeout ? messages.timeout : 1;
        const codeAccess = JSON.stringify(this.codeAccess);
        this.showCaptcha(messages)
        this.isLoading = false;

        setTimeout(() => {
          if(res.data?.actions?.auth === "resend request" && 
            timer <= 10 && codeAccess === JSON.stringify(this.codeAccess) 
          ) {
            this.checkCode();
          }
        }, +(timer + '000'));
      } else {
        this.removeCaptcha();
      }
    },
  },
  mounted() {
    if (this.isPhoneCorrect) {
      this.init();
    }
    this.$nextTick(() => {
      this.selectInput(0);
    });
  },
};
</script>
<style lang="scss" scoped>
.modal-sms {
  .wrapper {
    width: 100%;
    height: auto;

    position: relative;
    z-index: 500;
  }
  .new-password-via {
    // padding-top: 8px;
    font-size: 12px;
  }
  .inputs-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 15px 0 20px;
    .input {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      border: solid 1px #d8d8d8;
      margin-right: 15px;
      border-radius: 5px;
      padding: 1px 15px;
      min-height: 51px;
      max-height: 51px;
      max-width: 100%;
      width: 100%;
      text-align: center;
      box-shadow: none;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      appearance: textfield;
      &:last-child {
        margin-right: 0;
      }
      &:focus {
        outline: none;
        border: solid 1px #000000;
      }
      &.letter-spacing {
        letter-spacing: 10px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .input--success {
      border-color: #417505;
    }
    .input--invalid {
      border-color: #e10600;
    }
  }
  .success {
    color: #417505;
  }
  .error {
    color: #e10600;
  }
  .link {
    color: #2d6cb4;
  }
  .close {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .loader {
    height: 25px;
    position: relative;
    margin: 30px 0 10px;
    &:before {
      display: none;
    }
    &.loading-success {
      margin: 30px 0 14px;
    }
  }
  .redirect-text {
    text-align: center;
    color: #717171;
  }
  &.modal-sms--loading {
    :deep(.bottom-sheet__body) {
      position: relative;
      overflow: hidden;
    }

    .shadow {
      position: absolute;
      left: -10%;
      top: -10%;
      bottom: -10%;
      width: 30px;
      opacity: 0;
      background: #fff;
      animation: loader 1.3s linear infinite;
      filter: blur(20px);
      transform: rotate(25deg);
    }
    .ph-item {
      width: 100%;
      height: 17px;
      background: #d8d8d8;
      border-radius: 10px;

      &--h34 {
        height: 34px;
      }
    }
  }
  &__phone {
    white-space: nowrap;
  }
}

.intersect {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.cancelBooking {
  width: 100%;
  line-height: 40px;
  min-height: 42px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #000;
}
.color-gray {
  color: #717171;
}

@keyframes loader {
  from {
    left: -10%;
    opacity: 0;
  }
  10% {
    left: 10%;
    opacity: 1;
  }
  90% {
    left: 90%;
    opacity: 1;
  }
  to {
    left: 110%;
    opacity: 0;
  }
}
.captcha_submit {
  margin-top: 10px;
}
.button:disabled {
  opacity: .65;
}
.field-error {
  color: red;
  font-size: 14px;
  margin: 10px 0;
}
</style>


<i18n lang="json">
{
  "ru": {
    "dop_persons": "доплата за {n} гостей | доплата за {n} гостя | доплата за {n} гостей",
    "interval": "скидка при проживании \nот",
    "sale_title": "горящее предложение",
    "cleaning": "плата за уборку",
    "nights": "менее суток | {n} сутки | {n} суток | {n} суток",
    "nights_interval": "менее суток | {n} суток | {n} суток | {n} суток"
  },
  "en": {
    "dop_persons": "surcharge for {n} guests / surcharge for {n} guests / surcharge for {n} guests",
    "interval": "discount on the price from",
    "sale_title": "last minute offer",
    "cleaning": "cleaning fee",
    "nights": "less than a day | {n} a day | {n} a day | {n} a day",
    "nights_interval": "less than a day | {n} a day | {n} a day | {n} a day"
  }
}
</i18n>

<template>
  <div v-if="priceItem" class="price-item">
    <p class="price-item__calc">
      <div class="price-item__title-wrap">
        <span class="price-item__title">{{ calcInfoStr }}</span>
        <SaleHint v-if="priceItem.type === 'sale'"/>
      </div>
      <span class="price-item__hint text-grey">
        {{ getTextByType }}
      </span>
    </p>
    <p v-if="priceItem.cost" class="price-item__result" :class="classes">
      {{ resultInfoStr }}
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { formatPriceCeil } from "@/utils/format";
import SaleHint from "./SaleHint.vue";

const props = defineProps({
  priceItem: {
    type: Object,
    default: null,
  },
  hotItem: {
    type: Object,
    default: null,
  },
  currencyItem: {
    type: Object,
    default: null,
  },
});

const { t, locale } = useI18n();

const priceItem = computed(() => props.priceItem);
const currencyCode = computed(() => props.currencyItem?.title || "RUB");

const getValue = (obj, field) => {
  if (!Object.hasOwn(obj, field) || !field) {
    return null;
  }
  return obj[field];
};

const compareTypeOfPrice = (value) => priceItem.value.type === value;
const getTextByType = computed(() => {
  if (!priceItem.value || !Object.keys(priceItem.value)?.length) {
    return "";
  }

  const valueByType = {
    one_day_price: null,
    dop_persons: getValue(priceItem.value, "guests"),
    cleaning: null,
    interval: null,
  };

  const textByType = {
    one_day_price: () => "",
    dop_persons: (value) => t("dop_persons", value),
    cleaning: () => "",
    interval: () => "",
  };

  if (textByType[priceItem.value.type]) {
    return textByType[priceItem.value.type](valueByType[priceItem.value.type]);
  }
  return "";
});

const calcInfoStr = computed(() => {
  if (compareTypeOfPrice("cleaning")) {
    return t("cleaning");
  }
  if (compareTypeOfPrice("sale")) {
    return t("sale_title");
  }
  if (compareTypeOfPrice("interval")) {
    return `${t("interval")} ${t("nights_interval", priceItem.value.days)}`;
  }
  return `${formatPriceCeil(
    priceItem.value.cost,
    currencyCode.value,
    locale.value
  )} x ${t("nights", priceItem.value.nights)}`;
});

const resultInfoStr = computed(() => {
  if (priceItem.value.nights) {
    if (compareTypeOfPrice("interval") || compareTypeOfPrice("sale")) {
      return `- ${formatPriceCeil(
        priceItem.value.cost,
        currencyCode.value,
        locale.value
      )}`;
    }
    if (compareTypeOfPrice("dop_persons")) {
      return formatPriceCeil(
        priceItem.value.cost * priceItem.value.nights,
        currencyCode.value,
        locale.value
      );
    }
    return formatPriceCeil(
      priceItem.value.cost * priceItem.value.nights,
      currencyCode.value,
      locale.value
    );
  }
  return formatPriceCeil(priceItem.value.cost, currencyCode.value, locale.value);
});

const classes = computed(() => ({
  "text-red": compareTypeOfPrice("interval") || compareTypeOfPrice("sale"),
}));
</script>

<style scoped lang="scss">
.price-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  &__calc {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  &__title {
    white-space: pre-line;
  }
  &__old-price {
    text-decoration: line-through;
  }
  &__result {
    margin-top: 0;
  }
  &__hint {
    font-size: 12px;
  }
  &__title-wrap {
    display: flex;
    align-items: center;
  }
}

.text-grey {
  color: rgb(113, 113, 113);
}

.text-black {
  color: black;
}

.text-red {
  color: red;
}
</style>

export default {
  "settings-1": "icon-app-settings-1",
  "no-child": "icon-app-no-child",
  "lightning": "icon-app-lightning",
  "user-3": "icon-app-user-3",
  "calendar1": "icon-app-calendar1",
  "no-children": "icon-app-no-children",
  "party-ok": "icon-app-party-ok",
  "pets-ok": "icon-app-pets-ok",
  "smoking-ok": "icon-app-smoking-ok",
  "edit-price": "icon-app-edit-price",
  "shape": "icon-app-shape",
  "drop-circle": "icon-app-drop-circle",
  "rocket-planet": "icon-app-rocket-planet",
  "rocket-right": "icon-app-rocket-right",
  "shuttle-2": "icon-app-shuttle-2",
  "up-arrow": "icon-app-up-arrow",
  "dislike": "icon-app-dislike",
  "like": "icon-app-like",
  "metro-circle": "icon-app-metro-circle",
  "sea": "icon-app-sea",
  "degrees": "icon-app-degrees",
  "graduate-cap": "icon-app-graduate-cap",
  "heart-tile": "icon-app-heart-tile",
  "heart-tile-fill": "icon-app-heart-tile-fill",
  "point-homeicon-app-point-homeicon-app-man-transparent": "icon-app-point-homeicon-app-point-homeicon-app-man-transparent",
  "check": "icon-app-check",
  "important": "icon-app-important",
  "info": "icon-app-info",
  "lamp": "icon-app-lamp",
  "tabs": "icon-app-tabs",
  "update": "icon-app-update",
  "fire": "icon-app-fire",
  "heart-full": "icon-app-heart-full",
  "heart": "icon-app-heart",
  "partner": "icon-app-partner",
  "location": "icon-app-location",
  "star": "icon-app-star",
  "rocket": "icon-app-rocket",
  "wifi": "icon-app-wifi",
  "parking": "icon-app-parking",
  "condition": "icon-app-condition",
  "documents": "icon-app-documents",
  "no-smoking": "icon-app-no-smoking",
  "washmachine": "icon-app-washmachine",
  "refrigerator": "icon-app-refrigerator",
  "microwave": "icon-app-microwave",
  "hairdryer": "icon-app-hairdryer",
  "tv": "icon-app-tv",
  "pets": "icon-app-pets",
  "safe": "icon-app-safe",
  "toiletries": "icon-app-toiletries",
  "jacuzzi": "icon-app-jacuzzi",
  "flash": "icon-app-flash",
  "clip": "icon-app-clip",
  "clock": "icon-app-clock",
  "bed": "icon-app-bed",
  "arrival": "icon-app-arrival",
  "double-bed": "icon-app-double-bed",
  "user": "icon-app-user",
  "ticket": "icon-app-ticket",
  "sport": "icon-app-sport",
  "vacation": "icon-app-vacation",
  "location-filled": "icon-app-location-filled",
  "emblem": "icon-app-emblem",
  "world": "icon-app-world",
  "zero-percent": "icon-app-zero-percent",
  "garantie": "icon-app-garantie",
  "free": "icon-app-free",
  "people": "icon-app-people",
  "guest": "icon-app-guest",
  "calendar": "icon-app-calendar",
  "minus": "icon-app-minus",
  "plus": "icon-app-plus",
  "select": "icon-app-select",
  "cross-min": "icon-app-cross-min",
  "cam": "icon-app-cam",
  "playground": "icon-app-playground",
  "beach": "icon-app-beach",
  "pool_out": "icon-app-pool_out",
  "spa": "icon-app-spa",
  "restaurant": "icon-app-restaurant",
  "gum": "icon-app-gum",
  "transfer": "icon-app-transfer",
  "share-simple": "icon-app-share-simple",
  "share": "icon-app-share",
  "qiwi": "icon-app-qiwi",
  "yandexmoney": "icon-app-yandexmoney",
  "webmoney": "icon-app-webmoney",
  "sberbank": "icon-app-sberbank",
  "bankcard": "icon-app-bankcard",
  "fullscreen": "icon-app-fullscreen",
  "ski": "icon-app-ski",
  "purse": "icon-app-purse",
  "exclamation-triangle": "icon-app-exclamation-triangle",
  "question": "icon-app-question",
  "arrow-left": "icon-app-arrow-left",
  "camera": "icon-app-camera",
  "arrow-down": "icon-app-arrow-down",
  "arrow-up": "icon-app-arrow-up",
  "pencil": "icon-app-pencil",
  "cross": "icon-app-cross",
  "metro": "icon-app-metro",
  "label": "icon-app-label",
  "bsofa-bed": "icon-app-bsofa-bed",
  "king-bed": "icon-app-king-bed",
  "yarus-bed": "icon-app-yarus-bed",
  "linen": "icon-app-linen",
  "cleaning": "icon-app-cleaning",
  "add-room": "icon-app-add-room",
  "add-house": "icon-app-add-house",
  "add-apartment": "icon-app-add-apartment",
  "add-hotel": "icon-app-add-hotel",
  "youtube": "icon-app-youtube",
  "instagram": "icon-app-instagram",
  "twitter": "icon-app-twitter",
  "odnoklassniki": "icon-app-odnoklassniki",
  "vk": "icon-app-vk",
  "facebook": "icon-app-facebook",
  "phone": "icon-app-phone",
  "crown": "icon-app-crown",
  "dzen": "icon-app-dzen",
  "arro": "icon-app-arro",
  "car": "icon-app-car",
  "chat": "icon-app-chat",
  "door": "icon-app-door",
  "eye": "icon-app-eye",
  "hat": "icon-app-hat",
  "man": "icon-app-man",
  "money": "icon-app-money",
  "setting": "icon-app-setting",
  "message": "icon-app-message",
  "bed1": "icon-app-bed1",
  "autumn": "icon-app-autumn",
  "holiday": "icon-app-holiday",
  "winter": "icon-app-winter",
  "meal": "icon-app-meal",
  "calendar-5": "icon-app-calendar-5",
  "washing-machine": "icon-app-washing-machine",
  "wifi1": "icon-app-wifi1",
  "walk": "icon-app-walk",
  "car-map": "icon-app-car-map",
  "tv1": "icon-app-tv1",
  "clock-info": "icon-app-clock-info",
  "no-pets-2": "icon-app-no-pets-2",
  "documents1": "icon-app-documents1",
  "no-parties": "icon-app-no-parties",
  "child": "icon-app-child",
  "bedclothes": "icon-app-bedclothes",
  "close-btn": "icon-app-close-btn",
  "condition1": "icon-app-condition1",
  "hairdryer1": "icon-app-hairdryer1",
  "invalid-name": "icon-app-invalid-name",
  "kettle": "icon-app-kettle",
  "isolation": "icon-app-isolation",
  "messenger": "icon-app-messenger",
  "microwave1": "icon-app-microwave1",
  "no-smoking1": "icon-app-no-smoking1",
  "safe-box": "icon-app-safe-box",
  "balcony": "icon-app-balcony",
  "towels": "icon-app-towels",
  "bath": "icon-app-bath",
  "star2": "icon-app-star2",
  "doc-on": "icon-app-doc-on",
  "telegram": "icon-app-telegram",
  "finger-like": "icon-app-finger-like",
  "map-filter": "icon-app-map-filter",
  "list-filter": "icon-app-list-filter",
  "finger-like-alt": "icon-app-finger-like-alt",
  "repair": "icon-app-repair",
  "before": "icon-app-before"
}